import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.less'],
})
export class TextInputComponent implements OnInit {
  @Input()
  placeholder: string;

  @Input()
  width: string;

  @Input()
  type: string;

  @Input()
  border: boolean;

  @Input()
  value: string;

  @Input()
  error: any;

  @Output()
  nChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  setText($event) {
    this.nChange.emit($event);
  }
}
