import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  public isLegal = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const isLegal = window.location.href.includes('legal');
    this.isLegal = false;
  }

  goToHome() {
    if (!this.isLegal) {
      this.router.navigate(['/']);
    }
  }

  goToCampaignsCauses() {
    if (!this.isLegal) {
      this.router.navigateByUrl('/campaigns/causes');
    }
  }
  goToCampaignsFoundations() {
    if (!this.isLegal) {
      this.router.navigateByUrl('/campaigns/foundations');
    }
  }

  goToDonateNow() {
      this.router.navigateByUrl('/campaigns/foundations');
  }
  goToStartCampaign() {
      this.router.navigateByUrl('/create/campaign');
  }
}
