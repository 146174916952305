import { Component, Input } from '@angular/core';
import { ICampaign } from '../../models/campaign.model';

@Component({
  selector: 'ui-campaign-profile-tabs',
  templateUrl: './campaign-profile-tabs.component.html',
  styleUrls: ['./campaign-profile-tabs.component.less']
})
export class CampaignProfileTabsComponent {

  @Input()
  campaigns: ICampaign[]


  subtab:string = 'my_campaigns_recaudo';

}
