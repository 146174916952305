import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TableModule } from 'ngx-easy-table';
import { ProfileFiltersComponent } from './components/profile-filters/profile-filters.component';
import { ProfileTabsUserComponent } from './components/profile-tabs-user/profile-tabs-user.component';
import { ProfileTabsAdminComponent } from './components/profile-tabs-admin/profile-tabs-admin.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SharedModule } from 'src/app/shared/shared.module';
import { CampaignsModule } from 'src/app/core/campaigns/campaigns.module';
import { AdminHomeSettingsFormComponent } from './components/forms/admin-home-settings-form/admin-home-settings-form.component';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
@NgModule({
  declarations: [ProfileFiltersComponent, ProfileTabsUserComponent, ProfileTabsAdminComponent, AdminHomeSettingsFormComponent],
  imports: [CommonModule, SharedModule, InputSwitchModule, ButtonModule, CampaignsModule, NgxDatatableModule, TableModule, AutocompleteLibModule],
  exports: [AdminHomeSettingsFormComponent, ProfileFiltersComponent, ProfileTabsUserComponent, ProfileTabsAdminComponent],
})
export class ProfilePageModule {}
