import { Component, Input, OnInit } from '@angular/core';
import { IComment } from 'src/app/core/comments/models/comment.model';

@Component({
  selector: 'ui-campaign-comments',
  templateUrl: './campaign-comments.component.html',
  styleUrls: ['./campaign-comments.component.less'],
})
export class CampaignCommentsComponent implements OnInit {
  @Input()
  comments: IComment[];

  constructor() {}

  ngOnInit(): void {}
}
