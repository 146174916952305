import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IServerUser, IUser } from 'src/app/core/users/models/user.model';
import { environment } from 'src/environments/environment';
import Swal from "sweetalert2";

export type IUserPayload = { 
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  mobile: string;
}
@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(private $http: HttpClient) {}

  async doSignUpWithEmailAndPassword(user: IUser) {
    const url = `${environment.server}/auth/signup/by/email`;
    const body = {
      ...user,
      env: environment.env,
      url: environment.url,
    };
    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    const userResponse = await this.$http.post<IServerUser[]>(url, body, {
      headers:{
        //'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        'Content-Type' : 'application/json; charset=UTF-8'
    }
    }).toPromise();
    return userResponse;
  }

  async doSignUpWithEmailAndPassword2(user: IUserPayload) {
    const url = `${environment.server}/auth/signup/by/email/v2`;
    const body = {
      ...user,
      env: environment.env,
      url: environment.url,
    };
    //let headers = new Headers();
    //headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    const userResponse = await this.$http.post<IServerUser>(url, body).toPromise();
    return userResponse;
  }

  doSignUpWithFacebook() {}

  doSignUpGoogle() {}

  async doRecoveryEmail(email: string) {
    const url = `${environment.server}/users/recovery/password/by/email/${email}`;
    const body = {
      email,
    };
    const userResponse = await this.$http.post<IServerUser>(url, body).toPromise();
    return userResponse;
  }

  async doChangePassword(email: string, token: string, password: string) {
    const url = `${environment.server}/auth/change/password/by/email/${email}`;
    const body = {
      email,
      token,
      password,
    };
    const userResponse = await this.$http.post<IServerUser>(url, body).toPromise();
    return userResponse;
  }
}
