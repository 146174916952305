import { Component } from '@angular/core';

@Component({
  selector: 'ui-mobile-profile-view',
  templateUrl: './mobile-profile-view.component.html',
  styleUrls: ['./mobile-profile-view.component.less']
})
export class MobileProfileViewComponent {

}
