<div class="page recovery-page">
  <!-- ------------------------------------------ -->
  <!-- --- AUTH::RECOVERY::FORM ------------------- -->
  <!-- ------------------------------------------ -->
  <form class="form-recovery">
    <!-- ---- FORM::HEADER ---------------------- -->
    <div class="form-recovery--header">
      <ui-title-auth-h1 [button]="'< Atrás'" [title]="'auth.RECOVERY_PASSWORD'" (nClick)="doBack()"></ui-title-auth-h1>
    </div>
    <!-- ---- /FORM::HEADER --------------------- -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-recovery--container">
      <h2 class="recovery-info">
        Escribe la dirección de correo electrónico asociada a tu cuenta. Te enviaremos un enlace para que puedas reestablecer el ingreso a tu perfil.
      </h2>

      <br />

      <div class="row inputs">
        <ui-text-input-inline
          (nChange)="setEmail($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Correo electrónico'"
          [type]="'email'"
          style="width: 100%"
        ></ui-text-input-inline>

        <span [ngClass]="{ 'input-failed': authError }" *ngIf="authError"> Email invalidos. </span>
      </div>

      <div class="row signin-btn">
        <ui-default-button (nClick)="doRecoveryEmail()" [label]="'SOLICITAR NUEVA CONTRASEÑA'" [color]="'green'"></ui-default-button>
      </div>

      <br />

      <div class="row links">
        <div style="margin: 0 auto">
          <span
            >No tienes una cuenta,
            <a [routerLink]="'/auth/signup/user'">registrate aqui</a>
          </span>
        </div>
      </div>

      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--footer">
      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->
  </form>
</div>
