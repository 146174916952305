import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
const N_MAX_COVER = 5;

@Pipe({
  name: 'filterByIsCover',
})
export class FilterByIsCoverPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    if (campaigns && campaigns.length > 0) {
      return campaigns.filter((campaign) => campaign.isInCover).slice(0, N_MAX_COVER);
    }
    return [];
  }
}
