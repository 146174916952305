<div class="page-about page-about-us">
  <section class="section-title">
    <h1>TERMINOS Y CONDICIONES</h1>
  </section>

  <section class="section-green">
    <br />
    <br />
    <br />
    <p>
      El presente contrato describe los términos y condiciones aplicables al uso del contenido y/o servicios del sitio web Fundación Soliun del cual es titular
      la Fundación Soliun. ESAL. Para hacer uso del contenido, productos y/o servicios del sitio web el usuario deberá sujetarse a los presentes términos y
      condiciones.
    </p>
    <br />

    <h2>I OBJETO</h2>
    <p>
      El objeto es regular el acceso y utilización del contenido, productos y/o servicios a disposición del público en general en el dominio www.funs.com.co
    </p>
    <br />
    <p>
      El titular se reserva el derecho de realizar cualquier tipo de modificación en el sitio web en cualquier momento y sin previo aviso, el usuario acepta
      dichas modificaciones. El acceso al sitio web por parte del usuario es libre y gratuito, la utilización del contenido, productos y/o servicios implica un
      costo de suscripción para el usuario. El sitio web solo admite el acceso a personas mayores de edad y no se hace responsable por el incumplimiento de
      esto. El sitio web está dirigido a usuarios residentes en Colombia y cumple con la legislación establecida en dicho país, si el usuario reside en otro
      país y decide acceder al sitio web lo hará bajo su responsabilidad. La administración del sitio web puede ejercerse por terceros, es decir, personas
      distintas al titular, sin afectar esto los presentes términos y condiciones.
    </p>
  </section>

  <section class="section-pink">
    <br />
    <h2>II. USUARIO</h2>
    <p>
      La actividad del usuario en el sitio web como publicaciones o comentarios estarán sujetos a los presentes términos y condiciones. El usuario se compromete
      a utilizar el contenido, productos y/o servicios de forma lícita, sin faltar a la moral o al orden público, absteniéndose de realizar cualquier acto que
      afecte los derechos de terceros o el funcionamiento del sitio web. El usuario se compromete a proporcionar información verídica en los formularios del
      sitio web. El acceso al sitio web no supone una relación entre el usuario y el titular del sitio web. El usuario manifiesta ser mayor de edad y contar con
      la capacidad jurídica de acatar los presentes términos y condiciones.
    </p>
    <br />
    <h2>III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB</h2>
    <p>
      El titular no garantiza la continuidad y disponibilidad del contenido, productos y/o servicios en el sitito web, realizará acciones que fomenten el buen
      funcionamiento de dicho sitio web sin responsabilidad alguna.
    </p>
    <p>
      El titular no se responsabiliza de que el software esté libre de errores que puedan causar un daño al software y/o hardware del equipo del cual el usuario
      accede al sitio web. De igual forma, no se responsabiliza por los daños causados por el acceso y/o utilización del sitio web.
    </p>
    <br />
    <h2>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h2>
    <p>
      Conforme a lo establecido en la Ley de Protección de Datos Personales en Posesión de Particulares, el titular se compromete a tomar las medidas necesarias
      que garanticen la seguridad del usuario, evitando que se haga uso indebido de los datos personales que el usuario proporcione en el sitio web. El titular
      corroborará que los datos personales contenidos en las bases de datos sean correctos, verídicos y actuales, así como que se utilicen únicamente con el fin
      con el que fueron recabados. El uso de datos personales se limitará a lo previsto en el Aviso de Privacidad disponible en (dirección electrónica del aviso
      de privacidad / URL DE POLÍTICAS DE PRIVACIDAD).
    </p>

    <br />
    <p>
      www.funs.com.co se reserva el derecho de realizar cualquier tipo de modificación en el Aviso de Privacidad en cualquier momento y sin previo aviso, de
      acuerdo con sus necesidades o cambios en la legislación aplicable, el usuario acepta dichas modificaciones.
    </p>
  </section>

  <section class="section-green">
    <br />
    <p>
      El sitio web implica la utilización de cookies que son pequeñas cantidades de información que se almacenan en el navegador utilizado por el usuario como
      datos de ingreso, preferencias del usuario, fecha y hora en que se accede al sitio web, sitios visitados y dirección IP, esta información es anónima y
      solo se utilizará para mejorar el sitio web. Los cookies facilitan la navegación y la hacen más amigable, sin embargo, el usuario puede desactivarlos en
      cualquier momento desde su navegador en el entendido de que esto puede afectar algunas funciones del sitio web.
    </p>
    <br />
    <h2>V. POLÍTICA DE ENLACES</h2>
    <p>El sitio web puede contener enlaces a otros sitios de internet pertenecientes a terceros de los cuales no se hace responsable.</p>

    <br />
    <h2>VI. POLÍTICA DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
    <p>
      El titular manifiesta tener los derechos de propiedad intelectual e industrial del sitio web incluyendo imágenes, archivos de audio o video, logotipos,
      marcas, colores, estructuras, tipografías, diseños y demás elementos que lo distinguen, protegidos por la legislación Colombiana e internacional en
      materia de propiedad intelectual e industrial.
    </p>

    <br />
    <p>
      El usuario se compromete a respetar los derechos de propiedad industrial e intelectual del titular pudiendo visualizar los elementos del sitio web,
      almacenarlos, copiarlos e imprimirlos exclusivamente para uso personal.
    </p>

    <br />
    <h2>VII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE</h2>
    <p>La relación entre el usuario y el titular se regirá por las legislaciones aplicables en Colombia.</p>
    <p>
      www.funs.com.co no se responsabiliza por la indebida utilización del contenido, productos y/o servicios del sitio web y del incumplimiento de los
      presentes términos y condiciones.
    </p>

    <br />
    <h2>POLÍTICA DE DONACIÓN Y ORIGEN DE RECURSOS</h2>
    <p>
      El usuario declara bajo la gravedad del juramento que los recursos que a título de donación entrega a la Fundación Soliun, provienen del giro ordinario de
      los negocios derivados de su actividad económica o su objeto social y que no son producto de actividades ilícitas. En el caso en que las autoridades
      competentes ejerzan algún requerimiento a la Fundación Soliun con respecto a los recursos donados, el donante queda obligado a responder ante las mismas.
    </p>

    <br />
    <p>
      El Usuario donante, se obliga a proporcionar toda la información que las autoridades o la Fundación Soliun le soliciten relacionada con la prevención del
      lavado de activos y financiación del terrorismo. En caso de no aportar toda la documentación requerida, la Fundación Soliun se abstendrá de recibir la
      donación.
    </p>
    <br />
    <p>
      Igualmente se autoriza a la Fundación Soliun para consultar de manera directa o a través de terceros, bases o bancos de datos que contengan información
      sobre el donante, todo de conformidad con lo establecido en la normatividad vigente sobre datos personales y Habeas Data; y abstenerse de recibir la
      donación en el evento de encontrarse el donante reportado en las mismas.
    </p>
  </section>
</div>
