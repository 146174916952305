import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-auth-pages',
  templateUrl: './auth-pages.component.html',
  styleUrls: ['./auth-pages.component.less'],
})
export class AuthPagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
