import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { HeaderAuthComponent } from './components/header-auth/header-auth.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { HeaderLanguageComponent } from './components/header-language/header-language.component';

@NgModule({
  declarations: [HeaderAuthComponent, HeaderSearchComponent, HeaderLanguageComponent],
  exports: [HeaderAuthComponent, HeaderSearchComponent, HeaderLanguageComponent],
  imports: [CommonModule, TranslateModule, FormsModule],
})
export class HeaderModule {}
