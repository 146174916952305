<section class="page-section modal share-modal">
  <div class="modal-wrapper" (clickOutside)="onClickedOutside($event)">



    <div class="modal-header" *ngIf="!isSharePictureTab">
      <div class="header--btn btn-close" (click)="closeModal()"><i class="far fa-times"></i></div>
      <div class="header--btn btn-title">Comparte para ayudar</div>
      <div class="header--btn btn-close" style="visibility: hidden"></div>
    </div>

    <div class="modal-header" *ngIf="isSharePictureTab">
      <div class="header--btn btn-share" (click)="isSharePictureTab = false"><i class="far fa-angle-left"></i> Atrás</div>
      <div class="header--btn btn-title "> Compartir imagen </div>
      <div class="header--btn btn-close" (click)="closeModal()"><i class="far fa-times"></i></div>
    </div>



    <div class="modal-content" *ngIf="!isSharePictureTab">
      <h1>Las campañas que se comparten en las redes sociales recaudan hasta 5 veces más.</h1>
      <ul>
        <li (click)="shareFB()">
          <img src="../../../assets/icons/share_icon_fb.png" />
          <h5>Facebook</h5>
        </li>
        <li (click)="shareTW()">
          <img src="../../../assets/icons/share_icon_tw.png" />
          <h5>Twitter</h5>
        </li>
        <li *ngIf="false">
          <img src="../../../assets/icons/share_icon_html.png" />
          <h5>INSERTAR HTML</h5>
        </li>
        <li (click)="sharePicture()">
          <img src="../../../assets/icons/share_icon_share.png" />
          <h5>Compartir Imagen</h5>
        </li>
      </ul>
      <div class="row copy-link-row">
        <h3 class="copy-link-row--title">Copiar enlace</h3>
        <div class="copy-link-row--wrapper">
          <input type="text" [placeholder]="shareURL" [(ngModel)]="shareURL" />
          <ui-solid-btn [color]="'green'" [minWidth]="'50px'" [margins]="'0em'" [paddings]="'0.5em 1em'"
            [text]="'COPIAR'" (nClick)="doShareCopyURL()"></ui-solid-btn>
        </div>
      </div>
      <div class="row copy-text-row">
        <h3 class="copy-text-row--title">Copiar enlace</h3>
        <div class="copy-text-row--wrapper">
          <textarea rows="8" style="height: 250px;">
Hola,He pensado que quizá te gustaría apoyar esta campaña:https://www.fundaciónsoliun-org.com/f/zqz2f-ayuda-help-ni&ossinzika_source=customer&utm_medium=email&utm_campaign=p_cp+share-sheet. Un donativo, por pequeño que sea, puede ayudar a alcanzar su objetivo. Si no te es posible hacerlo, aún podrías ayudar compartiendo la campaña. ¡Gracias por tu tiempo!</textarea>
          <ui-solid-btn [color]="'green'" [minWidth]="'50px'" [margins]="'0em'" [paddings]="'0.5em 1em'"
            [text]="'COPIAR'"></ui-solid-btn>
        </div>
      </div>
    </div>



    <div class="modal-content" *ngIf="isSharePictureTab">

      <p class="share-picture-p">Puedes compartir esta imagen en tus redes sociales y descargarla para difundirla o
        imprimirla.</p>

      <div class="share-picture-buttons">
        <div class="share-picture-button" (click)="shareFB()">
          <img src="../../../assets/icons/share_icon_fb.png" /> Compartir con Facebook
        </div>
        <div class="share-picture-button" (click)="shareTW()">
          <img src="../../../assets/icons/share_icon_tw.png" /> Compatir con Twitter
        </div>
      </div>

      <div class="canvas" *ngIf="photo">
        <div class="canvas-wrapper"
          style="position: relative; display: block; position: relative; width: 100%; height: 450px" id="capture">
          <div class="canvas-bg" [ngStyle]="{
              'background-image': getPhoto,
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              width: '100%',
              height: '450px',
              position: 'absolute',
              top: '0px',
              left: '0px'
            }"></div>
          <div class="canvas-content" [ngStyle]="{
              width: '100%',
              height: '450px',
              position: 'absolute',
              top: '0px',
              left: '0px'
            }">
            <h1>{{ campaign.title | truncateText: 40 }}</h1>
            <p *ngIf="false">{{ campaign.description | truncateText: 160 }}
              <br>
            </p>
            <p *ngIf="true">
              Para conocer más, visítanos en www.funs.com.co campaña número {{campaign.id}}
            </p>
            <img src="../../../../../assets/logos/ID-FS-TARJETA-COMPARTIR.png" />
            <div class="campaign-container-id"><span>ID {{ 
              id
            }}</span></div>
          </div>
        </div>

      </div>

      <div class="row download-row" (click)="doDownloadImage()">
        <img />
        <a>Descargar imagen</a>
      </div>

    </div>

    <div class="modal-footer">
      <h5>Para saber más sobre nuestras políticas y funcionamiento puedes hacer</h5>
      <a href="">click aqui</a>
    </div>
  </div>
</section>