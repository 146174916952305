import * as moment from 'moment';
import Swal from 'sweetalert2';

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/core/users/services/user.service';
import { SignupService } from 'src/app/auth/services/signup.service';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IStatusValue } from 'src/app/core/campaigns/models/status.model';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { IUser } from 'src/app/core/users/models/user.model';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { campaignCategories } from "src/app/core/campaigns/models/campaign.model";


export const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('user_password')?.value;
  const confirmPassword = control.get('user_password2')?.value;
  return password === confirmPassword ? null : { passwordMismatch: true };
};



@Component({
  selector: 'ui-create-campaign-cause-page',
  templateUrl: './create-campaign-cause-page.component.html',
  styleUrls: ['./create-campaign-cause-page.component.less'],
})
export class CreateCampaignCausePageComponent implements OnInit {
  // ---------------------------------- //
  // ---
  form: FormGroup;
  formCategoriesOptions = campaignCategories.map((item) => ({ value: item, label: ('campaings.categories.' +item.toUpperCase())  }));
  // ---------------------------------- //

  public isLogged: boolean = false;
  public user: IUser | null = null;

  public optionsCategories = ['OLDER_ADULTS', 'ANIMALS', 'EDUCATION', 'YOUNG_PEOPLE', 'FAMILIES', 'ENVIRONMENTAL', 'KIDS'];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private $user: UserService,
    private signupService: SignupService,
    private placeService: CountriesService,
    private campaignService: CampaignsService
  ) {}

  public ngOnInit(): void {
    this.$user.syncUser().subscribe((user) => {
      this.user = user;
      this.isLogged = !!user;
      this.doSetFormGroup();
    });
  }

  public doSetFormGroup() {
    const isRequiredValidator = !this.isLogged ? [Validators.required] : [];
    const isEmailValidator = !this.isLogged ? [Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)] : [];
    const isPhonePatternValidator = !this.isLogged ? [Validators.pattern('^\\d{9,15}$')] : [];
    const isPasswordSameValidator = !this.isLogged ? [passwordMatchValidator] : [];
    const formUser = {
      user_name: [null, [...isRequiredValidator]],
      user_phone: [null, [...isRequiredValidator, ...isPhonePatternValidator]],
      user_mobile: [null, [...isRequiredValidator, ...isPhonePatternValidator]],
      user_nid: [null, [...isRequiredValidator]],
      user_email: [null, [...isRequiredValidator, ...isEmailValidator]],
      user_password: [null, [...isRequiredValidator]],
      user_password2: [null, [...isRequiredValidator]],
    };
    const formCampaignCause = {
      campaign_name: [null, [Validators.required]],
      campaign_category: [null, [Validators.required]],
      campaign_postcode: [null, []],
      campaign_place: [null, [Validators.required]],
      campaign_description: [ null, [Validators.required, Validators.minLength(10)]],
      campaign_is_urgent: [null, []],
      campaign_amount: [null, [Validators.required, Validators.pattern('^\\d+$')]],
      campaign_deadline: [null, [Validators.required, Validators.pattern('^\\d+$')]],
      campaign_currency: ['COP', [Validators.required]],
    };

    const formGroup = { ...formUser, ...formCampaignCause };
    this.form = this.fb.group({ ...formGroup }, { validator: [...isPasswordSameValidator] } as AbstractControlOptions);
  }

  public setPlace(event: any) {
    this.form.get('campaign_place').setValue({
      city: event.city,
      department: event.deparment,
      country: event.country,
    });
  }

  public onSubmit() {
    this.markAllControlsAsTouched(this.form);
    if (this.form.valid) {
      // Form is valid, proceed with submission
      this.addCampaingFoundation();
      console.log('Form submitted successfully!');
    } else {
      // Form is invalid, do not proceed with submission
      console.log(this.form.value);
      console.log('Form contains errors. Please fix them before submitting.');
      Swal.fire('Error Creando Causa', 'onSubmit', 'error');
    }
  }

  private markAllControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      // If the control is a nested form group, recursively mark its controls as touched
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

  public get errorAmount() {
    return (
      this.form.get('campaign_amount').invalid &&
      (this.form.get('campaign_amount').dirty || this.form.get('campaign_amount').touched) &&
      this.form.get('campaign_amount').errors?.required
    );
  }
  public get errorDeadline() {
    return (
      this.form.get('campaign_deadline').invalid &&
      (this.form.get('campaign_deadline').dirty || this.form.get('campaign_deadline').touched) &&
      this.form.get('campaign_deadline').errors?.required
    );
  }

  private getDateEnd(deadline_days: string) {
    const days = parseInt(deadline_days, 10);
    const now = moment(new Date()).utc();
    const end = now.add(days, 'days');
    return end.toDate();
  }

  private getUserPayload() {
    return {
      name: this.form.get('user_name').value as string,
      firstname: this.form.get('user_name').value as string,
      lastname: '',
      email: this.form.get('user_email').value as string,
      password: this.form.get('user_password').value as string,
      phone: this.form.get('user_phone').value as string,
      mobile: this.form.get('user_mobile').value as string,
    };
  }

  private getPlacePayload() {
    const place: { country: string; department: string; city: string } = this.form.get('campaign_place').value;
    return {
      country: place.country,
      state: place.department,
      city: place.city,
    };
  }

  private getCampaignCausePayload() {
    const place = this.getPlacePayload();
    const isUrgent = !!this.form.get('campaign_is_urgent').value;
    return {
      type: 'CAUSE',
      status: 'S0_IN_PROCESS' as IStatusValue,
      title: this.form.get('campaign_name').value,
      description: this.form.get('campaign_description').value,
      category: this.form.get('campaign_category').value,

      place_country: place.country,
      place_department: place.state,
      place_city: place.city,

      goal: parseFloat(this.form.get('campaign_amount').value),

      date_start: new Date(),
      date_end: this.getDateEnd(this.form.get('campaign_deadline').value),

      isUrgent: isUrgent,
      isInCover: false,
      isInOutstanding: false,

      //
      content: '-',
    };
  }
  async addUser() {
    let user_id;

    // User is already logged in
    if (this.isLogged && this.user) {
      user_id = this.user.id;
      return { id : user_id };
    }

    const user_payload = this.getUserPayload();
    const user_response = await this.signupService.doSignUpWithEmailAndPassword2(user_payload);
    user_id = user_response.id;
    return { id : user_id };
  }

  async addPlace() {
      const place_payload = this.getPlacePayload();
      const place_response = await this.placeService.doCreatePlace(place_payload);
      const place_id = place_response.id;
      return { id: place_id };
  }

  async addCampaign(user_id: number, place_id: number) {
      const campaign_payload = this.getCampaignCausePayload();
      campaign_payload['organizer_id'] = user_id;
      campaign_payload['place_id'] = place_id;
      const campaign_response = await this.campaignService.postCampaing(campaign_payload);
      const campaign_id = campaign_response;
      return { id: campaign_id };
  }

  async addCampaingFoundation() {
    try{
      const user_response = await this.addUser();
      const place_response = await this.addPlace();
      const user_id = user_response.id;
      const place_id = place_response.id;
      const campaign_id = await this.addCampaign(user_id, place_id);
      this.router.navigateByUrl(`/profile2/${user_id}/campaigns/liked`);
    } catch(err){
      console.error(' ')
      console.error(' ================ ')
      console.error(' ')
      console.error(' error ')
      console.error(err)
      console.error(' ')
      console.error(' ================ ')
      console.error(' ')

      if(err && err.error && err.error.errors){
        const entries = Object.entries(err.error.errors);
        const lis = entries.map(item => '<li> <strong>'+ item[0] +'</strong> : <span> ' +  item[1] + '</span> </li>').join('');
        const html = '<ul> ' +  lis   + '</ul>';
        Swal.fire('Error al crear cuenta', html, 'error')
      }

    }
  }
}
