<!-- ----------------------------------- -->
<!-- --- home-page about --------------- -->
<!-- ----------------------------------- -->
<div class="home-page about">
  <section class="about--about">
    <div class="about--section-bg">
      <img class="about-pic1" src="../../../../../assets/about/about-pic1.png" />
    </div>
    <div class="about--section-content">
      <h1>Somos</h1>
      <p>
        Una organización sin ánimo de lucro desarrolladora de iniciativas para <b>emprendimiento social </b> que promueve y ofrece espacios de actividades con
        carácter lúdico-recreativo, deportivos con niños, niñas y jóvenes en situación de vulnerabilidad, exclusión y discriminación.
      </p>
      <a [routerLink]="'/about/us'" >VER MÁS</a>
    </div>
  </section>

  <section class="about--future">
    <div class="about--section-bg">
      <img class="about-pic2" src="../../../../../assets/about/about-pic2.png" />
    </div>
    <div class="about--section-content">
      <h1>Proyectamos</h1>
      <p>
        para el año 2025 ser una organización sin ánimo de lucro reconocida, comprometida con el <b>desarrollo integral </b> de comunidades a nivel nacional e
        internacional siendo un punto de comunicación, logístico y de trabajo en equipo entre entidades públicas, fundaciones y causas mediante iniciativas de
        emprendimiento social.”
      </p>
    </div>
  </section>
</div>
