<div class="page--container  background-color--red">

    <h1 class="mobile__h1_title mobile__title--red"> {{ title }}</h1>
    <h2 class="mobile__h1_subtitle mobile__title--gray"> {{ subtitle }} </h2>

    <div class="mobile-campaign__photos">
        <swiper class=" "[config]="config">
            <div class="mobile-campaign__photo" *ngFor="let photo of activePhotos">

                <div class="mobile-campaign__photo-container container--vertical" *ngIf="isVerticalPhoto(photo)">
                    <div class="mobile-campaign__photo-back">
                        <img class="photo" [src]="photo.src">
                    </div>
                    <div class="mobile-campaign__photo-front">
                        <img class="photo" [src]="photo.src">
                    </div>
                </div>

                <div class="mobile-campaign__photo-container container--horizontal" *ngIf="!isVerticalPhoto(photo)">
                    <div class="mobile-campaign__photo-back">
                        <img class="photo" [src]="photo.src">
                    </div>
                    <div class="mobile-campaign__photo-front">
                        <img class="photo" [src]="photo.src">
                    </div>
                </div>

            </div>
        </swiper>
    </div>
    <button class="mobile-campaign__button mobile-button button--solid button--red shadow--green" (click)="goToCheckout(id)">Donar ahora</button>
    <p class="mobile__p-small"> También puedes ayudar aumentando el alcance de esta campaña en tus redes sociales: </p>
    <button class="mobile-campaign__button mobile-button button--solid button--green shadow--green" (click)="doShare()">Compartir</button>
    <br>
    <br>
</div>


<div class="page--container background-color--green " style="padding-top: 3em; padding-bottom: 3em;">
    <h2 class="mobile-campaign__category">Categoría: {{ formatCategory(category) | translate}}</h2>
    <h2 class="mobile-campaign__donated">{{ ((donated) | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }} recolectados de {{ ((total) | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }}</h2>
    <h2 class="mobile-campaign__missing">Falta: {{ ((total - donated) | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }}</h2>

    <div class="mobile__progress-bar">
      <div class="progress-bar__container">
        <div class="progress-bar__level" [ngStyle]="{ 'width': 100*(donated / total) + '%'}"></div>
      </div>
    </div>
    <br>
    <div class="mobile-campaign__icons-group">
      <div class="icons-group icon--green">
        <img src="../../../../../assets/v2/BUTTON_GREEN_ON.png"> <span> Se creo hace   {{ (created_at | dateDiffTime2).time | number: '1.0-0' }} {{ (created_at | dateDiffTime2).label }} </span>
      </div>
      <div class="icons-group icon--red"> 
        <img src="./../../../../assets/v2/BUTTON_RED_OFF.png">  <span> 4 semanas para terminar </span>
      </div>
    </div>
</div>


  <div class="page--container background-color--white">
    <div class="mobile-campaign__description" >
      <h2 class="mobile__title2 mobile__title--green" > Descripción del proyecto </h2>
      <p  style="text-overflow: ellipsis; overflow: hidden;" 
      class="mobile__p-medium" id="id-description-campaign-mobile" 
      [ngStyle]="{ 'height': see_more ? '100%' : '194px'}"
      [innerHTML]="text"></p> 
      <!--
      <p class="mobile__p-medium"  style="display:inline" *ngIf="!see_more" > ... </p>
-->
      <span class="description__more" *ngIf="!see_more" (click)="openMoreText()">más</span>
      <span class="description__more" *ngIf="see_more" (click)="openLessText()">menos</span>
    </div>
  </div>