import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/services/signin.service';
import { UserService } from 'src/app/core/users/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ui-alternative-page',
  templateUrl: './alternative-page.component.html',
  styleUrls: ['./alternative-page.component.less'],
})
export class AlternativePageComponent implements OnInit {
  public user: any = {};
  public authError = '';

  constructor(private router: Router, private $user: UserService, private signinService: SigninService) {}

  ngOnInit(): void {
    const storeUser = this.$user.getUser();
    const sessionUser = JSON.parse('' + sessionStorage.getItem('user'));
    const user = storeUser || sessionStorage || null;
    if (user) {
      const userId = user.id;
      if (userId) {
        this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
      }
    }
  }

  doClose(): void {}

  setEmail($event) {
    this.user.email = $event.target.value;
  }
  setPassword($event) {
    this.user.password = $event.target.value;
  }
  go() {}

  async doSignInWithEmailAndPassword() {
    console.warn(' doSignInWithEmailAndPassword ');
    console.warn(' asdfasd ');

    try {
      const email = this.user.email;
      const password = this.user.password;

      const userResponse: any = await this.signinService.doSignInWithEmailAndPassword(email, password);
      const userId = userResponse.id;

      sessionStorage.setItem('user', JSON.stringify(userResponse));
      this.$user.setUser(userResponse);

      setTimeout(() => {
        this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
      }, 500);
    } catch (error) {
      this.authError = error;
      // console.error(error);
      // Swal.fire('Oops...', 'Something went wrong!', 'error');
    }
  }
}
