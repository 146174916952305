<div class="tab my_following_donations">
    <div class="campaigns-list">
      <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsLiked">
        <ui-card-campaign [campaign]="campaign" [theme]="getCampaignTheme(campaign)"></ui-card-campaign>
      </div>
      <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
        <ui-card-empty [card_type]="'like_tutorial_step_1'"></ui-card-empty>
      </div>
      <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
        <ui-card-empty [card_type]="'like_tutorial_step_2'"></ui-card-empty>
      </div>
      <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
        <ui-card-empty [card_type]="'like_tutorial_step_3'"></ui-card-empty>
      </div>
    </div>
  </div>