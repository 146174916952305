import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'funs-mobile-input-text',
  templateUrl: './funs-mobile-input-text.component.html',
  styleUrls: ['./funs-mobile-input-text.component.less']
})
export class FunsMobileInputTextComponent {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() type: string;
  focus: boolean = false;

  get error(): boolean {
    if (this.control) {
      return this.control.invalid && (this.control.dirty || this.control.touched);
    }
    return false;
  }

  onFocus(): void {
    this.focus = true;
  }

  onBlur(): void {
    this.focus = false;
  }

}
