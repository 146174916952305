import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-widget-percentage-level',
  templateUrl: './widget-percentage-level.component.html',
  styleUrls: ['./widget-percentage-level.component.less'],
})
export class WidgetPercentageLevelComponent implements OnInit {
  @Input()
  markerPosition: 'TOP' | 'BOTTOM' | 'CENTER' = 'BOTTOM';

  @Input()
  maxValue: number;

  @Input()
  currentValue: number;

  public width = 100; // px

  constructor() {}

  ngOnInit(): void {}

  public get valueMarker(): number {
    return Math.max(0, Math.min(100, this.width * this.currentValue  / this.maxValue ));
  }

  public get currentMarkerStyle(): any {

    if(this.markerPosition === 'BOTTOM'){
      return {
        left:'calc(' + this.valueMarker + '% + 0.5em)',
        width: '5em',
        bottom: '-5px',
        top: 'auto',
      };
    } else if(this.markerPosition === 'CENTER') {
      return {
        left:'calc(100% + 0.5em)',
        width: '5em',
        bottom: '0px',
        top: '0px',
      };
    } else {
      return {
        left:'calc(' + this.valueMarker + '% + 1em)',
        width: '5em',
        bottom: '-5px',
        top: 'auto',
      };
    }
  }
  public get bgStyle(): any {
    return {
      width: this.width + '%',
    };
  }
  public get valueStyle(): any {
    return {
      width: this.valueMarker + '%',
    };
  }
}
