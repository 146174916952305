import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfilePageService } from 'src/app/pages/profile-page/profile-page.service';
//import { SliderValue, SliderOptions } from '@jkuri/ngx-slider';

@Component({
  selector: 'ui-profile-filters2',
  templateUrl: './profile-filters.component.html',
  styleUrls: ['./profile-filters.component.less'],
})
export class ProfileFilters2Component implements OnInit {
  constructor(private $profile: ProfilePageService) {}
  public modal = false;
  public isModal = false;
  public city = '';

  @Input()
  mode = '';

  @Output()
  nChange = new EventEmitter();

  public categories = ['Adultos Mayores', 'Animales', 'Educación', 'Jóvenes', 'Familias', 'Medio Ambiente', 'Populares', 'Menos Donados', 'Niños'];
  public category = '';

  public filter = {
    date: {
      create: {
        start: new Date(),
        end: new Date(),
      },
      close: {
        start: new Date(),
        end: new Date(),
      },
    },
    amount: 0,
    city: '',
    department: '',
    type: '',
    random: 0,
    category: '',
  };

  public filter0 = {
    date: {
      create: {
        start: new Date(),
        end: new Date(),
      },
      close: {
        start: new Date(),
        end: new Date(),
      },
    },
    amount: 0,
    city: '',
    department: '',
    type: '',
    random: 0,
    category: '',
  };

  ngOnInit(): void {

  }

  setCity($city) {
    this.filter.city = $city;
    this.filter.random = Math.random();
    this.nChange.emit(this.filter0);
    this.$profile.setFilter(this.filter);
  }

  setCategory(category: string) {
    if (this.category === category) {
      this.category = '';
    } else {
      this.category = category;
    }
    this.filter.category = this.category;

    this.nChange.emit(this.filter);
    this.$profile.setFilter(this.filter);
  }

  setStartDate($event) {
    this.filter.date.create.start = $event.start;
    this.filter.date.create.end = $event.end;
    this.nChange.emit(this.filter);
    this.$profile.setFilter(this.filter);
  }
  setEndDate($event) {
    this.filter.date.close.start = $event.start;
    this.filter.date.close.end = $event.end;
    this.nChange.emit(this.filter);
    this.$profile.setFilter(this.filter);
  }
  setAmount($event) {
    this.filter.amount = $event;
    this.nChange.emit(this.filter);
    this.$profile.setFilter(this.filter);
  }
  setType($event) {
    this.filter.type = $event;
    this.nChange.emit(this.filter);
    this.$profile.setFilter(this.filter);
  }
  go(){

  }
  openModal() {
    this.isModal = true;
  }


  closeModal() {
    this.isModal = false;
  }
}
