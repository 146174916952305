import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import Swal from 'sweetalert2';
import { Md5 } from 'ts-md5/dist/md5';
import { NgProgressComponent } from 'ngx-progressbar';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
const apiKeyTest = '4Vj8eK4rloUd272L48hsrarnUA'; // Test
const apiKeySoliun = 'A3obXL78tct4gN0BA4eUhga1a3';

@Component({
  selector: 'ui-payu-btn',
  templateUrl: './payu-btn.component.html',
  styleUrls: ['./payu-btn.component.less'],
})
export class PayuBtnComponent implements OnInit {
  @Input()
  settings: any = {};

  @Output()
  nSucced = new EventEmitter();

  @Output()
  nSelect = new EventEmitter();

  @Input()
  selected = false;

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  public isTest = true;

  @ViewChild('payuForm') payuFormEl: any;

  public get formAction(): string {
    return this.isTest ? 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/' : 'https://checkout.payulatam.com/ppp-web-gateway-payu';
  }
  public get formMerchantId(): string {
    return this.isTest ? '508029' : '905151';
  }
  public get formAccountId(): string {
    return this.isTest ? '512326' : '911858';
  }
  public get formDescription(): string {
    return 'Donación a la campaña ' + (this.settings.campaign as ICampaign).title;
  }
  public get formReferenceCode(): string {
    return Md5.hashStr(JSON.stringify(this.settings)) + '';
  }
  public get formAmount(): string {
    return this.settings.amount;
  }
  public get formTax(): string {
    return (this.settings.amount * 0.19).toFixed(2) + '';
  }
  public get formTaxReturnBase(): string {
    return (this.settings.amount * (1 - 0.19)).toFixed(2) + '';
  }
  public get formCurrency(): string {
    return this.settings.currency;
  }
  public get rawSignature(): string {
    const apiKey = this.isTest ? apiKeyTest : apiKeySoliun;
    return apiKey + '~' + this.formMerchantId + '~' + this.formReferenceCode + '~' + this.formAmount + '~' + this.formCurrency;
  }
  public get formSignature(): string {
    /*
    return md5.appendStr(
        apiKey + '~' + this.formMerchantId + '~' + this.formReferenceCode + '~' + this.formAmount + '~' + this.formCurrency
    ).end() + '';
    */
    return Md5.hashStr(this.rawSignature) + '';
  }
  public get formTest(): string {
    return '1';
  }
  public get formBuyerEmail(): string {
    return this.settings.email;
  }
  public get formResponseUrl(): string {
    return 'http://www.test.com/response';
  }
  public get formConfirmationUrl(): string {
    return 'http://www.test.com/confirmation';
  }

  constructor(private $http: HttpClient, private $campaigns: CampaignsService) {}

  ngOnInit(): void {}

  public doSelectPayU(): void {
    this.nSelect.emit({});
  }

  public doPayU(): void {
    if (this.checkSettings(this.settings)) {
      console.clear();
      console.log(' ');
      console.log(' ');
      console.log(' settings ');
      console.log(this.settings);
      console.log(' ');
      console.log(' ');
      console.log('rawSignature');
      console.log(this.rawSignature);
      console.log(' ');
      console.log(' ');
      console.log('formSignature');
      console.log(this.formSignature);
      console.log(' ');
      console.log(' ');
      console.log('form');
      console.log({
        formAction: this.formAction,
        formMerchantId: this.formMerchantId,
        formAccountId: this.formAccountId,
        formDescription: this.formDescription,
        formReferenceCode: this.formReferenceCode,
        formAmount: this.formAmount,
        formTax: this.formTax,
        formTaxReturnBase: this.formTaxReturnBase,
        formCurrency: this.formCurrency,
        formBuyerEmail: this.formBuyerEmail,
      });
      this.onSubmit();
    } else {
      Swal.fire('Oops... Falta algun campo', 'Por favor llenar los campos correctamente', 'error');
    }
  }

  public checkSettings(settings: any) {
    console.error('settings');
    console.error(settings);
    console.error({
      companyName: settings.companyName || settings.userName,
      currency: settings.currency,
      amount: settings.amount,
      campaign: settings.campaign,
      country: settings.country,
      department: settings.department,
      city: settings.city,
      cc: settings.cc,
      email: settings.email,
    });
    return (
      (settings.companyName || settings.userName) &&
      settings.currency &&
      settings.amount &&
      settings.campaign &&
      settings.country &&
      settings.department &&
      settings.city &&
      (settings.cc || settings.cc === 0) &&
      settings.email
    );
  }

  public async onSubmit() {
    // this.payuFormEl.nativeElement.submit();

    // For Testing
    const url = `${environment.server}/payments`;
    const obj = {
      user_id: this.settings.user.id,
      campaign_id: this.settings.campaign.id,
      method: 'CREDIT_CARD',
      amount: this.settings.amount,
      currency: this.settings.currency,
      status: 'APPROVED',
    };

    this.progressBar.start();

    try {
      const response: any = await this.$http.post<any>(url, obj).toPromise();
      setTimeout(() => this.progressBar.complete(), 2000);

      this.$campaigns.refresh();
    } catch (error) {
      setTimeout(() => this.progressBar.complete(), 2000);

      console.error(' ');
      console.error(' error ');
      console.error(error);
      console.error(' ');
      window.alert('ERROR');
    }
  }
}
