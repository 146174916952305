import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from './v2/v2-funs-mobile/services/responsive.service';

export enum DEVICES {
  MOBILE = 'DEVICE_MOBILE',
  TABLET = 'DEVICE_TABLET',
  LAPTOP = 'DEVICE_LAPTOP',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {

  title = 'ng-soliun';

  device = DEVICES.MOBILE;
  DEVICES_TYPES = DEVICES;

  param = { value: 'world' };

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private responsive: ResponsiveService,
  ) {
    this.translateService.setDefaultLang('es');
    this.translateService.use('es');
    (window as any).document.body.style.zoom = 0.85;
    this.responsive.setDeviceType();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.responsive.setDeviceType();
  }

  ngOnInit() {
    this.responsive.device$.subscribe(response => this.device = response);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


}
