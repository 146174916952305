import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-card-empty',
  templateUrl: './card-empty.component.html',
  styleUrls: ['./card-empty.component.less'],
})
export class CardEmptyComponent implements OnInit {
  @Input()
  card_type = '';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToCampaigns() {
    this.router.navigateByUrl('/campaigns/foundations');
  }
}
