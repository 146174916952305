import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchService } from '../../../../services/search.service';

@Component({
  selector: 'ui-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.less'],
})
export class HeaderSearchComponent implements OnInit {
  public isTyping = false;
  public isFocus = false;
  public text = '';
  public results$: Observable<Array<{ url: string; description: string }>>;
  public results = [
    {
      url: ' ',
      description: 'YORUSHIKA 1',
    },
    {
      url: ' ',
      description: 'YORUSHIKA 2',
    },
    {
      url: ' ',
      description: 'YORUSHIKA 3',
    },
    {
      url: ' ',
      description: 'YORUSHIKA 4',
    },
  ];

  constructor(private $search: SearchService) {
    this.results$ = this.$search.syncResults();
  }

  ngOnInit(): void {}

  onChange() {
    this.$search.onKeywordChange(this.text);
  }

  onBlur() {
    this.isFocus = false;
  }

  onFocus() {
    this.isFocus = true;
  }
}
