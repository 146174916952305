import { Component } from '@angular/core';

@Component({
  selector: 'ui-funs-mobile-footer',
  templateUrl: './funs-mobile-footer.component.html',
  styleUrls: ['./funs-mobile-footer.component.less']
})
export class FunsMobileFooterComponent {
  public SOCIALS = [
    {
      src: '/assets/v2/SOCIAL_FB.png',
      href: 'https://facebook.com'
    },
    {
      src: '/assets/v2/SOCIAL_IG.png',
      href: 'https://instagram.com'
    },
    {
      src: '/assets/v2/SOCIAL_TWITTER.png',
      href: 'https://twitter.com'
    },
    {
      src: '/assets/v2/SOCIAL_TIKTOK.png',
      href: 'https://tiktok.com'
    },
    {
      src: '/assets/v2/SOCIAL_LINKEDIN.png',
      href: 'https://linkedin.com'
    },
  ]
}
