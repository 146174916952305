import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FilterByIsActivePipe } from '../home-page/pipes/filterByIsActive.pipe';

const COMPONENTS = [];
const PIPES = [];
const MODULES = [
  CommonModule,
  SharedModule,
  CoreModule,
  DialogModule,
  ButtonModule,
];
@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...COMPONENTS, ...MODULES],
  imports: [...MODULES],
  providers:    [ FilterByIsActivePipe]
})
export class CampaignPageModule {}



