<!-- ----------------------------------- -->
<!-- --- home-page outstanding-blogs --- -->
<!-- ----------------------------------- -->
<div class="home-page outstanding-campaings">
  <!-- ------------------------------------ -->
  <!-- --- Swiper ------------------------- -->
  <!-- ------------------------------------ -->
  <swiper [config]="config" [(index)]="index">
    <div class="outstanding-campaings--slide" *ngFor="let campaign of campaigns | slice: 0:10">
      <div class="slide--wrapper">
        <!-- CARDS -->
        <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
        <br />
        <br />
      </div>
    </div>

    <div class="swiper-scrollbar"></div>
  </swiper>
  <!-- ----------------------------------- -->
</div>
<!-- ----------------------------------- -->
