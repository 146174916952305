import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/services/signin.service';
import { UserService } from 'src/app/core/users/services/user.service';

@Component({
  selector: 'ui-recovery-password-page',
  templateUrl: './recovery-password-page.component.html',
  styleUrls: ['./recovery-password-page.component.less'],
})
export class RecoveryPasswordPageComponent implements OnInit {
  public user: any = {};
  public authError = '';

  constructor(private location: Location, private router: Router, private $user: UserService, private signinService: SigninService) {}

  ngOnInit(): void {
    const storeUser = this.$user.getUser();
    const sessionUser = JSON.parse('' + sessionStorage.getItem('user'));
    const user = storeUser || sessionStorage || null;
    if (user) {
      const userId = user.id;
      if (userId) {
        this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
      }
    }
  }

  doBack(): void {
    this.location.back();
  }

  setEmail($event) {
    this.user.email = $event.target.value;
  }

  async doRecoveryEmail() {
    console.warn(' doRecoveryEmail ');
    try {
      const email = this.user.email;
      const userResponse: any = await this.signinService.doRecoveryEmail(email);
      setTimeout(() => {
        this.router.navigate(['/auth/recovery/password/email/send/' + email]);
      }, 500);
    } catch (error) {
      this.authError = error;
    }
  }
}
