import { Pipe, PipeTransform } from '@angular/core';
import categoriesConstant from 'src/app/core/campaigns/constants/categories.constant';

@Pipe({
  name: 'categoryToText',
})
export class CategoryToTextPipe implements PipeTransform {
  transform(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
