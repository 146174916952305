import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgProgressComponent } from 'ngx-progressbar';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { environment } from 'src/environments/environment';
import {Md5} from 'ts-md5/dist/md5';
import Swal from 'sweetalert2';

@Component({
  selector: 'ui-wompi-btn',
  templateUrl: './wompi-btn.component.html',
  styleUrls: ['./wompi-btn.component.less']
})
export class WompiBtnComponent implements OnInit {

  @Input()
  settings: any = {};

  @Output()
  nSucced = new EventEmitter();

  @Output()
  nSelect = new EventEmitter();

  @Input()
  selected = false;

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  public isTest = true;

  @ViewChild('wompiForm') wompiFormEl: any;

  public wompiPubKey = 'pub_test_q7CMrcolyU0V7G4tONiY8RyZ2dRKqVGZ';
  public wompiCurrency = 'COP';
  public wompiAmountInCents = '' + 90000 + '00';

  @Input()
  amount: number;

  @Input()
  campaign: ICampaign;

  @Input()
  user: IUser;

  constructor(private $http: HttpClient, private $campaigns: CampaignsService) {}

  ngOnInit(): void {
  }

  doSelectWompi(){
    this.nSelect.emit({});
  }

  public async onSubmit() {
    // this.payuFormEl.nativeElement.submit();

    // For Testing
    const url = `${environment.server}/payments`;
    const obj = {
      ...this.user,
      user_id: this.user.id || -1,
      is_anonimous: this.user.id ? false : true,
      campaign_id: this.campaign.id,
      method: 'CREDIT_CARD',
      amount: this.amount,
      currency: 'COP',
      status: 'APPROVED',
      nid_number: this.user.nid_number,
      email: this.user.email,
      name: this.user.firstname + ' ' + this.user.lastname,
      password: '2022'
    };

    this.progressBar.start();


    try {
      const response: any = await this.$http.post<any>(url, obj).toPromise();
      setTimeout(() => {
        this.progressBar.complete()
        this.$campaigns.refresh();
      }, 2000);

    } catch (error) {
      setTimeout(() => {
        this.progressBar.complete()
        console.error(' ');
        console.error(' error ');
        console.error(error);
        console.error(' ');
        debugger;
      }, 2000)
    }
  }

  public get formAction(): string {
    return this.isTest ? 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/' : 'https://checkout.payulatam.com/ppp-web-gateway-payu';
  }

  public get  wompiReference(){
    return Md5.hashStr(JSON.stringify(this.campaign))
  }

  public get  wompiRedirectURL(){
    return 'http://localhost:4200/checkout/1';
  }
  public get  wompiTaxCentsVat(){
    const r = 0.19;
    return this.amount - ((this.amount / (1 + r) * r));
  }
  public get  wompiTaxCentsConsumption(){
    const r = 0.08;
    return this.amount - ((this.amount / (1 + r) * r));
  }
  public get  wompiCustomerDataEmail(){
    return this.user.email;
  }
  public get  wompiCustomerFullName(){
    return this.user.firstname + this.user.lastname;
  }
  public get  wompiCustomerDataPhoneNumber(){
    return this.user.phone;
  }
  public get  wompiCustomerDataLegalId(){
    return this.user.nid_number;
  }
  public get  wompiCustomerDataLegalIdType(){
    return this.user.nid_type;
  }
  public get  wompiShippingAddressLine1(){
    return this.user.address;
  }
  public get  wompiShippingAddressCountry(){
    return this.user.country;
  }
  public get  wompiShippingAddressPhone(){
    return this.user.phone;
  }
  public get  wompiShippingAddressCity(){
    return this.user.city;
  }
  public get  wompiShippingAddressRegion(){
    return '';
  }


  
  public doPayU(): void {
    if (this.checkSettings(this.settings)) {
      console.clear();
      console.log(' ');
      console.log(' ');
      console.log(' settings ');
      console.log(this.settings);
      this.onSubmit();
    } else {
      Swal.fire('Oops... Falta algun campo', 'Por favor llenar los campos correctamente', 'error');
    }
  }

  public checkSettings(settings: any) {
    return (
      (settings.companyName || settings.userName) &&
      settings.currency &&
      settings.amount &&
      settings.campaign &&
      settings.country &&
      settings.department &&
      settings.city &&
      (settings.cc || settings.cc === 0) &&
      settings.email
    );
  }

}
