<div class="page create-page"
  [ngClass]="{
    'cause' :  page === 'cause',
    'foundation' :  page === 'foundation'
  }"
>
  <ui-create-campaign-menu-page *ngIf="page === 'menu'"></ui-create-campaign-menu-page>
  <ui-create-campaign-cause-page *ngIf="page === 'cause'"></ui-create-campaign-cause-page>
  <ui-create-campaign-foundation-page *ngIf="page === 'foundation'"></ui-create-campaign-foundation-page>
  <ui-create-campaign-thanks-page *ngIf="page === 'thanks'"></ui-create-campaign-thanks-page>
</div>
