<div class="input input-text" *ngIf="!advance">
  <div class="input-wrapper" [ngClass]="{ colorCian: colorCian, error: error, strong: isFocus, normal: !isFocus && !error }">
    <div class="input-border">
      <input class="input-field" [type]="type || 'text'" (focus)="onFocus()" (blur)="offFocus()" (focusout)="offFocus()" (change)="setText($event)" />
      <span class="input-label">{{ label }}</span>
    </div>
    <br />
    <span class="input-error" *ngIf="errorText && errorText != 'N/A' && error">
      {{ errorText }}
    </span>
    <span class="input-error" *ngIf="!errorText && error">
      {{ error }}
    </span>
  </div>
</div>





<div class="input input-text" *ngIf="advance">
  <div class="input-wrapper" [ngClass]="{ colorCian: colorCian, error: showError, strong: isFocus, normal: !isFocus && !error }">

    <div class="input-border">
      <div id="campaign-description-editor-js"></div>
    </div>

    <br />

    <span class="input-error" *ngIf="errorText && errorText != 'N/A' && showError">
      {{ errorText }}
    </span>

    <span class="input-error" *ngIf="!errorText && showError">
      {{ error }}
    </span>
  </div>
</div>
