<button
  [disabled]="disabled"
  class="button solid-button"
  [ngStyle]="{
    'min-width': minWidth || '0px',
    margin: margins || '0px 18px',
    padding: paddings || '6px 18px'
  }"
  [ngClass]="{
    'color-red': color === 'red',
    'color-green': color === 'green',
    'color-gray': color === 'none',
    'color-white': color === 'white'
  }"
  (click)="onClick()"
>
  {{ text | translate }}
</button>
