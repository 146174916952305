<div class="page recovery-page">
  <!-- ------------------------------------------ -->
  <!-- --- AUTH::RECOVERY::FORM ------------------- -->
  <!-- ------------------------------------------ -->
  <form class="form-recovery">
    <!-- ---- FORM::HEADER ---------------------- -->
    <div class="form-recovery--header">
      <ui-title-auth-h1 [button]="" [title]="'auth.RECOVERY_PASSWORD'"></ui-title-auth-h1>
    </div>
    <!-- ---- /FORM::HEADER --------------------- -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-recovery--container">
      <h2 class="recovery-info">Al terminar, te enviaremos a tu perfil. Al ingresar de nuevo no olvides que cambiaste la contraseña</h2>

      <br />

      <div class="row inputs">
        <ui-text-input-inline
          (nChange)="setPassword1($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Nueva contraseña'"
          [type]="'password'"
          style="width: 100%"
        ></ui-text-input-inline>
        <ui-text-input-inline
          (nChange)="setPassword2($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Confirma tu Contraseña'"
          [type]="'password'"
          style="width: 100%"
        ></ui-text-input-inline>
        <span [ngClass]="{ 'input-failed': authError }" *ngIf="authError"> Email invalidos. </span>
      </div>

      <br />

      <div class="row signin-btn">
        <ui-default-button (nClick)="doRecoveryPassword()" [label]="'CAMBIAR CONTRASEÑA'" [color]="'green'"></ui-default-button>
      </div>
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--footer">
      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->
  </form>
</div>
