<div class="campaing__row_card organized_card" *ngIf="campaign && campaign?.title ">
    <div class="campaign__photo">
      <img src="{{ campaign?.photo_1 | json}} " alt="">
    </div>
    <div class="campaign__status_and_title">
      <h1> {{ campaign?.status | campaignStatusLabel  }} </h1>
      <h2> {{ campaign?.title | truncateText: 50 }} </h2>
    </div>
    <div class="campaign__goal_and_donated">
      <div class="goal__progress">
        <ui-widget-percentage-level [currentValue]="(campaign?.donated || (0.0) )" [maxValue]="(campaign?.goal || (0.0))"></ui-widget-percentage-level>
      </div>
      <div class="goal__info"> {{ (campaign?.donated || (0.0) ) | currency: '$':'symbol':'1.0-0' }} / {{ (campaign?.goal || (0.0))| currency: '$':'symbol':'1.0-0' }} </div>
      <div class="goal__date">  {{ campaign?.date_end | date}} </div>
    </div> 
  </div>