import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-work',
  templateUrl: './about-work.component.html',
  styleUrls: ['./about-work.component.less'],
})
export class AboutWorkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
