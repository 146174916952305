<div class="campagins-page--header" 
  [ngClass]="{
    red: type === 'foundations',
    green: type === 'causes'
  }"
>
  <h1>
    <span *ngIf="type === 'foundations'">FUNDACIONES / PROYECTOS</span>
    <span *ngIf="type === 'causes'">CAUSAS</span>
  </h1>
  <h3><span>¿Cómo quieres ayudar al mundo?</span></h3>
  <div class="buttons">
    <button *ngFor="let category of categories | truncateArray: 4" (click)="setCategory(category)">
      {{ 'campaigns.categories.'+category | translate }}
    </button>
  </div>
</div>