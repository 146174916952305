import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-text-input-inline-c',
  templateUrl: './text-input-inline-c.component.html',
  styleUrls: ['./text-input-inline-c.component.less'],
})
export class TextInputInlineCComponent implements OnInit {
  @Input()
  type: string;

  @Input()
  label: string;

  @Input()
  width: string;

  @Input()
  border: boolean;

  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Input()
  error: any;

  @Input()
  errorFn = (value) => {};

  @Input()
  errorText: any;

  @Output()
  nChange = new EventEmitter();

  text = '';
  isFocus = false;

  constructor() {}

  ngOnInit(): void {}

  onFocus() {
    this.isFocus = true;
  }
  offFocus() {
    if (!this.text) {
      this.isFocus = false;
    }
  }

  setText($event) {
    this.text = $event.target.value;
    this.nChange.emit($event.target.value);
  }
}
