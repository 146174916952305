import { Component } from '@angular/core';

@Component({
  selector: 'ui-funs-mobile-header',
  templateUrl: './funs-mobile-header.component.html',
  styleUrls: ['./funs-mobile-header.component.less']
})
export class FunsMobileHeaderComponent {

}
