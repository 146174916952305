<div class="funs device" [ngClass]="{
  'device--mobile': device === DEVICES_TYPES.MOBILE,
  'device--tablet': device === DEVICES_TYPES.TABLET,
  'device--laptop': device === DEVICES_TYPES.LAPTOP,
}">

    <header>
      <ui-header *ngIf="device === DEVICES_TYPES.LAPTOP"></ui-header>
      <ui-funs-mobile-header *ngIf="device === DEVICES_TYPES.MOBILE"></ui-funs-mobile-header>
    </header>

    <main>
      <router-outlet></router-outlet>
    </main>

    <footer>
      <ui-footer *ngIf="device === DEVICES_TYPES.LAPTOP"></ui-footer>
      <ui-funs-mobile-footer *ngIf="device === DEVICES_TYPES.MOBILE"></ui-funs-mobile-footer >
    </footer>

</div>

<!-- -->
<!-- -->
<!-- -->
