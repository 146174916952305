import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
@Pipe({
  name: 'filterByCategory',
})
export class FilterByIsCategoryPipe implements PipeTransform {
  transform(campaigns: ICampaign[], category: string): ICampaign[] {
    if (category && category.length > 0) {
      return campaigns && campaigns.length > 0 ? campaigns.filter((campaign) => campaign.category === category) : [];
    } else {
      return campaigns;
    }
  }
}
