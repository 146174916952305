import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SignInPageComponent } from './signin-page/signin-page.component';
import { SignUpPageComponent } from './signup-page/signup-page.component';
import { SignupFoundationComponent } from './signup-foundation/signup-foundation.component';
import { RecoveryPasswordPageComponent } from './recovery-password-page/recovery-password-page.component';
import { RecoveryPasswordEmailSendPageComponent } from './recovery-password-email-send-page/recovery-password-email-send-page.component';
import { AuthPagesComponent } from './auth-pages.component';
import { RecoveryPasswordChangePageComponent } from './recovery-password-change-page/recovery-password-change-page.component';
import { AlternativePageComponent } from './alternative-page/alternative-page.component';
import { RecoveryPasswordSucessfullComponent } from './recovery-password-sucessfull/recovery-password-sucessfull.component';
import { SignupWelcomePageComponent } from './signup-welcome-page/signup-welcome-page.component';

export const routes = [
  {
    path: 'auth',
    component: AuthPagesComponent,
    canActivate: [],
    children: [
      { path: 'signin', component: SignInPageComponent },
      { path: 'signup', component: SignUpPageComponent },
      { path: 'signup/welcome', component: SignupWelcomePageComponent },
      { path: 'signup/alternatives', component: AlternativePageComponent },
      { path: 'signup/user', component: SignUpPageComponent },
      { path: 'signup/company', component: SignupFoundationComponent },
      { path: 'recovery/password', component: RecoveryPasswordPageComponent },
      { path: 'recovery/password/email/send/:email', component: RecoveryPasswordEmailSendPageComponent },
      { path: 'recovery/password/change/:email/token/:token', component: RecoveryPasswordChangePageComponent },
      { path: 'recovery/password/change', component: RecoveryPasswordChangePageComponent },
      { path: 'recovery/password/successfull', component: RecoveryPasswordSucessfullComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthPagesRoutingModule {}
