<div class="page alternative-page">
  <form class="form-alternative">
    <div class="form-alternative--container">
      <div class="row">
        <img class="head" [src]="img" />
      </div>
      <div class="row">
        <h1 class="title">Regístrate</h1>
      </div>
      <div class="row">
        <h1 class="subtitle" style="color: {{ colorsub }};">como {{ subtitle }}</h1>
      </div>

      <div class="row camp-text">
        <h4 class="text1">{{ text1 }}</h4>
      </div>

      <div class="row alternative-btn">
        <ui-default-button (nClick)="go(btnop)" [label]="btn" [color]="colorbtn"></ui-default-button>
      </div>
      <br />
    </div>
    <div class="form-alternative--footer">
      <div class="row camp-text">
        <h5 class="text2">{{ text2 }}</h5>
      </div>
    </div>
  </form>
</div>
