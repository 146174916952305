import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from "@angular/forms";
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';

@Component({
  selector: 'ui-ng-form-input-textarea',
  templateUrl: './ng-form-input-textarea.component.html',
  styleUrls: ['./ng-form-input-textarea.component.less']
})
export class NgFormInputTextareaComponent implements OnInit, OnChanges {

  @Input() control: FormControl;
  @Input() touched: boolean;
  @Input() label: string;
  @Input() type: string;

  public ID = 'text-area-editor-js';
  public editor: any = null;
  public config = {
    touched: false,
  }

  ngOnInit(){
    this.editor = new EditorJS({
      minHeight: 100,
      holderId: this.ID,
      autofocus: true,
      readOnly: false,
      placeholder: '',
      tools: {
        link: Link,
        header: Header,
        list: List
      },
      onChange: () => {
        this.editor.save().then((outputData) => {
          this.config.touched = true;
          this.setTextAreaValue(JSON.stringify(outputData));
        }).catch((error) => {
          console.log('Saving failed: ', error)
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.control.touched){
      this.config.touched = true;
    }
  }

  setTextAreaValue(text: string){
    this.control.setValue(text);
  }

  get error(): boolean{
    if(this.control && this.config.touched){
      return this.control.invalid
    }
    return false;
  }
}
