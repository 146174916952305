<div class="tabs">

  <div class="tabs__header">
    <div class="tab_title">
      <img src="../../../../../assets/icons/check_icon_subtab.png" />
      <p *ngIf="subtab === 'my_campaigns_recaudo'">Estas son las causas que tú haz realizado, han sido aprobadas y se encuentran en recaudo.</p>
      <p *ngIf="subtab === 'my_campaigns_ejecucion'">¡Solo un paso más! Estamos revisando que el valor  recaudado se haga efectivo en la causa planeada. </p>
      <p *ngIf="subtab === 'my_campaigns_finalizado'">Aquí podrás ver lo que haz hecho para ayudar al mundo ¡sigue generando causas exitosas!</p>
    </div>
    <div class="tab_header" [ngClass]="{ active: subtab === 'my_campaigns_recaudo' }" (click)="subtab = 'my_campaigns_recaudo'">
      CAMPAÑAS EN RECAUDO
    </div>
    <div class="tab_header" [ngClass]="{ active: subtab === 'my_campaigns_ejecucion' }" (click)="subtab = 'my_campaigns_ejecucion'">
      CAMPAÑAS EN EJECUCIÓN
    </div>
    <div class="tab_header" [ngClass]="{ active: subtab === 'my_campaigns_finalizado' }" (click)="subtab = 'my_campaigns_finalizado'">
      CAMPAÑAS FINALIZADAS
    </div>
  </div>

  <div class="tabs__content">
    <ui-campaigns-tab-in-collecting  *ngIf="subtab === 'my_campaigns_recaudo'" [campaigns]="(campaigns | filterByIsOrganized | filterByStatusInCollection)"> <!-- filterByStatusInCollection -->
    </ui-campaigns-tab-in-collecting>

    <ui-campaigns-tab-in-execution  *ngIf="subtab === 'my_campaigns_ejecucion'" [campaigns]="(campaigns | filterByIsOrganized | filterByStatusInProgress)"> <!-- filterByStatusInProgress -->
    </ui-campaigns-tab-in-execution>


    <ui-campaigns-tab-in-finished  *ngIf="subtab === 'my_campaigns_finalizado'" [campaigns]="(campaigns | filterByIsOrganized | filterByStatusFinished)"> <!-- filterByStatusFinished -->
    </ui-campaigns-tab-in-finished>

  </div>

</div>
