import { IPhoto } from '../../../shared/models/photo.model';
import { IPlace, IServerPlace } from '../../../shared/models/place.model';
import { IServerUser, IUser } from '../../users/models/user.model';
import { IComment } from '../../comments/models/comment.model';

export interface IServerCampaign {
  id?: number;

  organizer_id: number;
  place_id: number;

  created_at: Date;
  updated_at: Date;

  organizer?: any;
  company?: string;

  title: string;
  description: string;
  type: string;
  category: string;
  status: string;
  content: string;

  photo_1: string;
  photo_2: string;
  photo_3: string;
  photo_4: string;

  share_link: string;
  share_picture_url: string;

  goal: number;
  date_start: Date;
  date_end: Date;

  summary_html: string;
  summary_photo_url: string;

  isInCover: boolean;
  isInOutstanding: boolean;

  donated?: number;

  stats?: {
    total: number;
    last24hrs: number;
  };

  donors?: any[];
  followers?: any[];

  update_remains?: number;

  place_city?: string;

}

export interface ICampaign {
  id?: number;

  organizer: IServerUser | null;
  company?: string;
  place: IServerPlace | null;

  created_at: Date;
  updated_at: Date;

  title: string;
  description: string;
  type: string;
  category: string;
  status: string;
  content: string;

  photos: string[];

  share_link: string;
  share_picture_url: string;

  goal: number;
  date_start: Date;
  date_end: Date;

  summary_html: string;
  summary_photo_url: string;

  isInCover: boolean;
  isInOutstanding: boolean;

  donated?: number;

  stats?: {
    total: number;
    last24hrs: number;
  };

  donors?: any[];
  followers?: any[];

  comments?: IComment[];

  isLiked?: boolean;
  isDonated?: boolean;
  isOrganized?: boolean;
  update_remains?: number;


  place_city?: string;


}

export const campaignCategories = ['OLDER_ADULTS', 'ANIMALS', 'EDUCATION', 'YOUNG_PEOPLE', 'FAMILIES', 'ENVIRONMENTAL', 'KIDS'];

/*

export interface ICampaign {
  id: string;

  title: string;
  type: 'foundation' | 'cause';
  category: string;
  organizer: string;

  description: string;
  content: string;
  photos: IPhoto[];
  place: IPlace;
  link: string;

  dateStart: Date;
  dateEnd: Date;

  goal: number;

  status: 'OPEN' | 'CLOSED' | 'FINISHED';

  blog?: string;

  isInCover: boolean;
}


*/
