import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { categories } from '../filters/category-filter/categories';

@Component({
  selector: 'ui-campaigns-banner',
  templateUrl: './campaigns-banner.component.html',
  styleUrls: ['./campaigns-banner.component.less']
})
export class CampaignsBannerComponent implements OnInit {

  @Input()
  type: 'foundations' | 'causes' = 'foundations';

  @Output()
  onCategoryChange = new EventEmitter<string>();

  public category = '';
  public categories = [];

  constructor() { 
    this.categories = categories;
  }

  ngOnInit(): void {
  }

  setCategory(category:string){
    this.onCategoryChange.emit(category);
  }

}
