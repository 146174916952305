import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UIService {

  private _sidebar_profile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public sidebar_profile$: Observable<boolean> = this._sidebar_profile.asObservable();

  constructor() { }

  toogleSidebarProfile(){
    const value = this._sidebar_profile.getValue();
    this._sidebar_profile.next(!value);
  }
  openSidebarProfile(){
    this._sidebar_profile.next(true);
  }
  setSidebarProfile(value){
    this._sidebar_profile.next(value);
  }



}
