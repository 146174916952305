import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-title-auth-h1',
  templateUrl: './title-auth-h1.component.html',
  styleUrls: ['./title-auth-h1.component.less'],
})
export class TitleAuthH1Component implements OnInit {
  @Input()
  title: string;

  @Input()
  button: string;

  @Output()
  nClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  doClick(): void {
    this.nClick.emit();
  }
}
