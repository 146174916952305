import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeCoverComponent } from './components/home-cover/home-cover.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';

import { HomeAboutComponent } from './components/home-about/home-about.component';
import { HomePurposesComponent } from './components/home-purposes/home-purposes.component';
import { HomeCallToActionsComponent } from './components/home-call-to-actions/home-call-to-actions.component';
import { HomeOutstandingsBlogsComponent } from './components/home-outstandings-blogs/home-outstandings-blogs.component';
import { HomeOutstandingsCampaignsComponent } from './components/home-outstandings-campaigns/home-outstandings-campaigns.component';
import { HomeCategoryCampaignsComponent } from './components/home-category-campaigns/home-category-campaigns.component';

import { TruncateTextPipe } from 'src/app/shared/pipes/TruncateText.pipe';
import { FilterByIsCoverPipe } from './pipes/filterByIsCover.pipe';
import { FilterByIsOutstandingPipe } from './pipes/filterByIsOutstanding.pipe';
import { FilterByIsFinishedPipe } from './pipes/filterByIsFinished.pipe';
import { FilterByIsActivePipe } from './pipes/filterByIsActive.pipe';
import { FilterByIsCategoryPipe } from './pipes/filterByCategory.pipe';
import { FilterByExcludingIdPipe } from './pipes/filterByExcludingId.pipe';
import { RouterModule } from '@angular/router';

const COMPONENTS = [
  HomeCoverComponent,
  HomeAboutComponent,
  HomePurposesComponent,
  HomeCallToActionsComponent,
  HomeOutstandingsBlogsComponent,
  HomeOutstandingsCampaignsComponent,
  HomeCategoryCampaignsComponent,
];

const PIPES = [FilterByIsCoverPipe, FilterByIsOutstandingPipe, FilterByIsFinishedPipe, FilterByIsActivePipe, FilterByIsCategoryPipe, FilterByExcludingIdPipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...COMPONENTS, ...PIPES],
  imports: [CommonModule, SharedModule, CoreModule, RouterModule],
})
export class HomePageModule {}
