import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-uikit-form-default',
  templateUrl: './uikit-form-default.component.html',
  styleUrls: ['./uikit-form-default.component.less'],
})
export class UikitFormDefaultComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
