<div class="campaing-last-donors">
  <div class="campaing--row" *ngFor="let donor of donors">

    <div class="col picture">
      <img class="donor--photo" [src]="getUserPhoto(donor.user[0], donor.is_anonimous)" />
    </div>

    <div class="col text">
      <h1 class="donor--name">
        {{ getUserName(donor.user[0], donor.is_anonimous) | truncateText: 15 }} donó
        {{ donor.amount | currency: '$':'symbol':'1.0-0' }}
      </h1>
      <h3 class="donor--time">
        Hace {{ (donor.created_at | dateDiffTime).time | number: '1.0-0' }}
        {{ (donor.created_at | dateDiffTime).label }}
      </h3>
    </div>
  </div>
</div>
