import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPlace } from 'src/app/shared/models/place.model';

@Component({
  selector: 'ui-campaign-map',
  templateUrl: './campaign-map.component.html',
  styleUrls: ['./campaign-map.component.less'],
})
export class CampaignMapComponent implements OnInit {
  @Input()
  editable: boolean;

  @Input()
  place: IPlace;

  @Input()
  place_city?: string;

  @Output()
  nChange = new EventEmitter();

  lat = 77.753568;
  lon = -154.993411;

  constructor() {}

  ngOnInit(): void {}

  public get latitude(): number {
    return parseFloat(this.place.lat + '') + 0.0;
  }
  public get longitude(): number {
    return parseFloat(this.place.lon + '') + 0.0;
  }

  public markerDragEnd($event) {
    const lat = $event.latLng.lat();
    const lng = $event.latLng.lng();
    this.nChange.emit({ lat, lng });
  }
}
