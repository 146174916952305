import { Component, OnInit , Output , Input, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { categories } from '../filters/category-filter/categories';
export type IFilterType = {
  sort: string;
  keyword: string;
  categories: string[];
  place: {
    country: string;
    state: string;
    city: string;
  }
}

@Component({
  selector: 'ui-campaigns-filters',
  templateUrl: './campaigns-filters.component.html',
  styleUrls: ['./campaigns-filters.component.less']
})
export class CampaignsFiltersComponent implements OnInit, OnChanges {

  @Input()
  filter:IFilterType;

  @Output()
  onChange = new EventEmitter<IFilterType>();

  public isClear = false;
  public categories = [];
  public _filter:IFilterType = {
    categories: [],
    sort:'',
    keyword: '',
    place:{
      country:'',
      state:'',
      city:''
    }
  }
  constructor() { 
    this.categories = categories;
  }

  ngOnInit(): void {
    this._filter = this.filter;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this._filter = this.filter;
  }



  toogleCategory($category){
    let categories = JSON.parse(JSON.stringify(this._filter.categories));
    if(categories.includes($category)){
      categories = categories.filter(item => item !== $category);
    } else {
      categories.push($category);
    }
    categories = [...new Set(categories)];
    this._filter.categories = categories;
  }
  
  addCategory(categoryName: string){}
  
  remoteCategory(categoryName: string){}


  isFill(category){
    return this._filter.categories.includes(category)
  }

  setCategory($event){
    this.isClear = false;
    /*
    if(this._filter.category === $event){
      this._filter.category = '';
    } else {
      this._filter.category = $event;
    }
    */
  }

  setPlace($event){
    this.isClear = false;
    this._filter.place = $event;
  }

  applyChanges(){
    this.isClear = false;
    this.onChange.emit(Object.assign({}, {
      ...this._filter
    }));
  }
  clearChanges(){
    this.isClear = true;
    this.onChange.emit(Object.assign({}, {
      ...this.filter,
      category: '',
      place: {
        country: '',
        state: '',
        city: ''
      }
    }));
  }

}
