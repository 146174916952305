import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { ProfilePageService } from 'src/app/pages/profile-page/profile-page.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { Router } from '@angular/router';
import { IStatusEnum, IStatusOption, IStatusValue } from 'src/app/core/campaigns/models/status.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-profile-tabs-admin',
  templateUrl: './profile-tabs-admin.component.html',
  styleUrls: ['./profile-tabs-admin.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTabsAdminComponent implements OnInit,OnChanges {

  checked = false;

  constructor(
    private toastr: ToastrService,
    private $campaigns: CampaignsService,
    private $profile: ProfilePageService, private router: Router) {}

  public get table() {
    if (this.campaigns.length > 0) {
      this.data = this.campaigns;
    }
    return this.campaigns;
  }

  @ViewChild('statusTpl', { static: true }) statusTpl: TemplateRef<any>;
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;

  @Input()
  campaigns: ICampaign[];

  @Input()
  filter: any;

  @Input()
  user: IUser | any = {};

  editRow: number;
  campaign: ICampaign | any;

  public StatusL1: IStatusOption[] = [
    {
      value: IStatusEnum.S0_IN_PROCESS,
      blockers: []
    },
    {
      value: IStatusEnum.S1_IN_PENDING,
      blockers: [IStatusEnum.S3_PUBLISHED]
    },
    {
      value: IStatusEnum.S2_IN_CORRECTING,
      blockers: []
    },
    {
      value: IStatusEnum.S3_PUBLISHED,
      blockers: [IStatusEnum.S0_IN_PROCESS]
    },
  ];

  public StatusL2: IStatusOption[] = [
    {
      value: IStatusEnum.S4_IN_COLLECTING,
      blockers: [IStatusEnum.S0_IN_PROCESS,IStatusEnum.S1_IN_PENDING,IStatusEnum.S2_IN_CORRECTING]
    },
    {
      value: IStatusEnum.S5_IN_PAUSE,
      blockers: [IStatusEnum.S0_IN_PROCESS,IStatusEnum.S1_IN_PENDING,IStatusEnum.S2_IN_CORRECTING]
    },
    {
      value: IStatusEnum.S6_IN_EXECUTION,
      blockers: [IStatusEnum.S0_IN_PROCESS,IStatusEnum.S1_IN_PENDING,IStatusEnum.S2_IN_CORRECTING]
    },
    {
      value: IStatusEnum.S7_CANCELLED,
      blockers: [IStatusEnum.S0_IN_PROCESS,IStatusEnum.S1_IN_PENDING,IStatusEnum.S2_IN_CORRECTING]
    },
    {
      value: IStatusEnum.S8_FINISHED,
      blockers: [IStatusEnum.S0_IN_PROCESS,IStatusEnum.S1_IN_PENDING,IStatusEnum.S2_IN_CORRECTING]
    },
  ];

  public data = [];
  public columns: Columns[];
  public configuration: Config;
  public tab = 'admin_campaigns';

  expanded = {};
  timeout: any;

  ColumnMode = ColumnMode;

  modal = false;
  selected;

  ngOnInit(): void {

    this.$profile.filter$.subscribe((filter) => {
      this.filter = filter;
      console.log(' this.filter ');
      console.log(this.filter)
      this.updateNxTable();
    });

    this.doLoadTable();
    setTimeout(() => {
      this.$profile.update();
    }, 1000);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.campaigns && this.campaigns.length > 0) {
        this.$profile.update();
    }
  }

  updateNxTable() {

    const city = this.filter ? this.filter.city : '';
    const amount = this.filter ? this.filter.amount : { high: null, low: null };
    const type = this.filter ? this.filter.type : 'both';

    console.log('Probando upDateNXTable');
    console.log(this.campaigns);

    if (this.campaigns.length > 0) {
      // const filtered = this.campaigns
      //   .filter((campaign: ICampaign) => {

      //     if (city) {
      //       if (city === 'NONE' || city === '-' || city === '') {
      //         return true;
      //       } else {
      //         return campaign.place.city.toUpperCase().includes((this.filter.city || '').toUpperCase());
      //       }
      //     } else {
      //       return true;
      //     }
      //   })
      //   .filter((campaign: ICampaign) => {
      //     if (amount) {
      //       if (amount.high && amount.low) {
      //         return campaign.goal < amount.high && campaign.goal > amount.low;
      //       } else if (!amount.high && amount.low) {
      //         return campaign.goal > amount.low;
      //       } else if (amount.high && !amount.low) {
      //         return campaign.goal < amount.high;
      //       } else {
      //         return true;
      //       }
      //     } else {
      //       return true;
      //     }
      //   })
      //   .filter((campaign: ICampaign) => {
      //     if (type) {
      //       if (type === 'both') {
      //         return campaign.type === 'FOUNDATION' || campaign.type === 'CAUSE';
      //       } else if (type === 'cause') {
      //         return campaign.type === 'CAUSE';
      //       } else if (type === 'project') {
      //         return campaign.type === 'FOUNDATION';
      //       } else {
      //         return true;
      //       }
      //     } else {
      //       return true;
      //     }
      //   });
      const filtered = this.campaigns;
      console.log('Probando filtered');
      console.log(filtered);

      this.data = filtered.map((campaing: ICampaign) => {
        return {
          id: campaing.id,
          photo: campaing.photos[0] || '',
          title: campaing.title,
          company: campaing.company,
          organizer: campaing.organizer ? (campaing.organizer as any).name : this.user.name,
          category: campaing.category,
          city: campaing.place_city || 'NA',
          amount: campaing.goal,
          date_start: campaing.date_start,
          date_end: campaing.date_end,
          status: campaing.status,

          // Is in Cover and Outstanding
          isInCover: Boolean(campaing.isInCover),
          isInOutstanding: Boolean(campaing.isInOutstanding)

        };
      });
    }
  }

  setTab(tabName) {
    this.tab = tabName;
  }
  doLoadTable() {
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.configuration.selectRow = true;
    // ... etc.
    this.columns = [
      { key: 'id', title: '#ID', width: '2.5%' , placeholder:' '},
      { key: 'photo', title: 'TÍTULO', placeholder:' '},
      { key: 'title', title: 'AUTOR' , placeholder:' '},
      { key: 'organizer', title: 'CATEGORÍA' , placeholder:' '},
      { key: 'category', title: 'CIUDAD' , placeholder:' '},
      { key: 'city', title: 'CANTIDAD' , placeholder:' '},
      { key: 'amount', title: 'INICIO' , placeholder:' '},
      { key: 'date_start', title: 'FIN' , placeholder:' '},
      { key: 'date_end', title: 'STATUS' , placeholder:' '},
      // { key: 'status', title: 'Status', cellTemplate: this.statusTpl },
      // { key: 'action', title: 'Actions', cellTemplate: this.actionTpl, width: '12%' },
    ];
  }
  edit(rowIndex: number): void {
    this.editRow = rowIndex;
  }

  onEvent(event: { event: string; value: any }): void {
    this.selected = JSON.stringify(event.value.row, null, 2);
  }

  showModal(row): void {
    this.campaign = this.campaigns.find((campaing) => campaing.title === row.title);
    this.modal = true;
  }

  showLink(row): void{
    window.alert('campaign/'+row.id)
    window.open('/campaing/'+row.id, '_blank');
  }

  formatCategory(category) {
    return ('campaings.categories.' + category.toUpperCase()).replace(/ /g, '_');
  }

  formatStatus(status) {
    return ('campaings.status.' + status.toUpperCase()).replace(/ /g, '_');
  }

  goToCampaing(row): void {
    this.router.navigateByUrl(`/campaing/${row.id}`);
  }

  sendMessage(row): void {

  }

  saveCamapignVisibility($event){

    const id = $event.id;
    if(!id) return false;

    const $row = $event;
    $row.isSaving = true;

    setTimeout(() => {
      $row.isSaving = false;
    }, 2500)

    this.$campaigns.patch(id, {
      isInCover: $event.isInCover,
      isInOutstanding: $event.isInOutstanding,
      isOnlyCoverUpdate: true,
    }).then(response => {
      console.log(' THEN ')
      $row.isSaving = false;
    }).catch( error => {
      console.log(' CATCH ')
      this.toastr.error(error, 'Error guardando en campaña')
    })


  }




  onChangeIsInCover($event){
    console.log(' ')
    console.log(' onChangeIsInCover($event) ')
    console.log($event)
    console.log(' ')
  }

  onChangeIsInOutstanding($event){
    console.log(' ')
    console.log(' onChangeIsInOutstanding($event) ')
    console.log($event)
    console.log(' ')
  }

  hideModal(): void {
    this.modal = false;
  }

  setStatus($event, campaignId: string) {
    const status = $event.target.value;
    this.campaign = this.campaigns.find((_campaing) => (_campaing.id + '') == campaignId);
    const campaign = JSON.parse(JSON.stringify(this.campaign) + '');
    campaign.status = status;
    delete campaign.id;
    campaign['isAdmin'] = true;
    if (window.confirm('Seguro desea cambiar el status de esta campaña? ')) {
      // Save it!
      this.$campaigns
        .patch(campaignId, campaign)
        .then((response) => {
          console.log(' this.$campaigns.patch(campaign.id, campaign) ');
          console.log({ response, campaignId, campaign });
          this.$campaigns.fetchAllCampaigns();
        })
        .catch((error) => {
          console.error(' ERROR : this.$campaigns.patch(campaign.id, campaign) ');
          console.error({ error, campaignId, campaign });
        });
    }
  }


  isStatusL1Disabled(status: IStatusValue): boolean{
    const isEnableL1 = (status === 'S5_IN_PAUSE') || this.StatusL1.map(item => item.value).includes(status);
    return !isEnableL1;
    return this.StatusL1.filter(item => item.value != 'S3_PUBLISHED').map(item => item.value).includes(status);

  }
  isStatusL2Disabled(status: IStatusValue): boolean{
    const isEnableL2 = (status === 'S3_PUBLISHED') || this.StatusL2.map(item => item.value).includes(status);
    return !isEnableL2;
  }
  isStatusDisabled(row_status: IStatusValue, status: IStatusOption): boolean{
    return status.blockers.includes(row_status) || status.value === row_status;
  }

  deleteCampaign(row: any){
    const campaignId = row.id;
    Swal.fire({
      title: 'Seguro desea borrar esta campaña?',
    text: "Se borrara la campaña para siempre",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Si , borrar!',
    cancelButtonText: 'No, cancela!',
    reverseButtons: true
    }).then((result) => {
      if(result.isConfirmed) {
        this.$campaigns.removeById(campaignId).then(() => {
          setTimeout(() => {
            this.$campaigns.fetchAllCampaigns()
          }, 2500);
        });
      } else{
        console.error(' NOT BORRANDO')
      }
    })
  }
}
