import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, limite: string): string {
    const limit = parseInt(limite, 10);
    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }
}
