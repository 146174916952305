<div class="page-about page-about-us">
  <section class="section-title">
    <h1>
      Requisitos de permanencia <br />
      Regimen Tributario Especial - ESAL
    </h1>
  </section>

  <section class="section-green">
    <br />
    <br />
    <br />
    <p>
      1. Contamos con un informe anual de resultados o Informe de gestión para el Sector Cooperativo que provee datos sobre sus proyectos en curso y los
      finalizados, los ingresos, los contratos realizados, subsidios y aportes recibidos, así como las metas logradas en beneficio de la comunidad.
      <ui-default-button (nClick)="go()" [label]="'Descargar Documento'" [color]="'white'"></ui-default-button>
    </p>

    <br />
    <p>
      2. Estados financieros de la entidad.
      <!-- <ui-outline-btn [color]="'red'" [margins]="'0em'" [paddings]="'1em'" [minWidth]="'250px'" [text]="'Descargar Documento'" (nClick)="go()">
        </ui-outline-btn> -->
    </p>
    <br />
    <p>
      3. Certificación de Requisitos del representante legal o revisor fiscal en el que evidencia que han cumplido con todos los requisitos durante el
      respectivo año del respectivo año. (Numeral 13 del parágrafo 2 del artículo 364-5 E.T.)
    </p>
    <br />
    <p>4. Copia de la escritura pública, documento privado o acta de constitución en donde conste que está legalmente constituida.</p>

    <br />
    <p>
      5. Certificado de existencia y representación legal expedido por la autoridad competente, en el cual figuren sus administradores, representantes y
      miembros del consejo u órgano directivo cuando hubiere lugar a ello.
    </p>
  </section>

  <section class="section-pink">
    <br />
    <p>
      6. Copia del acta de la Asamblea General o máximo órgano de dirección, en la que se autorice al representante legal para que solicite que la entidad
      permanezca como entidad del Régimen Tributario Especial del Impuesto sobre la Renta.
    </p>
    <br />
    <p>
      7. Copia de los estatutos de la entidad o acta del máximo órgano directivo donde se indique que el objeto social principal corresponde a una actividad
      meritoria, que los aportes no son reembolsados, que sus excedentes no son distribuidos y que se identifique los cargos directivos de la entidad. (Numeral
      4 del Art. 1.2.1.5.1.8 del Decreto 1625 del 2016).
    </p>
    <br />
    <p>
      8. Certificación del Representante Legal de los antecedentes judiciales y declaración de caducidad de contratos estatales de los miembros de la junta
      directiva, fundadores, representantes legales o miembros de los órganos de dirección de conformidad con el numeral 3 artículo 364-3 E.T.
    </p>
    <br />
    <p>
      9. Acta de Asamblea General o máximo órgano de dirección que indica el estado de las asignaciones permanentes de los años gravables anteriores de
      conformidad con el artículo 1.2.1.5.1.27 del Decreto 1625 de 2026, si hay lugar a ello.
    </p>

    <br />
    <p>
      10. Acta de Asamblea General o máximo órgano de dirección en el que se aprobó la distribución del beneficio neto o excedente del respectivo año gravable,
      si hay lugar a ello.
    </p>
  </section>

  <section class="section-green">
    <br />
    <p>
      11. Registrar la Memoria Económica cuando los ingresos brutos, incluidas las ganancias ocasionales, del año gravable 2019 fueron iguales o superiores a
      160.000 UVT ($5.483.200.000.)
    </p>
    <br />
    <p>12. Formatos 2530, 2531, 2532 XML.</p>
    <br />
    <p>13. Declaración de renta.</p>
    <br />
    <p>14. Certificación remuneración directivos.</p>
  </section>
</div>
