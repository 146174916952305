<div class="campaigns-list" *ngIf="campaigns && campaigns.length > 0">
  <div class="campaing-card" *ngFor="let campaign of campaigns">
    <ui-card-campaign [campaign]="campaign" [theme]="campaign.status"></ui-card-campaign>
  </div>
</div>

<div class="campaigns-list" *ngIf="campaigns && campaigns.length === 0">
  <div class="campaing-card">
    <ui-card-empty [card_type]="'finished_tutorial_step_1'"></ui-card-empty>
  </div>
  <div class="campaing-card">
    <ui-card-empty [card_type]="'finished_tutorial_step_2'"></ui-card-empty>
  </div>
  <div class="campaing-card">
    <ui-card-empty [card_type]="'finished_tutorial_step_3'"></ui-card-empty>
  </div>
</div>
