<form>
  <div class="form--header">
    <ui-title-auth-h1 [button]="'x'" [title]="'Regístrate'"> </ui-title-auth-h1>
  </div>

  <div class="form--body">
    <!--- -------------------------------- -->
    <!--- -- SOCIAL NETWORK SIGNUP ------- -->
    <!--- -------------------------------- -->
    <div class="form--row social-options">
      <br />
      <!--
        <ui-button-facebook-login [btnStyle]="'btn-border'" [btnLabel]="'auth.FACEBOOK_SIGNUP'"></ui-button-facebook-login>
        <ui-button-gmail-login [btnStyle]="'btn-border'" [btnLabel]="'auth.GMAIL_SIGNUP'"></ui-button-gmail-login>
      -->
    </div>
    <!--- -------------------------------- -->

    <div class="form--row div">
      <img src="../../../../../../assets/icons/Grupo 573.png" width="100%" style="padding: 1em 0em" />
    </div>

    <div class="form--row inputs">
      <div class="input--row x2">
        <ui-text-input-inline
          (nChange)="setFirstName($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Nombre'"
          [type]="'text'"
          style="width: 100%"
        ></ui-text-input-inline>

        <ui-text-input-inline
          (nChange)="setLastName($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Apellido'"
          [type]="'text'"
          style="width: 100%; margin-left: 60px"
        ></ui-text-input-inline>
      </div>

      <span [ngClass]="{ 'input-failed': nameError }" *ngIf="nameError"> Nombre o Apellido Invalidos </span>

      <div class="input--row x1">
        <ui-text-input-inline
          (nChange)="setEmail($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Correo electrónico'"
          [type]="'email'"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>
      <span [ngClass]="{ 'input-failed': emailError }" *ngIf="emailError"> Correo Invalido </span>

      <div class="input--row x1">
        <ui-text-input-inline
          (nChange)="setMobile($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Número celular'"
          [type]="'text'"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>

      <div class="input--row x1">
        <ui-text-input-inline
          (nChange)="setPassword($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Contraseña'"
          [type]="'password'"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>

      <span [ngClass]="{ 'input-failed': passwordError }" *ngIf="passwordError"> Contraseña Invalido </span>
    </div>

    <div class="form--row info-password">
      <a>Tu contraseña debe tener al menos 8 caracteres 1 letra mayúscula y 1 símbolo. </a>
    </div>

    <div class="form--row signup-btn">
      <div class="col-button">
        <h4 style="color: #9d9d9c; font-size: 20px; font-family: 'Poppins-Regular'">
          ¿Ya tienes una cuenta?
          <a [routerLink]="'/auth/signin'" style="color: #58b999">Iniciar sesión</a>
        </h4>
        <span style="width: 475px; font-size: 15px; color: #9d9d9c"
          >Al continuar aceptas todos nuestros <a style="color: #58b999" href="">términos y condiciones</a></span
        >
      </div>
      <div class="col-routes foundation"><button (click)="doConfirm()">REGISTRARME</button> <br /></div>
    </div>
  </div>

  <div class="form--footer">
    <div class="row more-info">
      <h5>
        Para saber más sobre nuestras políticas y funcionamiento puedes hacer
        <a style="color: #58b999" href="">clic aqui</a>
      </h5>
    </div>
  </div>
</form>
