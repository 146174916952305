import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.less'],
})
export class ProfilePageComponent implements OnInit {
  // sessionStorage.setItem('user', JSON.stringify(userResponse))

  public user: IUser | any = {};

  public mode = 'USER';
  public tab = 'my_donations';

  // public mode = 'ADMIN';
  // public tab = 'admin_in_pending';

  public campaigns$: Observable<ICampaign[]>;
  public campaingsCover$: Observable<ICampaign[]>;
  public campaingsProfile$: Observable<ICampaign[]>;

  public campaignsDonatedNumber = 0;
  public campaignsReceivedNumber = 0;
  public configuration: Config;
  public columns: Columns[];

  public data = [];
  public filter = {
    date: {
      create: {
        start: null,
        end: null,
      },
      close: {
        start: null,
        end: null,
      },
    },
    amount: null,
    city: null,
    department: null,
    type: null,
  };

  public isAdmin$: Observable<boolean>;

  constructor(private router: Router, private route: ActivatedRoute, private $campaigns: CampaignsService, private $user: UserService){
    this.isAdmin$ = this.$user.isAdmin$;
  }

  async ngOnInit() {
    this.campaigns$ = this.$campaigns.syncCampaigns();
    this.campaingsCover$ = this.$campaigns.syncCoverCampaigns();
    this.campaingsProfile$ = this.$campaigns.syncProfileCampaigns();

    let user = JSON.parse(sessionStorage.getItem('user'));
    let userId;
    if (!user) {
      userId = this.route.snapshot.paramMap.get('id');
      user = await this.$user.getUserById(userId);
    } else {
      userId = user.id;
    }
    this.$user.setUser(user);

    if (user) {
      user.company = user.company;
      user.name = user.name;
      user.firstname = user.firstname;
      user.lastname = user.lastname;
      user.email = user.email;
      user.photo_url = user.photo_url || 'https://ui-avatars.com/api/?background=random&name=' + encodeURI(user.name);
      this.user = user;
    }

    if (this.user.email === 'wallamejorge@hotmail.com' || this.user.email === 'liudezain@gmail.com' || this.user.email === 'civil.delgado@hotmail.com') {
      this.mode = 'ADMIN';
      this.mode = 'USER';
      this.tab = 'admin_in_pending';
      const allCampaigns = await this.$campaigns.fetchAllCampaigns('all');
    } else {
      this.mode = 'USER';
      this.tab = 'following_donations';
      const allCampaigns = await this.$campaigns.fetchAllCampaigns('open');
    }

    const coverCampaigns = await this.$campaigns.fetchCoverCampaigns();
    const myProfileCampaigns = await this.$campaigns.fetchMyProfileCampaigns(userId);

    this.campaingsProfile$.subscribe((campaigns: ICampaign[]) => {
      if (campaigns) {
        this.campaignsDonatedNumber = campaigns.filter((campaign) => campaign.isDonated).length;
        this.campaignsReceivedNumber = campaigns.filter((campaign) => campaign.isOrganized && campaign.stats && campaign.stats.total).map((campaign) => campaign.stats.total).length;
        console.log(campaigns.filter((campaign) => campaign.stats));
        
      }
    });

    console.error('user');
    console.error(user);
  }

  setFilter($event) {
    this.filter = $event;
  }

  public goCampaigns() {}

  public goStartCampaign() {
    const type = (this.user.rol === 'USER' || this.user.rol === 'ADMIN') ? 'cause': 'foundation';
    this.router.navigate(['/create/campaign/type/'+type]);
  }

  public setTab(tabName) {
    this.tab = tabName;
  }

  public deleteCampaing(campaing: ICampaign) {
    this.$campaigns.removeById(campaing.id);
  }
}
