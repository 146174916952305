import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from './../../core/campaigns/models/campaign.model';
@Pipe({
  name: 'filterBySearchKeyword',
})
export class FilterBySearchKeywordPipe implements PipeTransform {
  transform(values: ICampaign[], keyword: string): any[] {
    if (values && keyword && keyword.length > 0) {
      return JSON.parse('' + JSON.stringify(values)).filter(
        (value: ICampaign) =>
          value.title.toUpperCase().includes(keyword.toUpperCase()) ||
          value.description.toUpperCase().includes(keyword.toUpperCase()) ||
          (value.id + '').toUpperCase().includes(keyword.toUpperCase()) ||
          (value.id + '') == keyword
      );
    } else {
      if(values){
        return JSON.parse('' + JSON.stringify(values));
      } else return [];
    }
  }
}
