<button class="button wompi-btn" (click)="doSelectWompi()" [ngClass]="{ selected: selected }">
  <img src="../../../../../assets/icons/wompi-btn.png" />
  <form #wompiForm method="post" [action]="formAction" style="display: none" action="https://checkout.wompi.co/p/" method="GET">
    <!-- OBLIGATORIOS -->
    <input type="hidden" name="public-key" [value]="wompiPubKey" />
    <input type="hidden" name="currency" [value]="wompiCurrency" />
    <input type="hidden" name="amount-in-cents" [value]="wompiAmountInCents" />
    <input type="hidden" name="reference" [value]="wompiReference" />
    <!-- OPCIONALES -->
    <input type="hidden" name="redirect-url" [value]="wompiRedirectURL" />
    <input type="hidden" name="tax-in-cents:vat" [value]="wompiTaxCentsVat" />
    <input type="hidden" name="tax-in-cents:consumption" [value]="wompiTaxCentsConsumption" />
    <input type="hidden" name="customer-data:email" [value]="wompiCustomerDataEmail" />
    <input type="hidden" name="customer-data:full-name" [value]="wompiCustomerFullName" />
    <input type="hidden" name="customer-data:phone-number" [value]="wompiCustomerDataPhoneNumber" />
    <input type="hidden" name="customer-data:legal-id" [value]="wompiCustomerDataLegalId" />
    <input type="hidden" name="customer-data:legal-id-type" [value]="wompiCustomerDataLegalIdType" />
    <input type="hidden" name="shipping-address:address-line-1" [value]="wompiShippingAddressLine1" />
    <input type="hidden" name="shipping-address:country" [value]="wompiShippingAddressCountry" />
    <input type="hidden" name="shipping-address:phone-number" [value]="wompiShippingAddressPhone" />
    <input type="hidden" name="shipping-address:city" [value]="wompiShippingAddressCity" />
    <input type="hidden" name="shipping-address:region" [value]="wompiShippingAddressRegion" />
    <button type="submit">Pagar con Wompi</button>
  </form>

</button>

<ng-progress #progressBar [color]="'#58b999'" [thick]="false"></ng-progress>
