import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-requirements-page',
  templateUrl: './requirements-page.component.html',
  styleUrls: ['./requirements-page.component.less'],
})
export class RequirementsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  go() {}
}
