
<button
  class="button outline-button"
  [ngStyle]="{
    'min-width': minWidth || '0px',
     margin: margins || '0px 18px',
     padding: paddings || '6px 18px'
  }"
  [ngClass]="{
    'color-red': color === 'red',
    'color-green': color === 'green',
    'color-gray-strong': color === 'gray',
    'color-gray': color === 'none',

    'color-active': active
  }"
  (click)="onClick()"
>
  <span *ngIf="pipe === 'currency' && !currency"> {{ text | currency: '$':'symbol':'1.0-0' }} COP </span>

  <span *ngIf="pipe === 'currency' && currency"> {{ text | currency: '$':'symbol':'1.0-0' }} {{ currency }} </span>

  <span *ngIf="!pipe">{{ text | translate }}</span>
</button>
