import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TabView } from "primeng/tabview";
import { Observable } from 'rxjs';
import { DEVICES } from 'src/app/app.component';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IComment } from 'src/app/core/comments/models/comment.model';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';
import { PaypalBtnComponent } from 'src/app/shared/components/buttons/paypal-btn/paypal-btn.component';
import { PayuBtnComponent } from 'src/app/shared/components/buttons/payu-btn/payu-btn.component';
import { WompiBtnComponent } from 'src/app/shared/components/buttons/wompi-btn/wompi-btn.component';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { EpaycoService, IEpaycoPayload } from "src/app/shared/services/epayco.service";
import { ResponsiveService } from 'src/app/v2/v2-funs-mobile/services/responsive.service';
import Swal from "sweetalert2";

@Component({
  selector: 'ui-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.less'],
})
export class CheckoutPageComponent implements OnInit {

  device = DEVICES.MOBILE;
  DEVICES_TYPES = DEVICES;

  public isModalShared = false;
  public isModalSuccess = false;

  public campaign$: Observable<ICampaign>;
  public campaign: ICampaign;

  public user$: Observable<IUser>;
  public user: IUser;

  public isAnonymous: boolean = false;
  public isLogged: boolean = false;
  public isColombia: boolean = true;
  public isUserTab: boolean = true;
  public isCompanyTab: boolean = false;

  public campaignId: number = -1;

  public form: FormGroup;

  public OPTIONS_CURRENCY = ['COP', 'USD', 'EUR'].map((item) => ({ value: item, label: item }));
  public OPTIONS_NID_TYPES = [
    { value: 'CC', label: 'Cédula de Ciudadanía' },
    { value: 'CE', label: 'Cédula de Extranjería' },
    { value: 'TI', label: 'Tarjeta de Identidad' }
  ];
  public OPTIONS_ORIGINS = [
    { value: 'web', label: 'Sitio Web' },
    { value: 'social', label: 'Redes Sociales' },
    { value: 'friend', label: 'Referido por un amigo' }
  ];

  public EPAYCO = {
    disabled : false
  }

  @ViewChild('tabView') tabView: TabView;

  @ViewChild(PayuBtnComponent) payuBtnComponent: PayuBtnComponent;
  @ViewChild(PaypalBtnComponent) paypalBtnComponent: PaypalBtnComponent;
  @ViewChild(WompiBtnComponent) wompiBtnComponent: WompiBtnComponent;

  constructor(
    private fb: FormBuilder,
    private $user: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private epayco: EpaycoService,
    private $countries: CountriesService,
    private responsive: ResponsiveService,
    private $campaigns: CampaignsService) {

  }

  get isErrorAmount(){
    if(this.form.get('donation_amount')){
      return this.form.get('donation_amount').invalid && (this.form.get('donation_amount').dirty || this.form.get('donation_amount').touched)
    }
    return false;
  }

  async ngOnInit() {

    const campaignId = this.route.snapshot.paramMap.get('id');
    this.campaignId = Number(campaignId);

    this.user$ = this.$user.syncUser();
    this.campaign$ = this.$campaigns.syncCampaign(Number(campaignId));

    this.responsive.device$.subscribe(response => this.device = response);

    this.user$.subscribe((user) => {
      if(user){
        this.user = user;
        this.isLogged = true;
      }
    });
    this.campaign$.subscribe((campaign) => {
      if(campaign){
        this.campaign = campaign;
      }
    });

    this.$campaigns.fetchCampaignsById(Number(campaignId));

    this.doSetCheckoutFormGroup();

  }

  onTabChange(event: any) {
    if(event.index === 0){
      this.isUserTab = true;
      this.isCompanyTab = false;
    } else {
      this.isUserTab = false;
      this.isCompanyTab = true;
    }

    this.doSetCheckoutFormGroup();
  }

  public doSetCheckoutFormGroup(){
    const isCompanyTab = this.isCompanyTab;
    const isUserTab = this.isUserTab;
    const formUser = {
      user_firstname: [this.user?.firstname || null, [Validators.required]],
      user_lastname: [this.user?.lastname || null, [Validators.required]],
      user_nid_type: [(this.user?.nid_type || null), Validators.required],
      user_nid_number: [this.user?.nid_number || null, Validators.required],
      user_email: [this.user?.email || null, [Validators.required, Validators.email]],
      user_phone: [this.user?.phone || null, Validators.required],
    };
    const formCompany = {
      company_name: [this.user?.firstname || null, [Validators.required]],
      company_nid_type: ['NIT', Validators.required],
      company_nid_number: [null, Validators.required],
      company_email: [this.user?.email || null, [Validators.required, Validators.email]],
      company_phone: [this.user?.phone || null, Validators.required],
    };
    const formDonation = {
      donation_campaign_id: [this.campaignId, [Validators.required]],
      donation_currency: ['COP', [Validators.required]],
      donation_is_anonymous: [false, []],
      donation_amount: [null, [Validators.required, Validators.max(1000000), Validators.min(10000)]],
      donation_place: [{
        country: 'Colombia',
        department: 'Bogotá D.C.',
        city:'Bogotá D.C.'
      }, [Validators.required]],
      donation_origin: [null, ],
      donation_postcode: [null, ]
    };

    let formGroup ;
    if(isUserTab) formGroup = { ...formUser, ...formDonation };
    else formGroup = { ...formCompany, ...formDonation };

    this.form = this.fb.group({ ...formGroup }, { validator: [] } as AbstractControlOptions);
  }

  onChangeCurrency($event){
    const control_currency = this.form.get('donation_currency');
    const control_amount= this.form.get('donation_amount');
    if(control_currency.value === 'USD'){
      control_amount.setValue(5);
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'EUR'){
      control_amount.setValue(5);
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'COP'){
      control_amount.setValue(10000);
      control_amount.setValidators([Validators.min(10000), Validators.max(1000000)])

    }
  }

  onAnonymousChange($event){
    this.isAnonymous = $event.target.checked;
    if(this.isAnonymous){

      this.form.controls.user_firstname.disable();
      this.form.controls.user_lastname.disable();
      this.form.controls.user_nid_type.disable();
      this.form.controls.user_nid_number.disable();
      this.form.controls.user_email.disable();
      this.form.controls.user_phone.disable();

      this.form.controls.user_firstname.clearValidators();
      this.form.controls.user_lastname.clearValidators();
      this.form.controls.user_nid_type.clearValidators();
      this.form.controls.user_nid_number.clearValidators();
      this.form.controls.user_email.clearValidators();
      this.form.controls.user_phone.clearValidators();

    } else{
      this.form.controls.user_firstname.enable();
      this.form.controls.user_lastname.enable();
      this.form.controls.user_nid_type.enable();
      this.form.controls.user_nid_number.enable();
      this.form.controls.user_email.enable();
      this.form.controls.user_phone.enable();

      this.form.controls.user_firstname.setValidators([Validators.required]);
      this.form.controls.user_lastname.setValidators([Validators.required]);
      this.form.controls.user_nid_type.setValidators([Validators.required]);
      this.form.controls.user_nid_number.setValidators([Validators.required]);
      this.form.controls.user_email.setValidators([Validators.required, Validators.email]);
      this.form.controls.user_phone.setValidators([Validators.required]);
    }
  }

  setDonationAmout(amount: number){
    this.form.get('donation_amount').setValue(amount);
  }

  isSelected(usd:number): boolean{
    const donationAmount = Number(this.form.get('donation_amount').value || 0);
    const donationCurrency = String(this.form.get('donation_currency').value || 0);
    switch (usd) {
      case 5:
        return donationCurrency === 'COP' && donationAmount === 10000 || (donationCurrency === 'USD' || donationCurrency === 'EUR') && donationAmount === 5;
      case 10:
        return donationCurrency === 'COP' && donationAmount === 15000 || (donationCurrency === 'USD' || donationCurrency === 'EUR') && donationAmount === 10;
      case 15:
        return donationCurrency === 'COP' && donationAmount === 20000 || (donationCurrency === 'USD' || donationCurrency === 'EUR') && donationAmount === 15;
      default:
        return false;
    }
  }

  public setPlace(event: any) {
    this.isColombia = (event.country === 'Colombia');
    this.form.get('donation_place').setValue({
      city: event.city,
      department: event.deparment,
      country: event.country,
    });
  }

  public onSubmit(){
    this.markAllControlsAsTouched(this.form);
    if (this.form.valid) {
      // Form is valid, proceed with submission
      this.doEpayCo();
      console.log('Form submitted successfully!');
    } else {
      // Form is invalid, do not proceed with submission
      console.log(this.form.value);
      console.log('Form contains errors. Please fix them before submitting.');
      Swal.fire('Error Validando Donacion', 'onSubmit', 'error');
    }
  }

  private markAllControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      // If the control is a nested form group, recursively mark its controls as touched
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

  public async doEpayCo(){
    const _campaign = {
      campaign_id: this.campaignId,
      campaign_title: this.campaign.title,
    };
    const _donation = {
      donation_amount:  this.form.get('donation_amount').value,
      donation_currency: this.form.get('donation_currency').value,
      donation_is_anonymous: this.isAnonymous
    };
    const _place = {
      billing_country: this.form.get('donation_place').value.country,
      billing_deparment: this.form.get('donation_place').value.department,
      billing_city: this.form.get('donation_place').value.city,
    }

    let _billing;
    if(this.isUserTab) {
      _billing = {
        billing_name: this.form.get('user_firstname').value + ' ' + this.form.get('user_lastname').value,
        billing_email: this.form.get('user_email').value,
        billing_phone: this.form.get('user_phone').value,
        billing_address: ' NA ',
        billing_nid_type: this.form.get('user_nid_type').value,
        billing_nid_number: this.form.get('user_nid_number').value,
      }
    } else {
      _billing = {
        billing_name: this.form.get('company_name').value,
        billing_email: this.form.get('company_email').value,
        billing_phone: this.form.get('company_phone').value,
        billing_address: this.form.get('company_address').value,
        billing_nid_type: this.form.get('company_nid_type').value,
        billing_nid_number: this.form.get('company_nid_number').value,
      }
    }

    const payload: IEpaycoPayload = {
      ..._campaign,
      ..._donation,
      ..._billing,
      ..._place
    }

    try{
      const donation = await this.epayco.doEpayCo(payload);
      return donation;
    }catch(err){
      Swal.fire('Error', err, 'error');
    }
  }

  closeModal(){
    this.isModalShared = false;
    this.isModalSuccess = false;
  }

  openModal(){
    this.isModalShared =true;
  }
}
