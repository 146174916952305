import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ui-recovery-password-sucessfull',
  templateUrl: './recovery-password-sucessfull.component.html',
  styleUrls: ['./recovery-password-sucessfull.component.less'],
})
export class RecoveryPasswordSucessfullComponent implements OnInit {
  public email = '';
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
    setTimeout(() => {
      this.router.navigate(['/auth/signin']);
    }, 10000);
  }
}
