import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { CountriesService } from 'src/app/shared/services/countries.service';

@Component({
  selector: 'app-campaigns-page',
  templateUrl: './campaigns-page.component.html',
  styleUrls: ['./campaigns-page.component.less'],
})
export class CampaignsPageComponent implements OnInit {

  public campaingsSeeMore = 9;
  public campaingsType = '';
  public keyword = '';

  public orderOptions = ['MAS DONADAS', 'MENOS DONADAS', 'RECIEN CREADAS', 'POR FINALIZAR'];

  public filter = {
    keyword: '',
    categories: [],
    sort: this.orderOptions[0],
    place: {
      country: '',
      state: '',
      city: ''
    }
  }


  public campaigns$: Observable<ICampaign[]>;

  constructor(
    private route: ActivatedRoute,
    private $countries: CountriesService,
    private $campaigns: CampaignsService, private router: Router) {}

  async ngOnInit() {

    this.campaigns$ = this.$campaigns.syncCampaigns();
    this.$campaigns.fetchAllCampaigns();

    this.campaigns$.subscribe(items => console.log(items))

    const campaignsType = this.route.snapshot.paramMap.get('type');
    this.setCampaignsPageByType(campaignsType);

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.setCampaignsPageByType(val.url.split('/campaigns/')[1]);
      }
    });

  }


  setCampaignsPageByType(campaignsType) {
    this.campaingsType = campaignsType;
  }


  addSeeMore() {
    this.campaingsSeeMore = this.campaingsSeeMore + 9;
  }

  setCategory(category: string) {
    /*
    if (this.filter.category === category) {
      this.filter.category = '';
    } else {
      this.filter.category = category;
    }
    */
  }


  onFilterChange($event: any){
    console.warn(' ')
    console.warn(' onFilterChange($event: any) ')
    console.warn($event)
    console.warn(' ')
    console.warn(' ')
    this.filter = Object.assign({},{
      ...this.filter,
      ...$event
    });
  }


  setOrderBy($event){
    this.filter = Object.assign({},{
      ...this.filter,
      sort: $event
    });
  }



}
