import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-filter-autocomplete',
  templateUrl: './filter-autocomplete.component.html',
  styleUrls: ['./filter-autocomplete.component.less'],
})
export class FilterAutocompleteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
