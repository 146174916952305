
<div class="input input-text" style="padding-bottom: 20px">
  <div class="input-wrapper" [ngClass]="{ 
    colorCian: colorCian, 
    error: isError(), 
    strong: isFocus, 
    normal: !isFocus && !error 
  }">
    <div class="input-border">
      <input class="input-field" [type]="type || 'text'" 
      (focus)="onFocus()" 
      (blur)="offFocus()" 
      (focusout)="offFocus()" 
      (keypress)="setText($event)"
      (keyup)="setText($event)"
      (change)="setText($event)" />
      <span class="input-label">{{ label }}</span>
    </div>

    <span class="input-error" *ngIf="errorText" [hidden]="!empty &&  errorText  && (error || errorValue || errorFn(text))">
      {{ errorText }}
    </span>

    <span class="input-error" *ngIf="!errorText" [hidden]="!empty && !(errorText && (error ||  errorValue || errorFn(text)))">
      {{ error }}
    </span>
  </div>
</div>
