<div class="input input-text">
  <div
    class="input-wrapper"
    [ngClass]="{
      error: error || errorFn(text),
      strong: isFocus,
      normal: !isFocus && !error
    }"
  >
    <div class="input-border">
      <input
        class="input-field"
        [placeholder]="placeholder"
        [type]="type || 'text'"
        (focus)="onFocus()"
        (blur)="offFocus()"
        (focusout)="offFocus()"
        (change)="setText($event)"
      />
      <span class="input-label">{{ label }}</span>
    </div>

    <span class="input-error">
      {{ error || 'error' }}
    </span>
    <span class="input-error" *ngIf="!error" style="visibility: hidden"> No Error </span>
  </div>
</div>
