import { NgModule, inject } from '@angular/core';
import { CanActivateFn, RouterModule } from '@angular/router';
import { AdminPagesComponent } from './admin-pages.component';
import { AdminCampaignsViewComponent } from './views/admin-campaigns-view/admin-campaigns-view.component';
import { AdminUsersViewComponent } from './views/admin-users-view/admin-users-view.component';
import { UserService } from 'src/app/core/users/services/user.service';

export function authenticationGuard(): CanActivateFn {
  return () => {
    const oauthService: UserService = inject(UserService);
    
    if (oauthService.isAdmin() ) {
      return true;
    }
    return false;
  };
}

export const routes = [
  {
    path: 'admin/:id',
    component: AdminPagesComponent,
    canActivate: [authenticationGuard()],
    children: [
      { path: 'campaigns', component: AdminCampaignsViewComponent },
      { path: 'users', component: AdminUsersViewComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminPagesRoutingModule {}
