<section class="page-section modal share-modal">
    <div class="modal-wrapper scroll"  id="style-1" (clickOutside)="onClickedOutside($event)">
      <div class="modal-header">
        <h1 *ngIf="!isSharePictureTab"><span (click)="closeModal()">x</span> GLOSARIO</h1>
        <h1 *ngIf="isSharePictureTab">
          <!-- <span class="toLeft" (click)="isSharePictureTab = false">Atrás</span> -->
          Compartir imagen <span class="toRight" (click)="closeModal()">x</span>
        </h1>
      </div>
  
      <div class="modal-content" *ngIf="!isSharePictureTab">
        <h1>PRIMERA FASE:</h1><br>
        <h1>- EN PROCESO: El usuario pueda agregar la información complementaría (Descripción detallada, ubicación geográfica y fotos).</h1><br>
        <h1>- PENDIENTE: La persona publica y el administrador debe revisarlo para cambiar a status: EN CORRECCIÓN o PUBLICADO.</h1><br>
        <h2>- EN CORRECCIÓN: Sí y solo sí hay un error o anomalía en la información agregada para publicar la campaña se pondrá en estado de corrección. De lo contrario, si todo está bien el administrador cambiará de status a publicado.</h2>
        <h3>- PUBLICADO: La campaña cuenta con todos los campos completos y la información es confiable. La interfaz de campaña es publicada en el Home (modo tarjeta) sobre los carruseles y tendrá la interfaz de detalle (interfaz independiente). Se habilita el STATUS #2.</h3><br>
        <h1>SEGUNDA FASE:</h1><br>
        <h3>- EN RECAUDO: Al ser habilitado por el status de PUBLICADO, automáticamente la campaña inicia su proceso de recaudo de dinero. Se verá el porcentaje de progreso como seguimiento.</h3><br>
        <h4>- PAUSADO: Si y solo si hay algún inconveniente, sospecha de fraude o solicitud vía correo electrónico o llamada telefónica se pausará para: 
            1. Postergar.
            2. Hacer una revisión y de ser necesario anular la campaña que implica la devolución de dineros a donantes.
            </h4><br>
        <h3>- EN EJECUCIÓN: Con el dinero recaudado la campaña empieza su desarrollo y el dinero se destina a la descripción indicada.</h3><br>
        <h1>- ANULADO: Después de pausado y revisado se ve la necesidad de devolver el dinero donado.</h1><br>
        <h3>- EXITOSO: Una campaña finalizada. (CAMPAÑA EXITOSA).</h3><br>
      
      </div>
  
  
      <div class="modal-footer">
      </div>
    </div>
  </section>
  