import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { SignupService } from '../../../../app/auth/services/signup.service';
import Swal from 'sweetalert2';

import { signUpForm } from 'src/app/auth/forms/signup.user.form';
import { UserService } from 'src/app/core/users/services/user.service';
import { AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Toast, ToastrService } from "ngx-toastr";

export const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('user_password1')?.value;
  const confirmPassword = control.get('user_password2')?.value;
  return password === confirmPassword ? null : { passwordMismatch: true };
};

@Component({
  selector: 'ui-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.less'],
})
export class SignUpPageComponent implements OnInit {

  public form: FormGroup;

  constructor(private fb: FormBuilder, public router: Router, public userService: UserService, private toast: ToastrService, public route: ActivatedRoute, public signupService: SignupService) {}

  public type = 'user'; // user || company
  public user: any = {};

  ngOnInit(): void {
    this.doSetUpForm();
  }

  doSetUpForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      first_name: [null, [Validators.required, Validators.minLength(6)]],
      last_name: [null, [Validators.required, Validators.minLength(6)]],
      phone: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      password1: [null, [Validators.required, Validators.minLength(6)]],
      password2: [null, [Validators.required, Validators.minLength(6)]],
    }, { validator: [passwordMatchValidator] } as AbstractControlOptions);
  }

  public markAllControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      // If the control is a nested form group, recursively mark its controls as touched
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

  goGoogleSignUp() {}
  goFacebookSignUp() {}

  async doConfirm() {
      this.doSignUpAsUser();
  }
  async doSignUpAsUser() {
    try {

      console.log(this.form.value)
      console.log(this.form.valid)
      console.log(this.form.errors)

      if (!this.form.valid) {
        throw 'Formulario Invalido';
      }

      if(this.form.value.password1 !== this.form.value.password2){
        this.form.get('password1').setErrors({ notSamePassword: true})
        this.form.get('password2').setErrors({ notSamePassword: true})
        throw 'Contraseñas deben ser iguales';
      }

      const user: any = {};
      user.firstname = this.form.value.first_name;
      user.lastname = this.form.value.last_name;
      user.name = user.firstname + ' ' + user.lastname;
      user.email =  this.form.value.email;
      user.password = this.form.value.password1;
      user.phone = this.form.value.phone;
      user.mobile = this.form.value.mobile;
      user.nid_number = '';
      user.nid_type = 'CC';
      user.company = user.name;
      user.position = '';
      user.city = '';
      user.address = '';
      user.rol = 'USER';
      user.description = '';

      const userResponse: any = await this.signupService.doSignUpWithEmailAndPassword(user);
      const userId = userResponse.id;
      sessionStorage.setItem('user', JSON.stringify(userResponse));
      this.userService.setUser(userResponse);

      setTimeout(() => {
        this.router.navigate(['/auth/signup/welcome']);
      }, 1000)

    } catch (error) {
     this.toast.error(error, 'Error Creando Usuario, Revisa Formulario')
    }
  }

  onSignUpSucess(user: any) {
    const userId = user.id;
    this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
  }
  onError() {}
  doNavigate() {}
}
