import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersModule } from './users/users.module';
import { PaymentsModule } from './payments/payments.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { CommentsModule } from './comments/comments.module';

const MODULES = [CampaignsModule, UsersModule, PaymentsModule, CommentsModule];
@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [],
  exports: [...MODULES],
})
export class CoreModule {}
