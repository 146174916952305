<div class="page recovery-password-emai-send-page">
  <!-- ------------------------------------------ -->
  <!-- --- AUTH::RECOVERY::FORM ------------------- -->
  <!-- ------------------------------------------ -->
  <form class="form-recovery">
    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-recovery--container">
      <img src="../../../../assets/images/Grupo 754.png" />
      <h1>Revisa tu correo y sigue las instrucciones</h1>
      <p>
        Te hemos enviado un correo a <strong>{{ email }}</strong> <br />
        Con las instrucciones para cambiar tu contraseña. <br />
        Si no logras encontrarlo, revisa tu bandeja de spam.
      </p>
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--footer">
      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->
  </form>
</div>
