import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsBannerComponent } from './components/campaigns-banner/campaigns-banner.component';
import { CategoryFilterComponent } from './components/filters/category-filter/category-filter.component';
import { CampaignsSortComponent } from './components/campaigns-sort/campaigns-sort.component';
import { CampaignsPageComponent } from './campaigns-page.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CampaignsModule } from 'src/app/core/campaigns/campaigns.module';
import { CampaignsFiltersComponent } from './components/campaigns-filters/campaigns-filters.component';

@NgModule({
  declarations: [CampaignsPageComponent, CampaignsBannerComponent, CategoryFilterComponent, CampaignsSortComponent, CampaignsFiltersComponent],
  exports: [CampaignsPageComponent, CampaignsBannerComponent, CategoryFilterComponent, CampaignsSortComponent],
  imports: [CommonModule,CoreModule, CampaignsModule, SharedModule],
})
export class CampaignsPageModule {}
