import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-title-h3',
  templateUrl: './title-h3.component.html',
  styleUrls: ['./title-h3.component.less'],
})
export class TitleH3Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
