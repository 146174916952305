import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.less'],
})
export class SearchInputComponent implements OnInit {
  @Input()
  placeholder: string;

  @Input()
  options: string[];

  constructor() {}

  ngOnInit(): void {}
}
