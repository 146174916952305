import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ui-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.less'],
})
export class ShareModalComponent implements OnInit {
  @Input()
  campaign: ICampaign;

  @Output()
  nClose = new EventEmitter<boolean>();

  public shareURL = '';
  public href = '';

  public isSharePictureTab = false;
  public photo = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.href = location.href;
    this.shareURL = location.href;

    this.http.post<any>(`${environment.server}/files/photos/base64`, { src: this.campaign.photos[0] }).toPromise().then( response => {
      console.log(' ')
      console.log(' response ')
      console.log(response)
      console.log(' ')
      this.photo = response.base64;
    }).catch( error => {
      console.error(error)
    })
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.responseType = 'blob';
    xhr.send();
  }

  shareFB() {
    const text = this.campaign.description.substring(0, 100) + '...';
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.href), 'facebook-share-dialog', 'width=626,height=436');
  }

  shareTW() {
    const text = this.campaign.description.substring(0, 100) + '...';
    const href = this.href;
    window.open(
      'http://twitter.com/share?text=' + encodeURI(text) + '&url=' + encodeURI(href) + '&hashtags=hashtag1,hashtag2,hashtag3',
      'facebook-share-dialog',
      'width=626,height=436'
    );
  }

  sharePicture() {
    this.isSharePictureTab = true;
  }

  closeModal() {
    this.nClose.emit(true);
  }

  onClickedOutside($event) {
    if ($event.target.className.includes('share-modal')) {
      this.closeModal();
    }
  }
  doShareCopyURL() {
    const str = this.shareURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  doShareCopyText() {
    const str = this.shareURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  doDownloadImage() {
    window.scrollTo(0, 0);
    html2canvas(document.querySelector('#capture'), {
      proxy: this.campaign.photos[0],
      scrollX: 0,
      scrollY: 0,
       scale: 1,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      //document.body.appendChild(canvas);
      const a = document.createElement('a');
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
      a.download = 'soliun_share.jpg';
      a.click();
    });
  }


  get id(){
    return String(this.campaign.id).padStart(5,'0')
  }

  get getPhoto(){
    return "url('" + this.photo.replace(/(\r\n|\n|\r)/gm, "")
  }
}
