<!-- ----------------------------------- -->
<!-- --- home-page outstanding-blogs --- -->
<!-- ----------------------------------- -->
<div class="home-page outstanding-blogs" *ngif="false">
  <!-- ------------------------------------ -->
  <!-- --- Swiper ------------------------- -->
  <!-- ------------------------------------ -->
  <swiper [config]="config" [(index)]="index">
    <div class="outstanding-blogs--slide" *ngFor="let campaign of campaigns | slice: 0:10">
      <div class="slide--wrapper">
        <div class="slide--image" [ngClass]="{ active: hasPhoto(campaign.photos[0]) }">
          <img class="active-photo" [src]="campaign.photos[0]" (load)="onPhotoLoad()" />
          <img class="active-no-photo" src="../../../../../assets/icons/placeholder_photo.png" />
        </div>

        <div class="slide--content">
          <h1>{{ campaign.title | truncateText: 15 }}</h1>
          <p>{{ campaign.description | truncateText: 80 }}</p>
        </div>
      </div>
    </div>
    <div class="swiper-scrollbar"></div>
  </swiper>
  <!-- ----------------------------------- -->
</div>
<!-- ----------------------------------- -->
