<div class="page legal-page">
  <br />
  <br />
  <br />

  <ui-home-about></ui-home-about>

  <br />
  <br />
  <h1>Requisitos de permanencia Regimen Tributario Especial - ESAL</h1>
  <br />
  <br />

  <div class="section-bg">
    <section class="section-green">
      <br />
      <br />
      <br />
      <ul>
        <li *ngFor="let doc of docs; let i = index">
          <p>{{ i + 1 }}. {{ doc.text }}</p>
          <a class="doc-button" [href]="doc.link" target="_blank" *ngIf="doc.link"> DESCARGAR DOCUMENTO </a>
          <a class="doc-button disabled" *ngIf="!doc.link"> DESCARGAR DOCUMENTO </a>
        </li>
      </ul>
    </section>
  </div>
</div>
