<div>
  <!-- Default form contact -->
  <form class="text-center border border-light p-5">
    <p class="title h4 mb-4">Configuración "Home"</p>

    <!-- Subject -->
    <label class="subtitle">Tipo de "Destacadas"</label>
    <br />
    <br />
    <select class="browser-default custom-select mb-4" (change)="setOutstandingType($event)">
      <option [value]="OustandingType" disabled>{{ OustandingType }}</option>
      <option value="AUTO">AUTO</option>
      <option value="MIX">MIX</option>
      <option value="MANUAL">MANUAL</option>
    </select>

    <!-- Message -->
    <div class="form-group" *ngIf="OustandingType === 'MANUAL'">
      <br />

      <input type="number" placeholder="CampaignA Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignB Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignC Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignD Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignE Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignF Id" class="form-control" />
    </div>

    <div class="form-group" *ngIf="OustandingType === 'MIX'">
      <br />

      <input type="number" placeholder="CampaignA Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignB Id" class="form-control" />
      <br />
      <input type="number" placeholder="CampaignC Id" class="form-control" />
    </div>

    <!-- Send button -->
    <div class="to-left">
      <button type="button" class="btn btn-success" (click)="save()">Guardar</button>
    </div>
  </form>
  <!-- Default form contact -->
</div>
