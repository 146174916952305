<div class="place-inputs column"  >
    <ui-funs-mobile-dropdown style="width: 100%;"
          (nChange)="setField('country', $event)"
          [default]="getFieldDefault('country')"
          [options]="getFieldOptions('country')"
          [border]="getFieldBorder('country')"
          [label]="getFieldLabel('country') + ' *'"
          [option]="'name'"
          [loading]="isCountryLoading"
    ></ui-funs-mobile-dropdown>
    <ui-funs-mobile-dropdown style="width: 100%;"
        (nChange)="setField('department', $event)"
        [default]="getFieldDefault('department')"
        [options]="getFieldOptions('department')"
        [border]="getFieldBorder('department')"
        [label]="getFieldLabel('department') + ' *'"
        [option]="'name'"
        [loading]="isDepartmentLoading"
    ></ui-funs-mobile-dropdown>
    <ui-funs-mobile-dropdown style="width: 100%;"
        (nChange)="setField('city', $event)"
        [default]="getFieldDefault('city')"
        [options]="getFieldOptions('city')"
        [border]="getFieldBorder('city')"
        [label]="getFieldLabel('city') + ' *'"
        [option]="'name'"
        [loading]="isCityLoading"
    ></ui-funs-mobile-dropdown>
</div>