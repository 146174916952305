import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'ui-filter-amount',
  templateUrl: './filter-amount.component.html',
  styleUrls: ['./filter-amount.component.less'],
})
export class FilterAmountComponent implements OnInit {
  @Output()
  nChange = new EventEmitter();

  high = 0;
  low = 0;

  rangehigh = 0;
  rangelow = 0;

  constructor() {}

  ngOnInit() {
    this.low = 0;
    this.high = 1000000000;
    this.nChange.emit(this.range);
  }

  setHigh($event) {
    this.nChange.emit(this.range);
  }
  setLow($event) {
    this.nChange.emit(this.range);
  }

  public get range(): { high: number; low: number } {
    if (this.high > this.low) {
      this.rangehigh = this.high;
      this.rangelow = this.low;
    }
    return {
      high: this.rangehigh,
      low: this.rangelow,
    };
  }
}
