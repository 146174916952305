import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';
import { Observable, Subscription } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';

@Component({
  selector: 'ui-profile2-pages',
  templateUrl: './profile2-pages.component.html',
  styleUrls: ['./profile2-pages.component.less']
})
export class Profile2PagesComponent implements OnInit, OnDestroy {

  public user: IUser = null;

  public subs: Subscription = new Subscription();

  public user$: Observable<IUser>;
  public isAdmin$: Observable <boolean>;
  public campaings$: Observable<ICampaign[]>;
  public campaingsCover$: Observable<ICampaign[]>;

  public campaignsDonatedNumber = 0;
  public campaignsReceivedNumber = 0;

  public filter = {
    date: {
      create: {
        start: new Date(),
        end: new Date(),
      },
      close: {
        start: new Date(),
        end: new Date(),
      },
    },
    amount: 0,
    city: '',
    department: '',
    type: '',
    random: 0,
    category: '',
  };
  
  constructor(private router: Router, private $campaigns: CampaignsService, private $user: UserService, private route: ActivatedRoute){
    this.isAdmin$ = this.$user.isAdmin$;
    this.user$ = this.$user.syncUser();
    this.campaings$ = this.$campaigns.syncProfileCampaigns();
    this.campaingsCover$ = this.$campaigns.syncCoverCampaigns();
  }

  async ngOnInit() {
  
    // Load User Data
    const userId = Number(this.route.snapshot.paramMap.get('id'));
    const user = await this.$user.init(userId);
    this.user = user;

    // Load Campaigns Related to this profile
    const coverCampaigns = await this.$campaigns.fetchCoverCampaigns();
    const myProfileCampaigns = await this.$campaigns.fetchMyProfileCampaigns(userId);
  
    //Subs User
    const subsUser = this.user$.subscribe(user => this.user = user);
    this.subs.add(subsUser);

    //Subs Campaigns
    const subsCampaigns = this.campaings$.subscribe(campaigns => {
      if (campaigns) {
        this.campaignsDonatedNumber = campaigns.filter((campaign) => campaign.isDonated).length;
        this.campaignsReceivedNumber = campaigns.filter((campaign) => campaign.isOrganized && campaign.stats && campaign.stats.total).map((campaign) => campaign.stats.total).length; 
      }
    });
    this.subs.add(subsCampaigns);

    console.error('user');
    console.error(user);

  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  goCampaigns(){
    this.router.navigate(['/campaigns/foundations']);
  }
  goStartCampaign(){
    const type = (this.user.rol === 'USER' || this.user.rol === 'ADMIN') ? 'cause': 'foundation';
    this.router.navigate(['/create/campaign/type/'+type]);
  }
  setFilter(data){}
}
