export default [
  'OLDER ADULTS',
  'ANIMALS',
  'ANCIENT',
  'EDUCATION',
  'YOUNG PEOPLE',
  'FAMILIES',
  'ENVIRONMENTAL',
  'LESS DONATED',
  'MORE POPULAR',
  'KIDS',
  'RECENT',
];
