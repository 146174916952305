import { Component, Input } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { CardCampaignThemes } from 'src/app/shared/components/cards/card-campaign/card-campaign.component';

@Component({
  selector: 'ui-campaigns-tab-in-execution',
  templateUrl: './campaigns-tab-in-execution.component.html',
  styleUrls: ['./campaigns-tab-in-execution.component.less']
})
export class CampaignsTabInExecutionComponent {

  @Input() campaigns: ICampaign[];
  theme = CardCampaignThemes.EXECUTION;

  constructor(private $campaigns: CampaignsService){}

  public deleteCampaing(campaing: ICampaign) {
    this.$campaigns.removeById(campaing.id);
  }
}
