import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminPagesComponent } from './admin-pages.component';
import { AdminCampaignsViewComponent } from './views/admin-campaigns-view/admin-campaigns-view.component';
import { AdminUsersViewComponent } from './views/admin-users-view/admin-users-view.component';
import { AdminPagesRoutingModule } from './admin-pages.routing';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { Profile2PagesModule } from '../profile2-pages/profile2-pages.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TableModule } from 'ngx-easy-table';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { AdminCampaignDetailsRowComponent } from './components/admin-campaign-details-row/admin-campaign-details-row.component';
@NgModule({
  declarations: [
    AdminPagesComponent,
    AdminCampaignsViewComponent,
    AdminUsersViewComponent,
    AdminCampaignDetailsRowComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    Profile2PagesModule,
    CoreModule,
    TableModule,
    SidebarModule,
    TabViewModule,
    InputTextareaModule,
    ScrollPanelModule,
    AdminPagesRoutingModule,
    InputSwitchModule,
    InputTextModule,
    ButtonModule
  ]
})
export class AdminPagesModule { }
