import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export enum DEVICES {
  MOBILE = 'DEVICE_MOBILE',
  TABLET = 'DEVICE_TABLET',
  LAPTOP = 'DEVICE_LAPTOP',
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {

  private _device = new BehaviorSubject<DEVICES>(DEVICES.LAPTOP);
  public device$ = this._device.asObservable();

  constructor() {}


  public setDeviceType() {
    let device = DEVICES.LAPTOP;

    const width = window.innerWidth;
    if (width <= 768) {
      device = DEVICES.MOBILE;
    } else if (width > 768 && width <= 1024) {
      device = DEVICES.TABLET;
    } else {
      device = DEVICES.LAPTOP;
    }

    this._device.next(device);
  }

}
