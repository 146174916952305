<div class="page profile-page">

    <section class="profile-page--container">
      <div class="profile-page--header">
        <div class="header--col">
          <img class="profile--avatar" *ngIf="user" [src]="user?.photo_url" />
          <div class="profile--titles-info">
            <div *ngIf="user?.company" class="user-type-company">
              <h1>{{ user?.company }}</h1>
              <h2>Responsable: {{ user?.name }}</h2>
              <h3>{{ user?.email }}</h3>
            </div>

            <div *ngIf="!user?.company" class="user-type-regular">
              <h1>{{ user?.name }}</h1>
              <h2>{{ user?.email }}</h2>
              <h3 *ngIf="user?.position">{{ user?.position }}</h3>
            </div>
          </div>
          <div class="profile--titles-stats">
            <br />
            <h4>
              <strong>{{ campaignsDonatedNumber }}</strong> donativos
            </h4>
            <h4>
              <strong>{{ campaignsReceivedNumber }}</strong> recaudados
            </h4>
          </div>
        </div>
        <div class="header--col">
          <div class="header--row">
            <ul class="header-nav-menu">
              <li [routerLink]="''">Cuéntanos tu experiencia</li>
              <li [routerLink]="''">Cómo funciona</li>
              <li [routerLink]="''">Configuración</li>
              <li *ngIf="user?.rol === 'ADMIN'"
                  [routerLinkActive]="['is-active']"
                  [routerLink]="'/profile2/'+user?.id">
                  Usuario
              </li>
              <li *ngIf="user?.rol === 'ADMIN'"
                  [routerLinkActive]="['is-active']"
                  [routerLink]="'/admin/'+user?.id+'/campaigns'">
                  Admin
              </li>
            </ul>
          </div>
          <div class="header--row">
            <ui-solid-btn
              [color]="'red'"
              [minWidth]="'200px'"
              [margins]="'0em 1em 0em 0em'"
              [paddings]="'0.5em 1em'"
              [text]="'campaigns.donate'"
              (nClick)="goCampaigns()"
            ></ui-solid-btn>
            <ui-solid-btn
              [color]="'green'"
              [minWidth]="'200px'"
              [margins]="'0em'"
              [paddings]="'0.5em 1em'"
              [text]="'INICIAR CAMPAÑA'"
              (nClick)="goStartCampaign()"
            ></ui-solid-btn>
          </div>
        </div>
      </div>

      <div class="profile-page--div"></div>

      <div class="profile-page--sidebar-and-main">
        <!--
        <ui-profile-filters2 [mode]="user?.rol" (nChange)="setFilter($event)"></ui-profile-filters2>
        -->
        <div class="profile-page--main">
          <div class="main--tabs-header">
            <h1 [routerLinkActive]="['active']" [routerLink]="'/profile2/'+user?.id+'/campaigns/liked'">CAMPAÑAS QUE SIGO</h1>
            <h1 [routerLinkActive]="['active']" [routerLink]="'/profile2/'+user?.id+'/campaigns/donated'">MIS DONACIÓNES</h1>
            <h1 [routerLinkActive]="['active']" [routerLink]="'/profile2/'+user?.id+'/campaigns/organized'">MIS CAMPAÑAS</h1>
          </div>
          <div class="main--tabs-content">
            <div class="tab">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>


      </div>
    </section>

    <section class="profile-page--outstandings-campaigns">
      <div class="section--wrapper">
        <h1 class="section-title">CAMPAÑAS DESTACADAS</h1>

          <ui-home-outstandings-campaigns [campaigns]="campaingsCover$ | async">
          </ui-home-outstandings-campaigns>

        <br />
      </div>
    </section>

    <ui-profile-edit-sidebar

    ></ui-profile-edit-sidebar>
  </div>


