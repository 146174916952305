import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-button-gmail-login',
  templateUrl: './button-gmail-login.component.html',
  styleUrls: ['./button-gmail-login.component.less'],
})
export class ButtonGmailLoginComponent implements OnInit {
  constructor() {}

  @Input() btnStyle: 'btn-none' | 'btn-border' = 'btn-none';
  @Input() btnLabel = 'auth.FB_LOGIN';

  ngOnInit(): void {}
}
