

<div class="page signup-page signup-page-user" >
  <form class="form-signup" [formGroup]="form">

    <div class="form-sigin--header">
      <ui-title-auth-h1 [button]="'x'" [title]="'Regístrate'"> </ui-title-auth-h1>
    </div>

    <div class="form__funs form-signup--fields">
      <div class="field ui-ng-form-input-text"> <ui-ng-form-input-text [control]="form.controls?.first_name"  label="Nombres" type="text"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.last_name"  label="Apellidos" type="text"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.email"  label="Email" type="email"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.phone"  label="Telefono" type="phone"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.mobile"  label="Celular" type="phone"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.password1"  label="Contraseña" type="password"></ui-ng-form-input-text> </div>
      <div class="field ui-ng-form-input-text">  <ui-ng-form-input-text [control]="form.controls?.password2"  label="Repite Contraseña" type="password"></ui-ng-form-input-text> </div>
    </div>

    <div class="form-signup--container" style="padding-top: 0em">
      <div class="row signup-btn">
        <div class="col-button">
          <h4 style="color: #9d9d9c; font-size: 20px; font-family: 'Poppins-Regular'">
            ¿Ya tienes una cuenta?
            <a [routerLink]="'/auth/signin'" style="color: #58b999">Iniciar sesión</a>
          </h4>
          <span style="width: 475px; font-size: 15px; color: #9d9d9c"
            >Al continuar aceptas todos nuestros <a style="color: #58b999" href="">términos y condiciones</a></span
          >
        </div>

        <div class="col-routes foundation"><button class="green" (click)="doConfirm()">REGISTRARME</button> <br /></div>
      </div>
    </div>

    <br>
    <br>

    <div class="form-signup--footer">
      <div class="row more-info">
        <h5>
          Para saber más sobre nuestras políticas y funcionamiento puedes hacer
          <a style="color: #58b999" href="">clic aqui</a>
        </h5>
      </div>
    </div>
  </form>
</div>
