import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupService } from 'src/app/auth/services/signup.service';

@Component({
  selector: 'ui-form-signup-company',
  templateUrl: './form-signup-company.component.html',
  styleUrls: ['./form-signup-company.component.less'],
})
export class FormSignupCompanyComponent implements OnInit {
  @Output() nSuccess = new EventEmitter<any>();
  @Output() nError = new EventEmitter<any>();
  @Output() nNavigate = new EventEmitter<any>();

  constructor(private signupService: SignupService) {}

  public nameError = '';
  public emailError = '';
  public phoneError = '';
  public passwordError = '';
  public authError: any = '';

  public type = 'company'; // user || company
  public user: any = {};
  public confirm = false;

  ngOnInit(): void {}

  setFirstName($event) {
    this.user.firstname = $event.target.value;
    if (!this.user.firstname) {
      this.nameError = 'Nombre Invalido';
    }
  }
  setLastName($event) {
    this.user.lastname = $event.target.value;
    if (!this.user.lastname) {
      this.nameError = 'Apellido Invalido';
    }
  }
  setEmail($event) {
    this.user.email = $event.target.value;
    if (!this.user.email) {
      this.emailError = 'Correo Invalido';
    }
  }
  setMobile($event) {
    this.user.mobile = $event.target.value;
    if (!this.user.mobile && this.user.mobile.length < 10) {
      this.phoneError = 'Celular Invalido';
    }
  }
  setPassword($event) {
    this.user.password = $event.target.value;
    if (!this.user.password) {
      this.passwordError = 'Contraseña Invalida';
    }
  }

  async doConfirm() {
    this.doSignUpAsUser();
  }

  async doSignUpAsUser() {
    if (!this.user.firstname) {
      this.nameError = 'Nombre Invalido';
    }
    if (!this.user.lastname) {
      this.nameError = 'Nombre Invalido';
    }
    if (!this.user.email) {
      this.emailError = 'Email Invalido';
    }
    if (!this.user.mobile) {
      this.phoneError = 'Celular Invalido';
    }
    if (!this.user.password) {
      this.passwordError = 'Contraseña Invalida';
    }

    try {
      const user: any = {};
      user.name = this.user.firstname + ' ' + this.user.lastname;
      user.firstname = this.user.firstname;
      user.lastname = this.user.lastname;
      user.email = this.user.email;
      user.password = this.user.password;
      user.phone = this.user.mobile;
      user.mobile = this.user.mobile;
      const userResponse: any = await this.signupService.doSignUpWithEmailAndPassword(user);
      const userId = userResponse.id;
    } catch (error) {
      console.error(error);
    }
  }

  onClickedOutside($event) {
    this.confirm = false;
  }
}
