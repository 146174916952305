import { Component, ContentChildren, QueryList, AfterContentInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { CampaignTabComponent } from '../campaign-tab/campaign-tab.component';

@Component({
  selector: 'ui-campaign-tabs',
  templateUrl: './campaign-tabs.component.html',
  styleUrls: ['./campaign-tabs.component.less'],
})
export class CampaignTabsComponent implements AfterContentInit {
  constructor() {}

  @ContentChildren(CampaignTabComponent) tabs: QueryList<CampaignTabComponent>;

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: CampaignTabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((_tab) => (_tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
