import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-campaign-photos',
  templateUrl: './campaign-photos.component.html',
  styleUrls: ['./campaign-photos.component.less'],
})
export class CampaignPhotosComponent implements OnInit {
  @Input()
  photos: string[];

  constructor() {}

  ngOnInit(): void {}
}
