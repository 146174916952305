import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfilePageService {
  constructor() {}

  private _filter = new BehaviorSubject<any>({});
  public filter$ = this._filter.asObservable();

  public setFilter(filter) {
    this._filter.next(filter);
  }

  public getFilter() {
    return this._filter.getValue();
  }

  public update(){
    this._filter.next(
      JSON.parse(''+JSON.stringify(this._filter.getValue()))
    )
  }
}
