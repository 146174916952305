import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-default-button',
  templateUrl: './default-button.component.html',
  styleUrls: ['./default-button.component.less'],
})
export class DefaultButtonComponent implements OnInit {
  @Output()
  nClick = new EventEmitter();

  @Input()
  label = 'N/A';

  @Input()
  color = 'green';

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.nClick.emit(true);
  }
}
