import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-text-label',
  templateUrl: './text-label.component.html',
  styleUrls: ['./text-label.component.less'],
})
export class TextLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
