<div class="funs funs__footer funs__footer--mobile">
    <div class="background__color--green w-100p">
        <div class="footer__head">
            <h1>Síguenos en nuestras redes:</h1>
        </div>
        <div class="footer__social-icons">
            <ul class="social-icons">
                <li class="social-icon" *ngFor="let social of SOCIALS">
                    <a class="social-icons__link" [href]="social.href">
                        <img [src]="social.src">
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer__numbers">
            <h1> 311 521 6216 / (601) 765 3016. </h1>
        </div>
        <div class="footer__emails">
            <h1> contacto@funsoliun.com / www.funsoliun.com </h1>
        </div>
    </div>
    <div class="background__color--white w-100p">
        <h1 class="footer__copy"> © 2020 FUNS TODOS LOS DERECHOS RESERVADOS </h1>
    </div>
</div>