import { Component, Input, OnInit } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/users/services/user.service';
import { LikesService } from 'src/app/core/campaigns/services/likes.service';
import { IStatusEnum, IStatusValue } from 'src/app/core/campaigns/models/status.model';

export enum CardCampaignThemes {
  COLLECTING = 'collecting',
  EXECUTION = 'executing',
  FINISHED = 'finished',
}

@Component({
  selector: 'ui-card-campaign',
  templateUrl: './card-campaign.component.html',
  styleUrls: ['./card-campaign.component.less'],
})
export class CardCampaignComponent implements OnInit {
  @Input()
  campaign: ICampaign;

  @Input()
  theme?: IStatusValue;

  public isHover = false;
  public hasLike = false;
  public tooltipEnabled = false;
  public isPictureLoaded = false;

  public IStatusEnum = IStatusEnum;

  constructor(private router: Router, private $user: UserService, private $likes: LikesService) {}

  ngOnInit(): void {

    this.tooltipEnabled = false;
    this.hasLike = false; // TODO FROM DATABASE
    this.isHover = false; // TODO FROM DATABASE

    this.$user.syncUser().subscribe((user) => {
      try {
        if (user) {
          if (this.campaign.id === 3) {
            if (typeof this.campaign.followers === 'string') {
              console.warn(' ');
              console.warn(' (JSON.parse(this.campaign.followers) || []) ');
              console.warn(this.campaign || []);
              console.warn(' ');
            }
          }

          if (typeof this.campaign.followers === 'string') {
            this.hasLike = (JSON.parse(this.campaign.followers) || []).filter((follower) => follower.user_id === user.id).length > 0;
          } else {
            this.hasLike = (this.campaign.followers || []).filter((follower) => follower.user_id === user.id).length > 0;
          }
        }
      } catch (err) {
        if (typeof this.campaign.followers === 'string') {
          console.warn(' ');
          console.warn(' user ');
          console.warn(' ');
          console.warn(' followers');
          console.warn(JSON.parse(this.campaign.followers));
          console.warn(' ');
          console.warn(' ');
        }
      }
    });
  }

  get description() {
    try{
      const descriptionObj = JSON.parse(this.campaign.description);
      if(descriptionObj.blocks[0]){
        return descriptionObj.blocks[0].data.text;
      }
      return 'N/A';
      
    } catch(e){
      if (typeof this.campaign.description === 'string'){
        return this.campaign.description;
      }
      return 'ERROR';
    }
  
  }

  onPhotoLoad() {
    this.isPictureLoaded = true;
  }

  async setLike() {
    const user = this.$user.getUser();

    if (user) {
      this.hasLike = !this.hasLike;
      const campaign = this.campaign;
      if (this.hasLike) {
        this.$likes.setLike(campaign, user);
      }
      if (!this.hasLike) {
        this.$likes.removeLike(campaign, user);
      }
    } else {

      this.tooltipEnabled = true;
      setTimeout(() => (this.tooltipEnabled = false), 10000);

    }
  }

  goToSignIn() {
    this.router.navigateByUrl(`/auth/signin`);
  }

  setHover() {
    this.isHover = !this.isHover;
  }

  hasPhoto(src: string) {
    return src && !src.includes('null') && this.isPictureLoaded;
  }

  public get categoryLabel(): string {
    if (this.campaign.category) {
      return 'campaings.categories.' + this.campaign.category.replace(/ /g, '_');
    } else {
      return '';
    }
  }

  public get timeLeft(): { value: number; label: string } | null {
    if (this.campaign.date_end) {
      const days = moment.utc(this.campaign.date_end).diff(moment.utc(), 'days');
      if (days < 1) {
        const hours = moment.utc(this.campaign.date_end).diff(moment.utc(), 'hours');
        return {
          value: hours,
          label: 'hours',
        };
      } else if (days < 60) {
        return {
          value: days,
          label: 'days',
        };
      } else if (days < 365) {
        const months = moment.utc(this.campaign.date_end).diff(moment.utc(), 'months');
        return {
          value: months,
          label: 'months',
        };
      } else {
        const years = moment.utc(this.campaign.date_end).diff(moment.utc(), 'years');
        return {
          value: years,
          label: 'years',
        };
      }
    } else {
      return null;
    }
  }

  goToCampaing($event) {
    /*
    if (
      $event.path
        .map((elem) => elem.classList)
        .filter((elem) => elem)
        .map((classList) => classList.value.includes('tooltip'))
        .filter((elem) => elem).length === 0
    ) {
      this.tooltipEnabled = false;
      setTimeout(() => this.router.navigateByUrl(`/campaing/${this.campaign.id}`), 150);
    }*/

    this.router.navigateByUrl(`/campaing/${this.campaign.id}`)
  }
}
