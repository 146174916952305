<div class="page campaigns-page">

  <!-- ----------------------------------- -->
  <!-- ---- CAMPAIGNS::BANNER ------------ -->
  <!-- ----------------------------------- -->
    <ui-campaigns-banner
      [type]="campaingsType"
      (onCategoryChange)="setCategory($event)"
    ></ui-campaigns-banner>
  <!-- ----------------------------------- -->

  <div class="campagins-page--content">
    <div class="wrapper">

      <div class="sidebar">
        <!-- ----------------------------------- -->
        <!-- ---- CAMPAIGNS::FILTERS ----------- -->
        <!-- ----------------------------------- -->
          <ui-campaigns-filters
            [filter]="filter"
            (onChange)="onFilterChange($event)"
          ></ui-campaigns-filters>
        <!-- ----------------------------------- -->
      </div>

      <div class="campaigns">
        <div class="campaigns-search">
          <div class="campaigns-search-wrapper">
            <img src="../../../assets/icons/search-icon.png" />
            <input [(ngModel)]="keyword" type="text" placeholder="Buscar más categorías o campañas" />
          </div>
          <div class="campaigns-sortby-wrapper">
            Buscar Por :
            <ui-dropdown-input
              class=""
              [default]="orderOptions[0]"
              [options]="orderOptions"
              (nChange)="setOrderBy($event)"
              style="width: fit-content"
            ></ui-dropdown-input>
          </div>
        </div>
        <div class="campaigns-list">
          <div class="campaing-card"
          *ngFor="let campaign of ((campaigns$ | async) |
                  filterByIsActive |
                  filterByCategoryPipe: filter.categories |
                  filterBySearchKeyword: keyword  |
                  sortByPipe: filter.sort
          )
          ">
            <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
          </div>

          <div class="campaigns-see-more">
            <div class="campaigns-see-more--line"></div>
            <div class="campaigns-see-more--btn">
              <button (click)="addSeeMore()">VER MAS</button>
            </div>
            <div class="campaigns-see-more--line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
