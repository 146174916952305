import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from 'src/app/auth/services/signin.service';
import { UserService } from 'src/app/core/users/services/user.service';

@Component({
  selector: 'ui-recovery-password-change-page',
  templateUrl: './recovery-password-change-page.component.html',
  styleUrls: ['./recovery-password-change-page.component.less'],
})
export class RecoveryPasswordChangePageComponent implements OnInit {
  public user: any = {};
  public authError = '';

  public email = '';
  public token = '';

  public password1 = '';
  public password2 = '';

  constructor(private route: ActivatedRoute, private router: Router, private $user: UserService, private signinService: SigninService) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
    this.token = this.route.snapshot.paramMap.get('token');
  }
  setEmail($event) {
    this.user.email = $event.target.value;
  }
  setPassword1($event) {
    this.password1 = $event.target.value;
  }
  setPassword2($event) {
    this.password2 = $event.target.value;
  }
  async doRecoveryPassword() {
    console.warn(' doChangeEmail ');
    try {
      const email = this.email;
      const token = this.token;
      const password1 = this.password1;
      const password2 = this.password2;
      const password = this.password1 === this.password2 ? this.password1 : '';
      const userResponse: any = await this.signinService.doChangePassword(email, token, password);
      const userEmail = userResponse.email;
      setTimeout(() => {
        this.router.navigate(['/auth/recovery/password/successfull']);
      }, 500);
    } catch (error) {
      this.authError = error;
    }
  }
}
