import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-button-facebook-login',
  templateUrl: './button-facebook-login.component.html',
  styleUrls: ['./button-facebook-login.component.less'],
})
export class ButtonFacebookLoginComponent implements OnInit {
  constructor() {}

  @Input() btnStyle: 'btn-none' | 'btn-border' = 'btn-none';

  @Input() btnLabel = 'auth.FB_LOGIN';

  ngOnInit(): void {}
}
