<button class="button payu-btn" (click)="doSelectPayU()" [ngClass]="{ selected: selected }">
  <img src="../../../../../assets/icons/payu-btn.png" />
  <form #payuForm method="post" [action]="formAction" style="display: none">
    <input name="merchantId" type="hidden" [value]="formMerchantId" />
    <input name="accountId" type="hidden" [value]="formAccountId" />
    <input name="description" type="hidden" [value]="formDescription" />
    <input name="referenceCode" type="hidden" [value]="formReferenceCode" />
    <input name="amount" type="hidden" [value]="formAmount" />
    <input name="tax" type="hidden" [value]="formTax" />
    <input name="taxReturnBase" type="hidden" [value]="formTaxReturnBase" />
    <input name="currency" type="hidden" [value]="formCurrency" />
    <input name="signature" type="hidden" [value]="formSignature" />
    <input name="test" type="hidden" [value]="formTest" />
    <input name="buyerEmail" type="hidden" [value]="formBuyerEmail" />
    <input name="responseUrl" type="hidden" [value]="formResponseUrl" />
    <input name="confirmationUrl" type="hidden" [value]="formConfirmationUrl" />

    <input name="Submit" type="submit" value="Enviar" />
  </form>
</button>

<ng-progress #progressBar [color]="'#58b999'" [thick]="false"></ng-progress>
