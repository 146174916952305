import { Component, Input, OnInit } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';

@Component({
  selector: 'ui-home-purposes',
  templateUrl: './home-purposes.component.html',
  styleUrls: ['./home-purposes.component.less'],
})
export class HomePurposesComponent implements OnInit {
  @Input()
  campaigns: ICampaign[];

  constructor() {}

  ngOnInit(): void {}
}
