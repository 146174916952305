import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
  SwiperAutoplayInterface,
} from 'ngx-swiper-wrapper';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 3,
  spaceBetween: 43,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  },
};

@Component({
  selector: 'ui-home-outstandings-blogs',
  templateUrl: './home-outstandings-blogs.component.html',
  styleUrls: ['./home-outstandings-blogs.component.less'],
})
export class HomeOutstandingsBlogsComponent implements OnInit {
  @Input()
  campaigns: ICampaign[];

  public config = DEFAULT_SWIPER_CONFIG;
  public index = 0;

  public isPictureLoaded = false;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  ngOnInit(): void {}

  onPhotoLoad() {
    this.isPictureLoaded = true;
  }

  hasPhoto(src: string) {
    return src && !src.includes('null') && this.isPictureLoaded;
  }
}
