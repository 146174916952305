import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthPagesRoutingModule } from './auth-pages.routing';

import { SignInPageComponent } from './signin-page/signin-page.component';
import { SignUpPageComponent } from './signup-page/signup-page.component';
import { AuthPagesComponent } from './auth-pages.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { RecoveryPasswordPageComponent } from './recovery-password-page/recovery-password-page.component';
import { RecoveryPasswordEmailSendPageComponent } from './recovery-password-email-send-page/recovery-password-email-send-page.component';
import { RecoveryPasswordChangePageComponent } from './recovery-password-change-page/recovery-password-change-page.component';
import { SignupFoundationComponent } from './signup-foundation/signup-foundation.component';
import { UsersModule } from 'src/app/core/users/users.module';
import { RecoveryPasswordSucessfullComponent } from './recovery-password-sucessfull/recovery-password-sucessfull.component';
import { SignupWelcomePageComponent } from './signup-welcome-page/signup-welcome-page.component';

@NgModule({
  declarations: [
    SignInPageComponent,
    SignUpPageComponent,
    RecoveryPasswordPageComponent,
    AuthPagesComponent,
    RecoveryPasswordEmailSendPageComponent,
    RecoveryPasswordChangePageComponent,
    SignupFoundationComponent,
    RecoveryPasswordSucessfullComponent,
    SignupWelcomePageComponent,
  ],
  exports: [
    SignInPageComponent,
    SignUpPageComponent,
    RecoveryPasswordPageComponent,
    AuthPagesComponent,
    RecoveryPasswordEmailSendPageComponent,
    RecoveryPasswordChangePageComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, UsersModule, AuthPagesRoutingModule],
})
export class AuthPagesModule {}
