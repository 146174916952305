<div class="profile-page--sidebar sidebar">
  <br>
  <br>
  <h2>Filtrar Resultados</h2>

  <ul *ngIf="mode === 'ADMIN'">
    <li class="filter-item filter-by-city">
      <ui-dropdown-input-c
      [default]="city?.name"
      [options]="citiesNames"
      (nChange)="setCity($event)"
      style="width: 100%"
      [border]="true"
      [label]="'Ciudad'"
      >
     </ui-dropdown-input-c>
    </li>
    <li>
      <div class="round">
        <input type="checkbox" checked id="checkbox" />
        <label for="checkbox"></label>
      </div>
      <h3>Causas</h3>
      <div class="round">
        <input type="checkbox" checked id="checkbox" />
        <label for="checkbox"></label>
      </div>
      <h3 id="red">Proyecto</h3>
    </li>
    <li>
      <div class="round">
        <input type="checkbox" checked id="checkbox" />
        <label for="checkbox"></label>
      </div>
      <h3 id="gray">Campañas</h3>
    </li>
    <li>
      <p id="options">(Causas y proyectos)</p>
    </li>
    <li class="filter-item filter-by-amount">
      <ui-filter-amount style="width: 200px" (nChange)="setAmount($event)"></ui-filter-amount>
    </li>
    <li class="filter-item filter-by-start-date">
      <ui-filter-date [label]="'Fecha de Inicio'" (nChange)="setStartDate($event)"></ui-filter-date>
    </li>
    <!-- <li class="filter-item filter-by-start-date">
      <h6 class="status" *ngIf="false && campaign && campaign.status">
        <select (change)="setStatus($event)">
          <option>{{ formatStatus(row?.status || '') | translate }}</option>
          <option [disabled]="campaign?.status === 'PENDING'">PENDIENTE</option>
          <option [disabled]="campaign?.status === 'COLLECTING'">EN CORRECCIÓN</option>
          <option [disabled]="campaign?.status === 'OPEN' || campaign?.status === 'IN_PROGRESS'">PUBLICADO</option>
        </select>
      </h6>
    </li> -->

    <li class="filter-item filter-by-finish-date">
      <ui-filter-date [label]="'Fecha de Final'" (nChange)="setEndDate($event)"></ui-filter-date>
    </li>
    <li class="filter-item">
      <ui-solid-btn
            [color]="'white'"
            [text]="'Buscar'"
            (nClick)="go()"
        ></ui-solid-btn>
        <br>
        <br>
    </li>
    <li class="filter-item">
      <h1>Glosario de status</h1><br>
    </li>
    <li class="filter-item">
      <p>Cada palabra en el status indica algo específico, así que para mayor claridad se realizó un glosario a manera de ayuda. </p>
    </li>
    <li class="filter-item">
      <ui-solid-btn
            [color]="'white'"
            [text]="'VER GLOSARIO'"
            (nClick)="openModal()"
        ></ui-solid-btn>
    </li>
  </ul>

  <ul *ngIf="mode === 'USER'">
    <li class="filter-item filter-by-category">
      <h2>Categoria</h2>
      <ul>
        <li *ngFor="let caterg of categories; let k = index" (click)="setCategory(categories[k])">
          <div class="circle outline" [ngClass]="{ fill: category === categories[k] }"></div>
          {{ categories[k] }}
        </li>
      </ul>
    </li>
  </ul>

  <!--
<ul>
    <li
    *ngFor="let caterg of categories; let k = index"
    (click)="setCategory(categories[k])"
    >
    <div
        class="circle outline"
        [ngClass]="{ fill: category === categories[k] }"
    ></div>
    {{ categories[k] }}
    </li>
</ul>
    </div>


-->
  <ui-glossary-modal *ngIf="isModal" (nClose)="closeModal()"></ui-glossary-modal>
</div>

