import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByStatusInCollection',
})
export class FilterByStatusInCollectionPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter(
      (campaign) => 
        campaign.status === 'S0_IN_PROCESS' || 
        campaign.status === 'S1_IN_PENDING' ||
        campaign.status === 'S2_IN_CORRECTING' ||
        campaign.status === 'S3_PUBLISHED' ||
        campaign.status === 'S4_IN_COLLECTING'                                                                                                                                                                                                                                                     
      ) : [];
  }
}
