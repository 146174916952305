<div class="campaing donors">
  <div class="campaing-donors">
    <h1>{{ total }} {{ 'campaigns.donants' | translate }}</h1>
    <h2>{{ last24hrs }} {{ 'campaigns.last24hrsdonants' | translate }}</h2>
  </div>
  <div class="campaing-id">
    <span>ID {{ getID }}</span>
  </div>
</div>

