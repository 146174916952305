import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.less'],
})
export class DropdownInputComponent implements OnInit {
  @Input()
  options: any[];

  @Input()
  default: any = null;

  @Output()
  nChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  setOption($event) {
    this.nChange.emit($event.target.value);
  }
}
