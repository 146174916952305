import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-card-register',
  templateUrl: './card-register.component.html',
  styleUrls: ['./card-register.component.less'],
})
export class CardRegisterComponent implements OnInit {
  constructor(private router: Router) {}

  @Input()
  subtitle: string;

  @Input()
  text1: string;

  @Input()
  text2: string;

  @Input()
  img: string;

  @Input()
  colorsub: string;

  @Input()
  btn: string;

  @Input()
  btnop: number;

  @Input()
  colorbtn: string;

  ngOnInit(): void {}

  go(btnop) {
    switch (btnop) {
      case 1:
        this.router.navigateByUrl('/auth/signup/user');
        break;
      case 2:
        this.router.navigateByUrl('/auth/signup/company');
        break;
    }
  }
}
