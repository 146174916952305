import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { ProfilePageService } from 'src/app/pages/profile-page/profile-page.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { Router } from '@angular/router';
import { IStatusEnum, IStatusOption, IStatusValue } from 'src/app/core/campaigns/models/status.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-admin-campaigns-view',
  templateUrl: './admin-campaigns-view.component.html',
  styleUrls: ['./admin-campaigns-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCampaignsViewComponent {
  checked = false;
  checked2 = false;

  constructor(private toastr: ToastrService, private $campaigns: CampaignsService, private $profile: ProfilePageService, private router: Router) {}

  public get table() {
    if (this.campaigns.length > 0) {
      this.data = this.campaigns;
    }
    return this.campaigns;
  }

  @ViewChild('statusTpl', { static: true }) statusTpl: TemplateRef<any>;
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;


  campaigns: ICampaign[];

  filter: any;

  user: IUser | any = {};

  editRow: number;
  campaign: ICampaign | any;

  public StatusL1: IStatusOption[] = [
    {
      value: IStatusEnum.S0_IN_PROCESS,
      blockers: [],
    },
    {
      value: IStatusEnum.S1_IN_PENDING,
      blockers: [ IStatusEnum.S3_PUBLISHED],
    },
    {
      value: IStatusEnum.S2_IN_CORRECTING,
      blockers: [],
    },
    {
      value: IStatusEnum.S3_PUBLISHED,
      blockers: [ IStatusEnum.S0_IN_PROCESS],
    },
  ];

  public StatusL2: IStatusOption[] = [
    {
      value: IStatusEnum.S4_IN_COLLECTING,
      blockers: [ IStatusEnum.S0_IN_PROCESS, IStatusEnum.S1_IN_PENDING, IStatusEnum.S2_IN_CORRECTING],
    },
    {
      value: IStatusEnum.S5_IN_PAUSE,
      blockers: [IStatusEnum.S0_IN_PROCESS, IStatusEnum.S1_IN_PENDING, IStatusEnum.S2_IN_CORRECTING],
    },
    {
      value: IStatusEnum.S6_IN_EXECUTION,
      blockers: [ IStatusEnum.S0_IN_PROCESS, IStatusEnum.S1_IN_PENDING, IStatusEnum.S2_IN_CORRECTING],
    },
    {
      value: IStatusEnum.S7_CANCELLED,
      blockers: [ IStatusEnum.S0_IN_PROCESS, IStatusEnum.S1_IN_PENDING, IStatusEnum.S2_IN_CORRECTING],
    },
    {
      value: IStatusEnum.S8_FINISHED,
      blockers: [ IStatusEnum.S0_IN_PROCESS, IStatusEnum.S1_IN_PENDING, IStatusEnum.S2_IN_CORRECTING],
    },
  ];

  public data = [];
  public columns: Columns[] = [
    { key: 'id', title: '#ID', width: '2.5%', placeholder: ' ' },
    { key: 'title', title: 'TÍTULO', placeholder: ' ' },
    { key: 'organizer', title: 'AUTOR', placeholder: ' ' },
    { key: 'category', title: 'CATEGORÍA', placeholder: ' ' },
    { key: 'city', title: 'CIUDAD', placeholder: ' ' },
    { key: 'goal', title: 'CANTIDAD', placeholder: ' ' },
    { key: 'date_start', title: 'INICIO', placeholder: ' ' },
    { key: 'date_end', title: 'FIN', placeholder: ' ' },
    { key: 'status', title: 'STATUS', placeholder: ' ' },
  ];
  public configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    selectRow: true,
  };
  public tab = 'admin_campaigns';

  expanded = {};
  timeout: any;

  ColumnMode = ColumnMode;

  modal = false;
  selected;

  isSavingCampaignId = -1;


  ngOnInit(): void {

    // Intial Setup
    this.doLoadTable();
    this.$campaigns.fetchAllCampaigns();

    // Subscriptions
    this.$campaigns.syncCampaigns().subscribe((campaigns) => {
      this.campaigns = JSON.parse(JSON.stringify(campaigns) + '');
      this.updateNxTable();
    })

  }

  updateNxTable() {
    if (this.campaigns && this.campaigns.length > 0) {
      const filtered = JSON.parse(JSON.stringify(this.campaigns) + '');
      this.data = filtered.map((campaing: ICampaign) => {
        return {
          id: campaing.id,
          photo: campaing.photos[0] || '',
          title: campaing.title,
          company: campaing.company,
          organizer: campaing.organizer ? (campaing.organizer as any).name : this.user.name,
          category: campaing.category,
          city: campaing.place_city || 'NA',
          amount: campaing.goal,
          date_start: campaing.date_start,
          date_end: campaing.date_end,
          status: campaing.status,

          // Is in Cover and Outstanding
          isInCover: Boolean(campaing.isInCover),
          isInOutstanding: Boolean(campaing.isInOutstanding),
        };
      });
    }
  }

  setTab(tabName) {
    this.tab = tabName;
  }
  doLoadTable() {
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.configuration.selectRow = true;
    // ... etc.
    this.columns = [
      { key: 'id', title: '#ID', width: '2.5%', placeholder: ' ' },
      { key: 'title', title: 'TÍTULO', placeholder: ' ' },
      { key: 'organizer', title: 'AUTOR', placeholder: ' ' },
      { key: 'category', title: 'CATEGORÍA', placeholder: ' ' },
      { key: 'city', title: 'CIUDAD', placeholder: ' ' },
      { key: 'goal', title: 'CANTIDAD', placeholder: ' ' },
      { key: 'date_start', title: 'INICIO', placeholder: ' ' },
      { key: 'date_end', title: 'FIN', placeholder: ' ' },
      { key: 'status', title: 'STATUS', placeholder: ' ' },
      // { key: 'status', title: 'Status', cellTemplate: this.statusTpl },
      // { key: 'action', title: 'Actions', cellTemplate: this.actionTpl, width: '12%' },
    ];
  }
  edit(rowIndex: number): void {
    this.editRow = rowIndex;
  }

  onEvent(event: { event: string; value: any }): void {
    this.selected = JSON.stringify(event.value.row, null, 2);
  }

  showModal(row): void {
    this.campaign = this.campaigns.find((campaing) => campaing.title === row.title);
    this.modal = true;
  }

  showLink(row): void {
    window.alert('campaign/' + row.id);
    window.open('/campaing/' + row.id, '_blank');
  }

  formatCategory(category) {
    return ('campaings.categories.' + category.toUpperCase()).replace(/ /g, '_');
  }

  formatStatus(status) {
    return ('campaings.status.' + status.toUpperCase()).replace(/ /g, '_');
  }

  goToCampaing(row): void {
    this.router.navigateByUrl(`/campaing/${row.id}`);
  }

  sendMessage(row): void {}

  saveCamapignVisibility($event) {
    const id = $event.id;
    if (!id) return false;

    const $row = $event;
    this.isSavingCampaignId = $event.id;

    this.$campaigns
      .patch(id, {
        isInCover: $event.isInCover,
        isInOutstanding: $event.isInOutstanding,
        isOnlyCoverUpdate: true,
      })
      .then((response) => {
        console.log('UPDATE Save Admin Campaign')
        this.isSavingCampaignId = -1;
        //this.data = [];
        //this.updateNxTable();

      })
      .catch((error) => {
        console.log(' CATCH ');
        this.toastr.error(error, 'Error guardando en campaña');
      }).finally(() => {
        this.isSavingCampaignId = -1;
      })
  }

  onChangeIsInCover($event) {
    console.log(' ');
    console.log(' onChangeIsInCover($event) ');
    console.log($event);
    console.log(' ');
  }

  onChangeIsInOutstanding($event) {
    console.log(' ');
    console.log(' onChangeIsInOutstanding($event) ');
    console.log($event);
    console.log(' ');
  }

  hideModal(): void {
    this.modal = false;
  }

  setStatus($event, campaignId: string) {
    const status = $event.target.value;
    this.campaign = this.campaigns.find((_campaing) => _campaing.id + '' == campaignId);
    const campaign = JSON.parse(JSON.stringify(this.campaign) + '');
    campaign.status = status;
    delete campaign.id;
    campaign['isAdmin'] = true;
    if (window.confirm('Seguro desea cambiar el status de esta campaña? ')) {
      // Save it!
      this.$campaigns
        .patch(campaignId, campaign)
        .then((response) => {
          console.log(' this.$campaigns.patch(campaign.id, campaign) ');
          console.log({ response, campaignId, campaign });
          this.$campaigns.fetchAllCampaigns();
        })
        .catch((error) => {
          console.error(' ERROR : this.$campaigns.patch(campaign.id, campaign) ');
          console.error({ error, campaignId, campaign });
        });
    }
  }

  isStatusL1Disabled(status: IStatusValue): boolean {
    const isEnableL1 = status === 'S5_IN_PAUSE' || this.StatusL1.map((item) => item.value).includes(status);
    return !isEnableL1;
    return this.StatusL1.filter((item) => item.value != 'S3_PUBLISHED')
      .map((item) => item.value)
      .includes(status);
  }
  isStatusL2Disabled(status: IStatusValue): boolean {
    const isEnableL2 = status === 'S3_PUBLISHED' || this.StatusL2.map((item) => item.value).includes(status);
    return !isEnableL2;
  }
  isStatusDisabled(row_status: IStatusValue, status: IStatusOption): boolean {
    return status.blockers.includes(row_status) || status.value === row_status;
  }

  deleteCampaign(row: any) {
    const campaignId = row.id;
    Swal.fire({
      title: 'Seguro desea borrar esta campaña?',
      text: 'Se borrara la campaña para siempre',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si , borrar!',
      cancelButtonText: 'No, cancela!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.$campaigns.removeById(campaignId).then(() => {
          setTimeout(() => {
            this.$campaigns.fetchAllCampaigns();
          }, 2500);
        });
      } else {
        console.error(' NOT BORRANDO');
      }
    });
  }
}
