import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterLanguageComponent } from './components/footer-language/footer-language.component';

@NgModule({
  declarations: [FooterLanguageComponent],
  exports: [FooterLanguageComponent],
  imports: [CommonModule, TranslateModule],
})
export class FooterModule {}
