<div class="page-checkout" *ngIf="device === DEVICES_TYPES.LAPTOP">
  <section class="page-section campaing-wrapper" *ngIf="campaign">
    <div class="wrapper">
      <div class="col">
        <form class="funs__form form__checkout" [formGroup]="form">
          <h1 class="checkout-form--title">SELECCIONA EL MONTO A DONAR <span hidden>v2</span></h1>
          <div class="checkout__hearts">
            <div class="checkout__currency">
              <ui-ng-form-input-options style="position: relative; left: -20px" [control]="form.controls.donation_currency"  label="Moneda" [options]="OPTIONS_CURRENCY" placeholder="-" (nChange)="onChangeCurrency($event)"></ui-ng-form-input-options>
            </div>
            <div class="buttons__hearts">
              <div class="funs__button button__outline" [ngClass]="{'selected': isSelected(5) }">
                <button *ngIf="form.controls.donation_currency.value === 'COP'" (click)="setDonationAmout(10000)">10.000 COP</button>
                <button *ngIf="form.controls.donation_currency.value === 'USD'" (click)="setDonationAmout(5)">5 USD</button>
                <button *ngIf="form.controls.donation_currency.value === 'EUR'" (click)="setDonationAmout(5)">5 EUR</button>
              </div>
              <img class="icon__hearts" src="../../../../../assets/icons/heart_filled.png" />
              <div class="funs__button button__outline" [ngClass]="{'selected': isSelected(10) }">
                <button *ngIf="form.controls.donation_currency.value === 'COP'" (click)="setDonationAmout(15000)">15.000 COP</button>
                <button *ngIf="form.controls.donation_currency.value === 'USD'" (click)="setDonationAmout(10)">10 USD</button>
                <button *ngIf="form.controls.donation_currency.value === 'EUR'" (click)="setDonationAmout(10)">10 EUR</button>
              </div>
              <img class="icon__hearts" src="../../../../../assets/icons/heart_filled.png"/>
              <div class="funs__button button__outline" [ngClass]="{'selected': isSelected(15) }">
                <button *ngIf="form.controls.donation_currency.value === 'COP'" (click)="setDonationAmout(20000)">20.000 COP</button>
                <button *ngIf="form.controls.donation_currency.value === 'USD'" (click)="setDonationAmout(15)">15 USD</button>
                <button *ngIf="form.controls.donation_currency.value === 'EUR'" (click)="setDonationAmout(15)">15 EUR</button>
              </div>
            </div>

          </div>

          <div class="" style="    display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;">
            <div class="checkout__amount" [ngClass]="{'error': isErrorAmount}" style="    -webkit-order: 0;
            order: 0;
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
            -webkit-align-self: auto;
            align-self: auto;">
              <label>Tu donación: $</label>
              <input type="number" formControlName="donation_amount">
              <label>{{form.controls.donation_currency.value }}</label>
            </div>
            <div class="checkout__amount_warning" style="width: 320px;">
              <p style="width: 320px; font-size: 14px;">
                Puedes seleccionar uno de los montos por defecto o escribir el tuyo de forma libre<br>
                (Máximo $1.000.000 COP/$260 USD por día).
              </p>
            </div>
          </div>


          <div class="checkout__form">
            <h5 class="checkout-form--title">TUS DATOS</h5>
            <ui-place-input style="width: 100%; padding-left: 16px; padding-right: 16px;" (nChange)="setPlace($event)"></ui-place-input>
            <span *ngIf="!isColombia"> Para donaciones fuera de Colombia, la donacion como Persona Juridica (Empresa) esta deshabilitada</span>

            <p-tabView class="custom-tabview" (onChange)="onTabChange($event)" #tabView>
                <p-tabPanel header="PERSONA NATURAL">
                  <ng-container *ngIf="isUserTab && form.controls?.user_firstname" >
                    <div class="row-x2">
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.user_firstname"  label="Nombre" type="text"></ui-ng-form-input-text>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.user_lastname"  label="Apellido" type="text"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x2">
                      <ui-ng-form-input-options [disabled]="isAnonymous" [control]="form.controls.user_nid_type"  label="Tipo de Documento" [options]="OPTIONS_NID_TYPES" placeholder="Tipo de Documento"></ui-ng-form-input-options>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.user_nid_number"  label="Numero de Documento" type="number"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x2">
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.user_email"  label="Email" type="email"></ui-ng-form-input-text>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.user_phone"  label="Numero de Telefono" type="text"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x2">
                      <ui-ng-form-input-options [disabled]="isAnonymous" [control]="form.controls.donation_origin"  label="Como te enteraste?" [options]="OPTIONS_ORIGINS" placeholder="Como te enteraste?"></ui-ng-form-input-options>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.donation_postcode"  label="Codigo Postal (Opcional)" type="text"></ui-ng-form-input-text>
                    </div>
                  </ng-container>
                </p-tabPanel>
                <p-tabPanel header="PERSONA JURIDICA (EMPRESA)" [disabled]="!isColombia">
                  <ng-container *ngIf="isCompanyTab && form.controls?.company_name" >
                    <div class="row-x1">
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.company_name"  label="Razon Social" type="text"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x1">
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.company_nid_number"  label="NIT" type="number"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x2">
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.company_email"  label="Email" type="email"></ui-ng-form-input-text>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.company_phone"  label="Numero de Telefono" type="text"></ui-ng-form-input-text>
                    </div>
                    <div class="row-x2">
                      <ui-ng-form-input-options [disabled]="isAnonymous" [control]="form.controls.donation_origin"  label="Como te enteraste?" [options]="OPTIONS_ORIGINS" placeholder="Como te enteraste?"></ui-ng-form-input-options>
                      <ui-ng-form-input-text [disabled]="isAnonymous" [control]="form.controls.donation_postcode"  label="Codigo Postal (Opcional)" type="text"></ui-ng-form-input-text>
                    </div>
                  </ng-container>
                </p-tabPanel>
            </p-tabView>
          </div>

          <div class="form-row anon-check">
            <h2 class="anon-check__title">
              DONACIÓN ANÓNIMA: Al habilitar esta casilla, tu informacion será guardada para efectos de facturación, control y seguridad, pero ni tu nombre ni tu foto se publicarán en el apartado de donantes.
            </h2>
            <div class="form-row anon-check__input hide-name-row">
              <input (change)="onAnonymousChange($event)" formControlName="donation_is_anonymous"  type="checkbox" name="checkout-anonymous-donation" id="checkout-anonymous-donation" />
              <label for="checkout-anonymous-donation"> Donación Anonima</label>
            </div>
          </div>

        </form>
        <button class="button epayco-btn" [disabled]="EPAYCO.disabled">
          <ui-solid-btn
            [color]="'red'"
            style="width: 100%; padding-bottom: 1em; padding-top: 1em"
            [minWidth]="'100%'"
            [margins]="'0em'"
            [paddings]="'1.25em 2em'"
            [text]="'DONAR AHORA'"
            type="submit"
            (nClick)="onSubmit()"
          ></ui-solid-btn>
        </button>
        <img style="display: block; margin: 0 auto;"src="../../../../../assets/icons/btn-epayco.png">
      </div>
      <div class="col">
        <ui-campaign-info
          [campaign]="campaign"
          [title]="campaign.title"
          [photo]="campaign.organizer.photo_url"
          [company]="''"
          [name]="campaign.organizer.name"
          [position]="campaign.organizer.position"
          [category]="campaign.category"
          [donated]="campaign.donated"
          [goal]="campaign.goal"
          [like]="campaign.like"
          [start]="campaign.date_start"
          [end]="campaign.date_end"
        ></ui-campaign-info>
        <div class="campaign-buttons">
          <div class="wrapper x-extremes y-center">
            <ui-outline-btn
              style="width: 100%"
              [color]="'red'"
              [margins]="'0em'"
              [paddings]="'1em'"
              [minWidth]="'100%'"
              [text]="'campaigns.share'"
              (nClick)="openModal()"
            >
            </ui-outline-btn>
          </div>
        </div>
        <ui-campaign-donors [id]="campaign.id" [total]="campaign.stats.total" [last24hrs]="campaign.stats.last24hrs"> </ui-campaign-donors>
        <ui-campaign-map [place]="campaign.place" [place_city]="campaign.place_city"></ui-campaign-map>
        <div class="campaing-about-us x-extremes y-center">
          <h2 class="campaing-about-us--how">
            {{ 'campaigns.how_we_work' | translate }}
          </h2>
          <h2 class="campaing-about-us--report x-end y-center">
            <img src="../../../assets/icons/flag.png" />
            <a> {{ 'campaigns.report' | translate }} </a>
          </h2>
        </div>
      </div>
    </div>
  </section>

  <section class="page-section faq">
    <div class="faq--wrapper">
      <ul>
        <li>
          <strong>La unidad como formula de crecimiento.</strong> Creemos en la colaboración con otras organizaciones e instituciones para potenciar sinergias y
          mejorar el rendimiento del trabajo de la fundación.
        </li>
        <li>
          <strong>Nuestro compromiso social es</strong> trabajar con población que se encuentra en situaciones de mayor vulnerabilidad, con los procesos de
          integración intercultural y deportiva.
        </li>
        <li>
          <strong>La confianza es importante.</strong> Generamos relaciones a nivel interno y externo bajo dinámicas fluidas y eficaces promoviendo la
          comunicación asertiva.
        </li>
      </ul>
      <div class="line"></div>
    </div>
  </section>

  <ui-share-modal *ngIf="isModalShared" (nClose)="closeModal()" [campaign]="campaign"></ui-share-modal>
  <ui-success-modal *ngIf="isModalSuccess" (nClose)="closeModal()"></ui-success-modal>
</div>

<div class="mobile-page-campaign" *ngIf="device === DEVICES_TYPES.MOBILE" style="padding-top: 0px;">
  <ui-mobile-checkout-view *ngIf="campaign"
    [campaign] = "campaign"
  > </ui-mobile-checkout-view>
</div>
