import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
@Pipe({
  name: 'filterByExcludingId',
})
export class FilterByExcludingIdPipe implements PipeTransform {
  transform(campaigns: ICampaign[], id: number): ICampaign[] {
    if (id && id > 0) {
      return campaigns.filter((campaign) => campaign.id !== id);
    } else {
      return campaigns;
    }
  }
}
