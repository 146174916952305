<div class="profile-page--main">
  <div class="main--tabs-content">
    <div class="tab--users">
      <span class="users__search p-float-label">
        <input id="float-input" type="text" pInputText id="globalSearch" (input)="onChange($event)" placeholder="Search" />
        <label for="float-input">Buscar</label>
      </span>

      <ngx-table #table class="users__table table" [configuration]="configuration" [data]="data" [columns]="columns" (event)="onEvent($event)">
        <ng-template class="tabla" let-row>
          <!-- COLUMN::Id -->
          <td class="col__id">
            <label>
              <span>{{ row?.id }}</span>
            </label>
            <img src="{{ row?.photo_url || '/assets/placeholders/placeholder__user.png' }}" alt="" style="width: 3em; height: 3em; object-fit: cover; border-radius: 100%" />
          </td>

          <!-- COLUMN::Name -->
          <td class="col__name">
            <span>{{ row?.name }}</span
            ><br />
            <span>{{ row?.email }}</span>
          </td>

          <!-- COLUMN::Rol -->
          <td class="col__rol">
            <span>{{ row?.rol }}</span>
          </td>

          <!-- COLUMN::Email -->
          <td class="col__email">
            <span>{{ row?.email }}</span>
          </td>

          <!-- COLUMN::Company -->
          <td class="col__company">
            <span>{{ row?.company }}</span>
            <span>{{ row?.position }}</span>
          </td>

          <!-- COLUMN::Stats -->
          <td class="col__total_organized">
            <span>{{ row?.total_organized }}</span>
            <span>{{ row?.total_collected }}</span>
            <span>{{ row?.last_campaign_organized }}</span>
          </td>
        </ng-template>
      </ngx-table>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="sidebarVisible" position="right" styleClass="w-30rem">
  <div class="user_sidebar__header">
    <h3>Editar Usuario</h3>
    <button pButton pRipple label="Guardar" class="p-button-success" (click)="doUserSave()"></button>
  </div>

  <div class="user_sidebar__content grid selected_user" *ngIf="selectedUser && selectedUser.id">
    <div class="row row-2cols">
      <div class="col" style="position: relative">
        <img class="profile_picture" [src]="selectedUser.photo_url || '/assets/placeholders/placeholder__user.png'" />
        <label class="profile_delete" (click)="doRemovePicture()">
          <span><i class="fa fa-trash-o" aria-hidden="true"></i></span>
        </label>
        <label class="profile_edit" >
          <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
          <span ><i class="fa fa-pencil" aria-hidden="true"></i> </span>
        </label>
      </div>
      <div class="col">
        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Nombre'"
          [placeholder]="selectedUser.name"
          [type]="'text'"
          style="width: 100%"
          (nChange)="onChangeName($event)"
        ></ui-text-input-inline-c>

        <ui-text-input-inline-c
        [error]="authError"
        [errorText]="'N/A'"
        [border]="true"
        [label]="'Company'"
        [placeholder]="selectedUser.company"
        [type]="'text'"
        style="width: 100%"
        (nChange)="onChangeCompany($event)"
      ></ui-text-input-inline-c>

        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Correo'"
          [type]="'text'"
          [placeholder]="selectedUser.email"
          style="width: 100%"
          (nChange)="onChangeEmail($event)"
        ></ui-text-input-inline-c>

        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Telefono'"
          [placeholder]="selectedUser.phone"
          [type]="'text'"
          style="width: 100%"
          (nChange)="onChangePhone($event)"
        ></ui-text-input-inline-c>
      </div>
    </div>
    <div class="row row__dropdowns">
      <ui-dropdown-input-c
        [options]="['USER', 'ADMIN', 'EDITOR']"
        [border]="true"
        [label]="'Rol'"
        style="width: 33%"
        [default]="selectedUser.rol"
        (nChange)="onChangeRol($event)"
      >
      </ui-dropdown-input-c>
      <ui-dropdown-input-c
        [options]="['USER', 'FOUNDATION']"
        [border]="true"
        [label]="'Fundacion'"
        style="width: 33%"
        [default]="selectedUser.user_type"
        (nChange)="onChangeType($event)"
      >
      </ui-dropdown-input-c>
      <ui-dropdown-input-c
        [options]="['INACTIVED', 'ACTIVED', 'BLOCKED']"
        [border]="true"
        [label]="'Status'"
        style="width: 33%"
        [default]="selectedUser.status"
        (nChange)="onChangeStatus($event)"
      >
      </ui-dropdown-input-c>
    </div>

    <div class="row" style="display: none">
      <p-scrollPanel [style]="{ width: '100%', height: '250px' }">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
          veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
          consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
        </p>
        <p class="m-0">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas
          molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
          fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
          minus.
        </p>
        <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="value"></textarea>
        <p-button>Enviar</p-button>
      </p-scrollPanel>
    </div>

    <div class="row">
      <p-tabView style="
      width: 100%;
  ">
        <p-tabPanel header="Campañas Creadas">
          <ul>
            <li *ngFor="let organized of (selectedUser.campaigns_organized | filterByStatusNotFinished)">
              <ui-admin-campaign-details-row [campaign]="organized"></ui-admin-campaign-details-row>
            </li>
          </ul>
        </p-tabPanel>
        <p-tabPanel header="Campañas Donadas">
          <ul>
            <li *ngFor="let organized of selectedUser.campaigns_donated">
              <ui-admin-campaign-details-row [campaign]="organized"></ui-admin-campaign-details-row>
            </li>
          </ul>
        </p-tabPanel>
        <p-tabPanel header="Campañas Finalizadas">
          <ul>
            <li *ngFor="let organized of (selectedUser.campaigns_organized | filterByStatusFinished)">
              <ui-admin-campaign-details-row [campaign]="organized"></ui-admin-campaign-details-row>
            </li>
          </ul>
        </p-tabPanel>
      </p-tabView>
    </div>

  </div>
</p-sidebar>

<!--
  id: number;
  created_at: Date;
  updated_at: Date;

  nid_number: string;
  nid_type: string;

  birthdate: Date;
  position: string;

  photo_url: string;
  phone: string;
  mobile: string;

  city: string;
  address: string;

  company?: string;
  name?: string;
  firstname?: string;
  lastname?: string;
  country?: string;
  department?: string;
  email?: string;
  rol?: string;

-->
