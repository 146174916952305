import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Profile2PagesComponent } from './profile2-pages.component';
import { Profile2CampaignsLikedViewComponent } from './views/profile2-campaigns-liked-view/profile2-campaigns-liked-view.component';
import { Profile2CampaignsDonatedViewComponent } from './views/profile2-campaigns-donated-view/profile2-campaigns-donated-view.component';
import { Profile2CampaignsOrganizedViewComponent } from './views/profile2-campaigns-organized-view/profile2-campaigns-organized-view.component';

export const routes = [
  {
    path: 'profile2/:id',
    component: Profile2PagesComponent,
    canActivate: [],
    children: [
       // Campañas que sigo
      { path: 'campaigns/liked', component: Profile2CampaignsLikedViewComponent },
      // Mis donaciones
      { path: 'campaigns/donated', component: Profile2CampaignsDonatedViewComponent },
      // Mis Campañas
      { path: 'campaigns/organized', component: Profile2CampaignsOrganizedViewComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class Profile2PagesRoutingModule {}
