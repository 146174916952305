import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageModule } from '../home-page/home-page.module';
import { LegalPageComponent } from './legal-page.component';
import { HomeAboutComponent } from '../home-page/components/home-about/home-about.component';

@NgModule({
  declarations: [LegalPageComponent],
  imports: [CommonModule, HomePageModule],
})
export class LegalPageModule {}
