<div class="place-inputs" [ngClass]="{'row': isRow, 'column': !isRow, 'disabled': isReset }" >
    <ui-dropdown-input-c
          (nChange)="setField('country', $event)"
          [default]="getFieldDefault('country')"
          [options]="getFieldOptions('country')"
          [border]="getFieldBorder('country')"
          [label]="getFieldLabel('country')"
          [option]="'name'"
          [loading]="isCountryLoading"
    ></ui-dropdown-input-c>
    <ui-dropdown-input-c
        (nChange)="setField('department', $event)"
        [default]="getFieldDefault('department')"
        [options]="getFieldOptions('department')"
        [border]="getFieldBorder('department')"
        [label]="getFieldLabel('department')"
        [option]="'name'"
        [loading]="isDepartmentLoading"
    ></ui-dropdown-input-c>
    <ui-dropdown-input-c
        (nChange)="setField('city', $event)"
        [default]="getFieldDefault('city')"
        [options]="getFieldOptions('city')"
        [border]="getFieldBorder('city')"
        [label]="getFieldLabel('city')"
        [option]="'name'"
        [loading]="isCityLoading"
    ></ui-dropdown-input-c>
</div>