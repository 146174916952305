<form class="form-signup">
  <div class="form-sigin--header">
    <ui-title-auth-h1 [button]="'x'" [title]="'Regístrate'"> </ui-title-auth-h1>
  </div>
  <div class="form-sigin--container" style="padding-top: 3em">
    <div class="row inputs">
      <div class="input--row">
        <ui-text-input-inline
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Nombre de la fundación'"
          [type]="'text'"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>
      <div class="input--row">
        <ui-text-input-inline
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Teléfono'"
          [type]="'text'"
          style="width: 100%"
        ></ui-text-input-inline>
        <ui-text-input-inline
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Celular'"
          [type]="'text'"
          style="width: 100%; margin-left: 60px"
        ></ui-text-input-inline>
      </div>
      <span [ngClass]="{ 'input-failed': nameError }" *ngIf="nameError"> Nombre o Apellido Invalidos </span>

      <ui-text-input-inline [error]="authError" [errorText]="'N/A'" [border]="true" [label]="'NIT'" [type]="'text'" style="width: 100%"></ui-text-input-inline>

      <ui-text-input-inline
        [error]="authError"
        [errorText]="'N/A'"
        [border]="true"
        [label]="'Correo electrónico'"
        [type]="'text'"
        style="width: 100%"
      ></ui-text-input-inline>
      <span [ngClass]="{ 'input-failed': emailError }" *ngIf="emailError"> Correo Invalido </span>

      <ui-text-input-inline
        (nChange)="setPassword($event)"
        [error]="authError"
        [errorText]="'N/A'"
        [border]="true"
        [label]="'Contraseña'"
        [type]="'text'"
        style="width: 100%"
      ></ui-text-input-inline>
      <span [ngClass]="{ 'input-failed': passwordError }" *ngIf="passwordError"> Contraseña Invalido </span>
    </div>
    <div class="row info-password">
      <a style="font-size: 18px; color: #c6c6c6; margin: 0%; padding: 0%">Tu contraseña debe tener al menos X (8) caracteres y una letra mayúscula. </a>
    </div>

    <div class="row inputs">
      <div class="input--row">
        <ui-text-area-inline
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'¿Qué haces como fundación?'"
          [type]="'text'"
          style="width: 100%"
        ></ui-text-area-inline>
      </div>
    </div>

    <div class="row signup-btn">
      <div class="col-button">
        <h4 style="color: #9d9d9c; font-size: 20px; font-family: 'Poppins-Regular'">
          ¿Ya tienes una cuenta?
          <a [routerLink]="'/auth/signin'" style="color: #58b999">Iniciar sesión</a>
        </h4>
        <span style="width: 475px; font-size: 15px; color: #9d9d9c"
          >Al continuar aceptas todos nuestros <a style="color: #58b999" href="">términos y condiciones</a></span
        >
      </div>

      <div class="col-routes foundation"><button class="red" (click)="doConfirm()">REGISTRARME</button> <br /></div>
    </div>
  </div>
  <div class="form-sigin--footer">
    <div class="row more-info">
      <h5>
        Para saber más sobre nuestras políticas y funcionamiento puedes hacer
        <a style="color: #58b999" href="">clic aqui</a>
      </h5>
    </div>
  </div>
</form>
