import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePageComponent } from './create-page.component';
import { CreateCampaignCausePageComponent } from './create-campaign-cause-page/create-campaign-cause-page.component';
import { CreateCampaignFoundationPageComponent } from './create-campaign-foundation-page/create-campaign-foundation-page.component';
import { CreateCampaignMenuPageComponent } from './create-campaign-menu-page/create-campaign-menu-page.component';
import { CreateCampaignThanksPageComponent } from './create-campaign-thanks-page/create-campaign-thanks-page.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CreatePageComponent,
    CreateCampaignCausePageComponent,
    CreateCampaignFoundationPageComponent,
    CreateCampaignMenuPageComponent,
    CreateCampaignThanksPageComponent,
  ],
  exports: [
    CreatePageComponent,
    CreateCampaignCausePageComponent,
    CreateCampaignFoundationPageComponent,
    CreateCampaignMenuPageComponent,
    CreateCampaignThanksPageComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CreatePageModule {}
