import { Component, Input , OnInit} from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }  
  return true;
}


@Component({
  selector: 'ui-text-read-only-editor-js',
  templateUrl: './text-read-only-editor-js.component.html',
  styleUrls: ['./text-read-only-editor-js.component.less']
})
export class TextReadOnlyEditorJsComponent implements OnInit  {

  readonly: any;


  isJSON = false;

  jsonText = '';

  @Input()
  id: string;

  @Input()
  description: string;

  ngOnInit(): void {

    if(isJson(this.description)){
      this.isJSON = true; 
      this.jsonText = JSON.parse(this.description).blocks[0].data.text;  
    }

  }

  get tagId(){
    return 'campaign-description-readonly-js-'+this.id;
  }

  get Text(){

    if(isJson(this.description)){
      return JSON.parse(this.description).blocks[0].data.text;
    } else{
      return this.description;
    }



  }

}
