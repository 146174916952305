import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'ui-mobile-campaign-view',
  templateUrl: './mobile-campaign-view.component.html',
  styleUrls: ['./mobile-campaign-view.component.less']
})
export class MobileCampaignViewComponent {

  @Input()
  id: number;

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  subtitle: string;

  @Input()
  category: string;

  @Input()
  total: number;

  @Input()
  donated: number;

  @Input()
  created_at: Date;

  @Input()
  url: string;

  @Input()
  photos: Array<{ src: string, file: string}>;

  DESCRIPTION_ID = 'id-description-campaign-mobile';

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    scrollbar: false,
    navigation: false,
    autoplay: true,
    effect: 'slide',
    speed: 1000,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false,
    },
  };

  see_more = false;

  constructor(private router: Router){
    
  }
  
  public get activePhotos(): Array<{ src: string, file: string}>{
    return this.photos.filter(item => item.src != "null");
  }

  public isVerticalPhoto(photo){
    const img = document.createElement("img");
    img.src = photo.src;
    const h = img.naturalHeight;
    const w = img.naturalWidth;
    return (h > w);
  }


  public formatCategory(category) {
    return ('campaings.categories.' + category.toUpperCase()).replace(/ /g, '_');
  }

  public goToCheckout(id){
    //const id = this.route.snapshot.paramMap.get('id');
    this.router.navigateByUrl('/checkout/' + id);
  }

  public get text() {
    try {
      const descriptionObj = JSON.parse(this.description);
      if (descriptionObj.blocks[0] && descriptionObj.blocks[0].data.text) {
        // Remove non-breaking spaces (&nbsp;) from the text
        return descriptionObj.blocks[0].data.text.replace(/&nbsp;/g, ' ').trim() || 'N/A';
      }
      return 'N/A';
      
    } catch (e) {
      if (typeof this.description === 'string') {
        // Remove non-breaking spaces (&nbsp;) from the string description
        return this.description.replace(/&nbsp;/g, ' ').trim() || 'ERROR';
      }
      return 'ERROR';
    }
  }
  

  openMoreText(){
    this.see_more = true;
  }
  openLessText(){
    this.see_more = false;
  }


  async doShare(){
    // https://www.fundaciónsoliun-org.com/f/zqz2f-ayuda-help-ni&ossinzika_source=customer&utm_medium=email&utm_campaign=p_cp+share-sheet
    const link = 'https://www.funs.com.co/campaing/' + this.id;
    const title = this.title;
    const text = `Hola, He pensado que quizá te gustaría apoyar esta campaña . Un donativo, por pequeño que sea, puede ayudar a alcanzar su objetivo. Si no te es posible hacerlo, aún podrías ayudar compartiendo la campaña. ¡Gracias por tu tiempo!`;
    const shareData = {
      title: title,
      text: text,
      url: link,
    };
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error(' ERROR SHARING ! ')
      }
  }
}
