import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'ui-ng-form-input-options',
  templateUrl: './ng-form-input-options.component.html',
  styleUrls: ['./ng-form-input-options.component.less']
})
export class NgFormInputOptionsComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() options: { value: any, label: string }[];

  @Output() nChange = new EventEmitter();

  get error(): boolean{
    if(this.control){
      return this.control.invalid && (this.control.dirty || this.control.touched)
    }
    return false;
  }


  onChange($event){
    console.log(' onChange ');
    this.nChange.emit(true);
  }
}
