<div class="page recovery-password-successfull-page">
  <!-- ------------------------------------------ -->
  <!-- --- AUTH::RECOVERY::FORM ------------------- -->
  <!-- ------------------------------------------ -->
  <form class="form-recovery">
    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-recovery--container">
      <img src="../../../../assets/icons/Recovery_Password_Pic1.png" />
      <h1>!Recuperaste tu contraseña!</h1>
      <p>Perfecto, ya puedes continuar ayudando al mundo.</p>
      <img src="../../../../assets/icons/Recovery_Password_Pic2.png" />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--footer">
      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->
  </form>
</div>
