<h2>Filtrar Resultados</h2>
  
<br>

<h2>Por Categorias</h2>
<ul>
  <li *ngFor="let caterg of categories; let k = index" (click)="toogleCategory(categories[k])" [ngClass]="{ fill: isFill(categories[k]) }">
    <div class="circle outline" [ngClass]="{ fill: isFill(categories[k]) }"></div>
    {{ 'campaigns.categories.'+categories[k] | translate }}
  </li>
</ul>

<br>

<h2>¿Algún lugar específico?</h2>
<ui-place-input 
    style="width: 100%" 
    [isRow]="false"
    [isReset]="isClear"
    (nChange)="setPlace($event)"
></ui-place-input>


<ui-default-button (nClick)="applyChanges()" [label]="'Filtrar'" [color]="'green'"></ui-default-button>
<ui-default-button (nClick)="clearChanges()" [label]="'Limpiar'" [color]="'red'"></ui-default-button>