<div class="input-text" [ngClass]="{ bordered: border, error: error }">
  <input
    type="text"
    *ngIf="!type"
    [style.width]="width || 'fit-content'"
    [placeholder]="placeholder || value || ''"
    [value]="placeholder || value || ''"
    (input)="setText($event.target.value)"
  />

  <input
    type="text"
    class="text-currency"
    *ngIf="type && type === 'currency'"
    [style.width]="width || 'fit-content'"
    [placeholder]="placeholder || value || '' | currency: 'USD':'symbol':'1.0-0'"
    [value]="placeholder || value || '' | currency: 'USD':'symbol':'1.0-0'"
    (input)="setText($event.target.value)"
  />

  <span *ngIf="error">{{ error.error }}</span>
</div>
