<form>
  <div class="form--header">
    <ng-content section='section[name="header"]'> </ng-content>
  </div>

  <div class="form--container">
    <ng-content section='section[name="container"]'> </ng-content>
  </div>

  <div class="form--footer">
    <ng-content section='section[name="footer"]'> </ng-content>
  </div>
</form>
