import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateDiffTime2',
})
export class DateDiffTime2Pipe implements PipeTransform {
  transform(date: Date, startDate?: Date): { time: string; label: string } {
    let diffHours;
    let diffDays;
    let diffWeeks;
    let diffMonths;

    if (startDate) {
      diffHours = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asHours();
      diffDays = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asDays();
      diffWeeks = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asWeeks();
      diffMonths = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asMonths();
    } else {
      diffHours = moment.duration(moment.utc(date).diff(moment.utc())).asHours();
      diffDays = moment.duration(moment.utc(date).diff(moment.utc())).asDays();
      diffWeeks = moment.duration(moment.utc(date).diff(moment.utc())).asWeeks();
      diffMonths = moment.duration(moment.utc(date).diff(moment.utc())).asMonths();
    }
    if (Math.abs(diffHours) < 24) {
      return {
        time: Math.abs(diffHours) + '',
        label: 'horas',
      };
    } else if (Math.abs(diffDays) < 7) {
      return {
        time: Math.abs(diffDays) + '',
        label: 'dias',
      };
    } else if (Math.abs(diffWeeks) < 4) {
      return {
        time: Math.abs(diffWeeks) + '',
        label: 'semanas',
      };
    } else {
      return {
        time: Math.abs(diffMonths) + '',
        label: 'meses',
      };
    }
  }
}