<div class="campaing-info">

  <div class="wrapper">
    <div class="row">
      <h1 class="title" *ngIf="!editable">{{ title | truncateText: 50 }}</h1>
      <h1 class="title isEditable" *ngIf="editable">
        <input type="text" [value]="title" placeholder="Nombre de la Campaña" (change)="onChange($event.target.value)" />
      </h1>
    </div>
    <div class="row x-start y-center">
      <div class="col-photo">
        <img class="photo" [src]="photo || '/assets/placeholders/placeholder__user.png'"  />
      </div>
      <div class="col-text">
        <h2 class="company">{{ company }}</h2>
        <h2 class="name">Responsable: {{ name }}</h2>
        <h2 class="category" *ngIf="!editable">Categoria: {{ 'campaings.categories.'+category  | translate }}</h2>

        <h2 class="category" *ngIf="editable">
          <select class="category_selector" (change)="onCategoryChange($event)">
            <select selected value="category"> {{ 'campaings.categories.'+category  | translate }} </select>
            <option *ngFor="let kCategory of CATEGORIES" [value]="kCategory">
              {{ 'campaings.categories.'+kCategory  | translate }}
            </option>
          </select>
          <br>
          Categoria: {{ 'campaings.categories.'+category  | translate }}
        </h2>
      </div>
    </div>

    <!-- <div class="row x-end y-center" *ngIf="!editable">
      <span class="seemore">(VISTA PREVIA)</span>
    </div> -->

    <div class="row x-extremes y-center" *ngIf="!editable" style="line-height: 1em">
      <div class="col donated">
        <div>
          <span class="donated-1">
            {{ (donated || 0 | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }}
          </span>
          <span class="donated-2"> recolectados de </span>
          <span class="donated-3">{{ (goal || 0 | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }}</span>
        </div>
        <div>
          <div class="missing-donated">
            Faltan {{ ((goal - donated) | currency: '$':'symbol':'1.0-0').replaceAll(',','.') }}
          </div>
        </div>
      </div>
      <div class="col like" (click)="setLike()" style="cursor: pointer">
        <img *ngIf="!hasLike" src="../../../../../assets/icons/heart.png" />
        <img *ngIf="hasLike" src="../../../../../assets/icons/heart_filled.png" />
      </div>
    </div>

    <br *ngIf="editable">

    <div class="row x-extremes y-center" *ngIf="editable">
      <div class="col editable-goals">
        <img src="../../../../../assets/icons/editable-goals-target.PNG" />
        <span> Objetivo de recaudo </span> : <br>
        <input class="input-editable goal"[(ngModel)]="goal" (change)="onChange()"> <br>
        {{ goal | currency: '$':'symbol':'1.0-0' }}
        <img class="lock" src="../../../../../assets/icons/editable-goals-lock.PNG" />
      </div>
    </div>

    <div class="row x-extremes y-center" *ngIf="editable" >
      <div class="col editable-goals">
        <img src="../../../../../assets/icons/editable-goals-clock.PNG" />
        <span> Tiempo de recaudo </span> :
        <br>
        <input type="date" class="input-editable end"[(ngModel)]="end" (change)="onChange()">
        <br>
        <span>
          {{ (end | dateDiffTime: start).time | number: '1.0-0' }}
          {{ (end | dateDiffTime: start).label }}
        </span>
        <img class="lock" src="../../../../../assets/icons/editable-goals-lock.PNG" />
      </div>
    </div>

    <div class="row x-extremes y-center" *ngIf="editable">
      <div class="col editable-goals">
        <i class="fas fa-flag"></i>
        <span> Guardados </span> : versions {{ versions }} <span> disponibles</span>
        <img class="lock" src="../../../../../assets/icons/editable-goals-lock.PNG" />
      </div>
    </div>

    <br />

    <div class="row x-end y-center" style="width: 100%" *ngIf="!editable">
      <ui-widget-percentage-level style="width: 100%" [maxValue]="goal" [markerPosition]="'CENTER'" [currentValue]="donated"></ui-widget-percentage-level>
    </div>

    <br />

    <div class="row x-extremes y-center" *ngIf="!editable">
      <div class="col start-date">
        <img src="../../../../../assets/icons/ANTIRELOJ.png" />
        <span>
          Se créo hace {{ (start | dateDiffTime).time | number: '1.0-0' }}
          {{ (start | dateDiffTime).label }}
        </span>
      </div>
      <div class="col end-date">
        <img src="../../../../../assets/icons/RELOJ.png" />
        <span>
          {{ (end | dateDiffTime).time | number: '1.0-0' }}
          {{ (end | dateDiffTime).label }} para terminar
        </span>
      </div>
    </div>
  </div>
</div>
