import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-footer-language',
  templateUrl: './footer-language.component.html',
  styleUrls: ['./footer-language.component.less'],
})
export class FooterLanguageComponent implements OnInit {
  public languageCode: 'es' | 'en' = 'es';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  setLanguage(languageCode: 'es' | 'en') {
    this.languageCode = languageCode;
    this.translate.use(languageCode);
  }
}
