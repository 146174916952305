import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByCityName',
})
export class FilterByCityNamePipe implements PipeTransform {
  transform(campaigns: ICampaign[], city: string): ICampaign[] {
    if (city && city.length > 0) {
      return (campaigns || []).filter((campaign) => (campaign.place.city || '').toUpperCase().includes(city.toUpperCase()));
    } else {
      return campaigns || [];
    }
  }
}
