import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.less'],
})
export class HomeAboutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
