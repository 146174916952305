import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
@Pipe({
  name: 'filterByIsOutstanding',
})
export class FilterByIsOutstandingPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    if (campaigns && campaigns.length > 0) {
      return campaigns.filter((campaign) => campaign.isInOutstanding);
    }
    return [];
  }
}
