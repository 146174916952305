import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
  SwiperAutoplayInterface,
} from 'ngx-swiper-wrapper';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 5,
  spaceBetween: 0,
  slideToClickedSlide: true,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  },
};

@Component({
  selector: 'ui-home-outstandings-campaigns',
  templateUrl: './home-outstandings-campaigns.component.html',
  styleUrls: ['./home-outstandings-campaigns.component.less'],
})
export class HomeOutstandingsCampaignsComponent implements OnInit {
  @Input()
  campaigns: ICampaign[];

  public config = DEFAULT_SWIPER_CONFIG;
  public index = 0;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  ngOnInit(): void {}
}
