import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/users/services/user.service';
import { UIService } from "src/app/shared/services/ui.service";

@Component({
  selector: 'ui-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.less'],
})
export class HeaderAuthComponent implements OnInit {
  public user: any = null;
  public isMenuActive = false;
  constructor(private $ui: UIService, private router: Router, private $user: UserService) {}

  ngOnInit(): void {
    const user = JSON.parse(sessionStorage.getItem('user') || 'null');
    if (user) {
      this.$user.setUser(user);
    }

    this.$user.syncUser().subscribe((_user) => {
      this.user = _user;
    });
  }

  goToProfileEdit(){
    this.router.navigate(['/profile2/'+this.user.id]);
    this.$ui.openSidebarProfile();
  }

  goToLogin() {
    this.router.navigate(['/auth/signin']);
  }

  doLogout() {
    sessionStorage.clear();
    localStorage.clear();
    this.$user.setUser(null);

    this.router.navigate(['/']);
  }
}
