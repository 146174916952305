<form class="form form-foundation">

  <div class="form--header">
    <div class="x">x</div>
    <div class="title">Iniciar Un Proyecto </div>
  </div>

  <div class="form--container">
    <div class="form-row login-row" *ngIf="!user">
      <h1>Ya tienes una cuenta, <a href="">inicia sesión</a></h1>
    </div>

 
    <br *ngIf="user" />

    <form  class="funs__form form__create_campaign" [formGroup]="form">
      <ng-container *ngIf="!user && !isLogged">
        <div class="row-x1">
          <ui-ng-form-input-text *ngIf="form.controls?.user_name" [control]="form.controls?.user_name"  label="Nombre de usuario" type="text"></ui-ng-form-input-text>        
        </div>
        <div class="row-x2">
          <ui-ng-form-input-text *ngIf="form.controls?.user_phone" [control]="form.controls?.user_phone"  label="Número de teléfono" type="tel"></ui-ng-form-input-text>
          <ui-ng-form-input-text *ngIf="form.controls?.user_mobile" [control]="form.controls?.user_mobile"  label="Número de celular" type="tel"></ui-ng-form-input-text>
        </div>
        <div class="row-x2">
          <ui-ng-form-input-text *ngIf="form.controls?.user_nid" [control]="form.controls?.user_nid"  label="NIT" type="text"></ui-ng-form-input-text>
          <ui-ng-form-input-text *ngIf="form.controls?.user_email" [control]="form.controls?.user_email"  label="Correo Electrónico" type="email"></ui-ng-form-input-text>
        </div>
        <div class="row-x2">
          <ui-ng-form-input-text *ngIf="form.controls?.user_password" [control]="form.controls?.user_password"  label="Contraseña" type="password"></ui-ng-form-input-text>
          <ui-ng-form-input-text *ngIf="form.controls?.user_password2" [control]="form.controls?.user_password2"  label="Contraseña" type="password"></ui-ng-form-input-text>
        </div>
        <h3  *ngIf="!user">Tu contraseña debe tener al menos 8 caracteres 1 letra mayuscal y 1 simbolo</h3>
      </ng-container>
      <ng-container>
        <div class="row-x1">
          <ui-ng-form-input-text [control]="form.controls.campaign_name"  label="Titulo del proyecto" type="text"></ui-ng-form-input-text>
        </div>
        <div class="row-x2">
          <ui-ng-form-input-options [control]="form.controls.campaign_category"  label="Categoria" [options]="formCategoriesOptions" placeholder="Selecciona una categoria"></ui-ng-form-input-options>
          <ui-ng-form-input-text [control]="form.controls.campaign_postcode"  label="Codigo Postal" type="text"></ui-ng-form-input-text>
        </div>
        <div class="row-x1">
          <ui-place-input style="width: 100%; padding-left: 16px; padding-right: 16px;" (nChange)="setPlace($event)"></ui-place-input>
        </div>
        <div class="row-x1">
          <ui-ng-form-input-textarea [touched]="form.controls.campaign_description.touched" [control]="form.controls.campaign_description"  label="Descripción del proyecto" type="text"></ui-ng-form-input-textarea>
        </div>
        <div class="row-x1">
          <div class="form__input urgent">
            <span>Si es necesario que prioricemos tu solicitud, activa la</span>
            <input type="checkbox" formControlName="campaign_is_urgent">
            <label>Revisión Urgente</label>
          </div>
        </div>
        <br>
        <br>
        <div class="row-x2 between">
          <div class="form__input amount" [ngClass]="{'error': errorAmount}">
            <span>Indica tu objetivo aquí: </span>
            <input type="number" formControlName="campaign_amount" type="number" pattern="[0-9]*" inputmode="numeric" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
            <label>{{ form.get('campaign_currency').value }}</label>
          </div>
          <div class="form__input currency" [ngClass]="{'error': errorAmount}">
            <select formControlName="campaign_currency">
              <option selected>COP</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>
        </div>
        <br>
        <div class="row-x2 between">
          <div class="form__input deadline" [ngClass]="{'error': errorDeadline}">
            <span>Tiempo de Recaudo: </span>
            <label>{{ form.get('campaign_deadline').value }} </label>
          </div>
          <div class="form__input days" [ngClass]="{'error': errorDeadline}">
            <select formControlName="campaign_deadline">
              <option value="null" selected disabled>Selecciona</option>
              <option value="1">1 dia</option>
              <option value="3">3 dias</option>
              <option value="7">1 semana</option>
              <option value="14">2 semanas</option>
              <option value="21">3 semanas</option>
              <option value="30">1 mes</option>
              <option value="60">2 meses</option>
              <option value="90">3 meses</option>
              <option value="120">4 meses</option>
            </select>
          </div>
        </div>
       
      </ng-container>
    </form>

    <br />
    <br />

    <div class="form-row" style="width: 100%">
      <ui-solid-btn
        style="margin: 0 auto"
        [color]="'red'"
        [minWidth]="'200px'"
        [margins]="'0em auto'"
        [paddings]="'0.5em 1em'"
        [text]="'Enviar'"
        (nClick)="onSubmit()"
      ></ui-solid-btn>
    </div>
  </div>

  <div class="form--footer">
    <div class="row more-info">
      <h5>
        Para saber más sobre nuestras políticas y funcionamiento puedes hacer
        <a href="">clic aqui</a>
      </h5>
    </div>
  </div>
</form>
