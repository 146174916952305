import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  public show = true;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('auth')) {
          this.show = false;
        } else {
          this.show = true;
        }
      }
    });
  }

  goToFoundations(): void {
    this.router.navigateByUrl('/campaigns/foundations');
  }

  goToCauses(): void {
    this.router.navigateByUrl('/campaigns/causes');
  }

  goToAboutUs(): void {
    this.router.navigateByUrl('/about/us');
  }
  goToAboutWork(): void {
    this.router.navigateByUrl('/about/work');
  }
  goToPolitics(): void {
    this.router.navigateByUrl('/politics');
  }
  goToTerms(): void {
    this.router.navigateByUrl('/terms');
  }
  goToRequiriments(): void {
    this.router.navigateByUrl('/requirements');
  }
}
