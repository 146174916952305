import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
import * as moment from 'moment';
@Pipe({
  name: 'dateDiffTime',
})
export class DateDiffTimePipe implements PipeTransform {
  transform(date: Date, startDate?: Date): { time: string; label: string } {
    let diffHours;
    let diffDays;
    let diffWeeks;
    let diffMonths;

    if (startDate) {
      diffHours = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asHours();
      diffDays = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asDays();
      diffWeeks = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asWeeks();
      diffMonths = moment.duration(moment.utc(date).diff(moment.utc(startDate))).asMonths();
    } else {
      diffHours = moment.duration(moment.utc(date).diff(moment.utc())).asHours();
      diffDays = moment.duration(moment.utc(date).diff(moment.utc())).asDays();
      diffWeeks = moment.duration(moment.utc(date).diff(moment.utc())).asWeeks();
      diffMonths = moment.duration(moment.utc(date).diff(moment.utc())).asMonths();
    }
    if (Math.abs(diffHours) < 24) {
      return {
        time: Math.abs(diffHours) + '',
        label: 'horas',
      };
    } else if (Math.abs(diffDays) < 7) {
      return {
        time: Math.abs(diffDays) + '',
        label: 'dias',
      };
    } else if (Math.abs(diffWeeks) < 4) {
      return {
        time: Math.abs(diffWeeks) + '',
        label: 'semanas',
      };
    } else {
      return {
        time: Math.abs(diffMonths) + '',
        label: 'meses',
      };
    }
  }
}

/*

 if(this.campaign.date_end){
      const days = moment.utc(this.campaign.date_end).diff( moment.utc() , 'days');
      if(days < 1 ) {
        const hours = moment.utc(this.campaign.date_end).diff( moment.utc() , 'hours');
        return {
          value: hours,
          label: 'hours'
        }
      } else if(days < 60) {
        return {
          value: days,
          label: 'days'
        }
      } else if(days < 365){
        const months = moment.utc(this.campaign.date_end).diff( moment.utc() , 'months');
        return {
          value: months,
          label: 'months'
        }
      } else {
        const  years = moment.utc(this.campaign.date_end).diff( moment.utc() , 'years');
        return {
          value: years,
          label: 'years'
        }
      }
    } else {
      return null;
    }
    */
