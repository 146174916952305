import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUser } from '../../users/models/user.model';
import { ICampaign } from '../models/campaign.model';

@Injectable({
  providedIn: 'root',
})
export class LikesService {
  constructor(private $http: HttpClient) {}

  async setLike(campaign: ICampaign, user: IUser) {
    console.log('setLike() => {}');
    console.log({ campaign, user });

    const url = `${environment.server}/likes/add`;
    const obj = {
      user_id: user.id,
      campaign_id: campaign.id,
    };
    const response: any = await this.$http.post<any>(url, obj).toPromise();
  }

  async removeLike(campaign: ICampaign, user: IUser) {
    const url = `${environment.server}/likes/remove`;
    const obj = {
      user_id: user.id,
      campaign_id: campaign.id,
    };
    const response: any = await this.$http.post<any>(url, obj).toPromise();
  }
}
