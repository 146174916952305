import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByIsDonated',
})
export class FilterByIsDonatedPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter((campaign) => campaign.isDonated) : [];
  }
}
