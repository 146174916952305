import { Component } from '@angular/core';

@Component({
  selector: 'ui-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.less']
})
export class InputSwitchComponent {
  checked = false;
}
