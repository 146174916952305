import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByCampaingType',
})
export class FilterByCampaingTypePipe implements PipeTransform {
  transform(campaigns: ICampaign[], type: string): ICampaign[] {
    if (campaigns && type && type.length > 0) {
      return campaigns.filter((campaign) => campaign.type.toUpperCase() === type.toUpperCase());
    } else {
      return campaigns;
    }
  }
}
