<div class="header-search">
  <div class="row input-row">
    <div class="wrapper" [ngClass]="{ focused: isFocus || text.length > 0 }">
      <input type="text" [(ngModel)]="text" (change)="onChange()" (blur)="onBlur()" (focus)="onFocus()" />
    </div>
    <img src="assets/icons/mag-glass.png" />
  </div>

  <div class="row results-row" [ngClass]="{ disabled: !(text.length > 0) }">
    <ul>
      <li *ngFor="let result of results$ | async | slice: 1:10">
        <a class="result-item" href="{{ result.url }}" *ngIf="result.description.length > 20">
          <img [src]="result.src" />
          <span> {{ result.description | slice: 0:12 }} ... {{ result.description | slice: result.description.length - 5:result.description.length }} </span>
        </a>
        <a href="{{ result.url }}" *ngIf="result.description.length < 20">
          {{ result.description | slice: 0:25 }}
        </a>
      </li>
    </ul>
  </div>
</div>
