import { Component } from '@angular/core';

@Component({
  selector: 'ui-mobile-campaigns-view',
  templateUrl: './mobile-campaigns-view.component.html',
  styleUrls: ['./mobile-campaigns-view.component.less']
})
export class MobileCampaignsViewComponent {

}
