import { Component } from '@angular/core';

@Component({
  selector: 'ui-funs-mobile-spinner',
  templateUrl: './funs-mobile-spinner.component.html',
  styleUrls: ['./funs-mobile-spinner.component.less']
})
export class FunsMobileSpinnerComponent {

}
