<div class="page-about page-about-work">
  <section class="section-title">
    <h1>¿CÓMO FUNCIONAMOS?</h1>
  </section>

  <section class="section-green">
    <br />
    <br />
    <br />
    <p>
      Somos una entidad sin ánimo de lucro, que figura como una persona jurídica de derecho privado,
      <strong>regulada por los artículos 633 a 652 del Código Civil Colombiano, el decreto 2150 de 1995</strong>
      y demás normas concordantes. Se constituye en el año 2019 como una entidad sin ánimo de lucro, de carácter permanente, independiente, autónomo y de
      utilidad común.
    </p>

    <h2>MISIÓN</h2>
    <h3>Disminuir la brecha de desigualdad en Colombia y el mundo.</h3>
    <p>
      Somos una organización sin ánimo de lucro que desarrolla iniciativas de <b>emprendimiento social</b> para conectar causas y fundaciones con el fin de favorecer a
      que las personas desarrollen su potencial, buscando mejorar sus condiciones de vida, la de sus familias y comunidades, además de contribuir con la
      reducción de la brecha de desigualdad en Colombia y el mundo.
    </p>
    <br />

    <h2>VISIÓN</h2>
    <p>
      En el año 2025 ser una organización sin ánimo de lucro reconocida, que contribuye con el desarrollo integral de la comunidad siendo un puente de
      comunicación y trabajo en equipo con entidades públicas, causas y fundaciones generando una comunidad a través de iniciativas de emprendimiento social.
    </p>
  </section>

  <section class="section-pink">
    <br />
    <br />
    <br />

    <h2>VALORES INSTITUCIONALES</h2>

    <ul>
      <li>
        <strong>UNIDAD:</strong>Creemos en la colaboración con otras organizaciones e instituciones para potenciar sinergias y mejorar el rendimiento laboral de
        la fundación.
      </li>
      <li>
        <strong>COMPROMISO SOCIAL:</strong>Trabajar con población que se encuentra en situaciones de mayor vulnerabilidad, con los procesos de integración
        intercultural y deportiva de las personas.
      </li>
      <li><strong>CONFIANZA:</strong>Generamos relaciones a nivel interno y externo bajo dinámicas fluidas, eficaces y de comunicación asertiva.</li>
      <li><strong>HONESTIDAD:</strong>Honramos y defendemos el patrimonio de la Fundación siendo totalmente transparentes en nuestro accionar.</li>
      <li>
        <strong>COMPROMISO:</strong>Proporcionamos nuestro mayor esfuerzo para conseguir cada uno de nuestros objetivos, el de fundaciones aliadas y
        organizadores de causas.
      </li>
      <li>
        <strong>TRANSPARENCIA:</strong>Basamos nuestra labor en la honestidad, la mutua responsabilidad y el máximo acceso a la información como pilares de una
        rendición de cuentas sociales y económicas.
      </li>
    </ul>
  </section>

  <section class="section-red">
    <h2>OBJETIVOS</h2>

    <h3>Objetivo Estratégico No. 1</h3>
    <p>
      Gestionar los recursos técnicos y financieros a nivel interno y externo para lograr la sostenibilidad operativa y financiera de la Fundación y sus
      afiliados.
    </p>

    <h3>Objetivo Estratégico No. 2</h3>
    <p>
      Identificar y atender personas y familias en condición de vulnerabilidad o pobreza, que presenten situaciones de emergencias sociales, naturales
      imprevistas o generadas por efectos externos y que no cuenten con la capacidad para enfrentarlas y aportar a la estabilidad de su situación.
    </p>

    <h3>Objetivo Estratégico No. 3</h3>
    <p>
      Identificar fundaciones legalmente constituidas con el objetivo de realizar un proceso de articulación y brindar atención a personas para así visibilizar
      las personas y fundaciones que contribuyen a cumplir nuestra misión.
    </p>
  </section>
</div>
