<div class="page signup-welcome-page">
    <form class="form-welcome">

  

        <div class="form-signup--container" style="padding-top: 0em">

            <h1>¡Bienvenido!</h1>
            <p>
                Ahora puedes ayudar al mundo, propagando tu solidaridad con personas igulaes a ti que quieren construir
                una mejor sociedad
            </p>

            <div style=" width: 400px;margin: 0 auto; padding-top: 1em; padding-bottom: 1em;">
                <ui-default-button 
                    (nClick)="goToProfile()" 
                    [label]="'VER MI NUEVO PERFIL'" 
                    [color]="'green'">
                </ui-default-button>
            </div>


            <div class="row more-info">
                <h5>¿Quieres crear un proyecto como fundación o generar una causa como organizador?</h5>
            </div>

            <div class="row links">
                <a class="foundation" [routerLink]="'/create/campaign/type/foundation'">SOY UNA FUNDACIÓN</a>
                <a class="project" [routerLink]="'/create/campaign/type/cause'">SOY UN ORGANIZADOR</a>
            </div>
            <br />
        </div>
        

        <div class="form-signup--footer">
            <div class="row more-info">
                <h5>
                    Para saber más sobre nuestras políticas y funcionamiento puedes hacer
                    <a style="color: #58b999" href="">clic aqui</a>
                </h5>
            </div>
        </div>
    </form>
</div>