import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-campaign-buttons',
  templateUrl: './campaign-buttons.component.html',
  styleUrls: ['./campaign-buttons.component.less'],
})
export class CampaignButtonsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  doShare() {}
  doDonate() {}
}
