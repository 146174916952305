import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-title-h1',
  templateUrl: './title-h1.component.html',
  styleUrls: ['./title-h1.component.less'],
})
export class TitleH1Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
