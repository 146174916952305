import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-campaign-tab',
  templateUrl: './campaign-tab.component.html',
  styleUrls: ['./campaign-tab.component.less'],
})
export class CampaignTabComponent implements OnInit {
  @Input('tabTitle') title: string;
  @Input() active = false;

  constructor() {}

  ngOnInit(): void {}
}
