<form class="form">
  <div class="form--header">
    <div class="x">x</div>
    <div class="title">Iniciar Campaña</div>
  </div>
  <div class="form--container">
    <div class="form-row">
      <h3 class="title">¿Quieres crear un proyecto como fundación o generar una causa como organizador?</h3>
    </div>
    <div class="form-row cols">
      <div class="form-col first-child">
        <p>Si cuentas con una fundación legalmente constituida en Colombia esta es la forma correcta de ayudar al mundo hoy.</p>
        <h3>
          <a href="create/campaign/type/foundation">SOY UNA FUNDACIÓN ></a>
        </h3>
      </div>
      <div class="form-col line"></div>
      <div class="form-col last-child">
        <p>Si eres una persona que quiere aportar a solucionar una problemática social, esta es la forma correcta de ayudar al mundo hoy.</p>
        <h3><a href="create/campaign/type/cause">SOY UN ORGANIZADOR ></a></h3>
      </div>
    </div>
    <div class="form-row login-row">¿Ya tienes una cuenta? <a href="/auth/signin">Iniciar Sesión</a></div>
  </div>
  <div class="form--footer">
    <div class="row more-info">
      <h5>
        Para saber más sobre nuestras políticas y funcionamiento puedes hacer
        <a href="">clic aqui</a>
      </h5>
    </div>
  </div>
</form>
