import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/core/users/services/user.service';
import { LikesService } from '../../services/likes.service';
import { campaignCategories } from "../../models/campaign.model";
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it'
registerLocaleData(localeIt, 'it');
@Component({
  selector: 'ui-campaign-info',
  templateUrl: './campaign-info.component.html',
  styleUrls: ['./campaign-info.component.less'],
})
export class CampaignInfoComponent implements OnInit {
  @Input()
  campaign;

  @Input()
  editable = false;

  @Output()
  nChange = new EventEmitter<any>();

  @Input()
  title: string;

  @Input()
  photo: string;

  @Input()
  company: string;

  @Input()
  name: string;

  @Input()
  position: string;

  @Input()
  category: string;

  @Input()
  donated: number;

  @Input()
  goal: number;

  @Input()
  like: boolean;

  @Input()
  start: Date;

  @Input()
  end: Date;

  @Input()
  versions: number;

  public hasLike = false;
  public tooltipEnabled = false;

  public CATEGORIES = campaignCategories;

  constructor(
    private $user: UserService,
    private $likes: LikesService
  ) { }

  ngOnInit(): void {
    this.$user.syncUser().subscribe((user) => {
      try {
        if (user) {
          if (typeof this.campaign.followers === 'string') {
            this.hasLike = (JSON.parse(this.campaign.followers) || []).filter((follower) => follower.user_id === user.id).length > 0;
          } else {
            this.hasLike = (this.campaign.followers || []).filter((follower) => follower.user_id === user.id).length > 0;
          }
        }
      } catch (err) { }
    });
  }

  onChange(text) {
    if (text) {
      this.nChange.emit({
        category: this.campaign.category,
        title: text,
        goal: this.goal,
        end: this.end
      });
    } else {
      this.nChange.emit({
        category: this.campaign.category,
        goal: this.goal,
        end: this.end
      });
    }
  }

  onCategoryChange($event){
    this.campaign.category = $event.target.value;
    this.nChange.emit({
      category: this.campaign.category,
      goal: this.goal,
      end: this.end
    });
  }

  async setLike() {
    const user = this.$user.getUser();
    if (user) {
      this.hasLike = !this.hasLike;
      const campaign = this.campaign;
      if (this.hasLike) {
        this.$likes.setLike(campaign, user);
      }
      if (!this.hasLike) {
        this.$likes.removeLike(campaign, user);
      }
    } else {
      this.tooltipEnabled = true;
      setTimeout(() => (this.tooltipEnabled = false), 10000);
    }
  }
}
