import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PicturesService {
  constructor(private $http: HttpClient) {}

  async upload2(base64) {
    const response = await this.$http.post(`${environment.server}/files/pictures/upload/`, { image: base64 }).toPromise();
    return (response as any).src;
  }
  async upload(file: File) {

    var myFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');


    myFormData.append('image', file);

    const response = await this.$http.post(
      `${environment.server}/files/photos/upload`, 
      myFormData, 
      {
        headers: headers
      }).toPromise();

    return environment.server  + (response as any).path;
  }
}
