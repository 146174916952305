import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { IUser } from "src/app/core/users/models/user.model";
import { environment } from "src/environments/environment";
export type IEpaycoPayload = {

  campaign_id: number;
  campaign_title: string;

  donation_amount: number;
  donation_currency: string;
  donation_user_id?: number;
  donation_is_anonymous: boolean;

  billing_name: string;
  billing_email: string;
  billing_phone: string;
  billing_address: string;
  billing_nid_type: string;
  billing_nid_number: string;
  billing_country: string;
  billing_deparment: string;
  billing_city: string;
}

export type IEpaycoObject = {
name: string;
email: string;
description: string;
invoice: string;
currency: string;
amount: number;
tax_base: string;
tax: string;
country: string;
lang:string;
Onpage: boolean;
extra1: string;
extra2: string;
extra3: string;
confirmation: string;
name_billing: string;
address_billing: string;
type_doc_billing: string;
mobilephone_billing:string;
number_doc_billing: string;
acepted: string;
rejected: string;
pending: string;
}

export interface IUserPayload{
  id?: number;
  is_anonymous: boolean;
  name: string;
  email: string;
  phone: string;
  nid_type: string;
  nid_number: string;
}
export interface IPaymentPayload{
  user_id: number;
  campaign_id: number;
  method: string;
  amount: number;
  currency: string;
  status: string;
  nid_number: string;
  email: string;
  name: string;
  is_anonymous: boolean
}
export interface IDonationPayload{}

export interface IUserResponse{
  id: number;
}
export interface IPaymentResponse{
  id: number;
}
export interface IDonationResponse{
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class EpaycoService {

  private key =  '6e954af29476c4439bffe90adfbe16a0';
  private isTest = false;

  constructor(private $http: HttpClient) { }


  doCreateObject(payload: IEpaycoPayload): IEpaycoObject{
    return{
       name: payload.campaign_title,
       email: payload.billing_email,
       description: payload.campaign_id + '',
       invoice: payload.campaign_id + '',
       currency: payload.donation_currency.toLowerCase(),
       amount: payload.donation_amount,
       tax_base: "0",
       tax: "0",
       country: "co",
       lang: "es",
       Onpage:true,
       extra1: "extra1",
       extra2: "extra2",
       extra3: "extra3",
       confirmation:  `${environment.server}/payments/handler/epayco/is_anonimous/${payload.donation_is_anonymous}`,
       name_billing: payload.billing_name,
       address_billing: payload.billing_address,
       type_doc_billing: payload.billing_nid_type,
       mobilephone_billing: payload.billing_phone,
       number_doc_billing: payload.billing_nid_number,
       acepted: `${environment.url}/campaing/${payload.campaign_id}/modal/payment/ACCEPTED`,
       rejected: `${environment.url}/campaing/${payload.campaign_id}/modal/payment/REJECTED`,
       pending: `${environment.url}/campaing/${payload.campaign_id}/modal/payment/PENDING`,
    }
  }

  async doCreateUser(user: IUserPayload): Promise<IUserResponse>{
    if(user.id){
      return { id: user.id};
    }
    if(user && user.email){
      const userByEmail = await this.$http.get<any>(`${environment.server}/users/by/email/${user.email}`, ).toPromise();
      if(!(userByEmail && userByEmail.error)) return userByEmail;

      const response = await this.$http.post<IUserResponse>(`${environment.server}/users/by/donation/anonymous`, user).toPromise();
      return response;
    }
    throw 'ERROR GUARDANDO USUARIO';
  }
  async doCreatePayment(payment: IPaymentPayload): Promise<IPaymentResponse>{
    console.log(' ====== payload doCreatePayment ======== ')
    console.log(payment)
    const responsePayment: IPaymentResponse = await this.$http.post<IPaymentResponse>(`${environment.server}/payments`, payment).toPromise();
    return responsePayment;
  }
  async doCreateDonation(donation: IDonationPayload): Promise<IDonationResponse>{
    const responseDonation: IDonationResponse = await this.$http.post<IDonationResponse>(`${environment.server}/payments`, donation).toPromise();
    return responseDonation;
  }
//is_anonymous: epayco_payload.donation_is_anonymous,
  async doEpayCo(epayco_payload: IEpaycoPayload){

      var handler = (window as any).ePayco.checkout.configure({
        key: this.key,
        test: this.isTest
      });
      console.log(' ')
      console.log(' ')
      console.log('  payload: IEpaycoPayload  ')
      console.log(epayco_payload)
      console.log(' ')
      console.log(' ');

      const user = await this.doCreateUser({
        id: epayco_payload.donation_user_id,
        is_anonymous: epayco_payload.donation_is_anonymous,
        name: epayco_payload.billing_name,
        email: epayco_payload.billing_email,
        phone: epayco_payload.billing_phone,
        nid_type: epayco_payload.billing_nid_type,
        nid_number: epayco_payload.billing_nid_number,
      });

      console.log(' DEBUGGER:: epayco user')
      console.log(user)

      const payment = await this.doCreatePayment({
        user_id: user.id,
        campaign_id: epayco_payload.campaign_id,
        method: 'EPAYCO',
        amount: epayco_payload.donation_amount,
        currency: epayco_payload.donation_currency,
        status: 'PENDING',
        nid_number: epayco_payload.billing_nid_number,
        email: epayco_payload.billing_email,
        name: epayco_payload.billing_name,
        is_anonymous: epayco_payload.donation_is_anonymous,
      });

      console.log(' DEBUGGER:: epayco payment')
      console.log(payment)

      const obj = await this.doCreateObject(epayco_payload);
      obj.invoice = String(payment.id);


      console.log(' DEBUGGER:: epayco obj')
      console.log(obj)



      handler.open(obj);
      
      /*
      const donation_payload = {};
      const data = await this.doCreateObject(donation_payload);
      */

      return true;



  }

}
