
<!-- 
<div class="campaign-description">
  <div class="html" [innerHTML]="description" *ngIf="!editable"></div>
  <textarea class="html" *ngIf="editable" [(ngModel)]="description" (input)="updateDescription($event)"> </textarea>
</div>
-->

<div class="campaign-description" [hidden]="!!!editable">
  <div id="campaign-description-editor-js"></div>
</div>

<div class="campaign-description" [hidden]="!!editable">
  <div id="campaign-description-readonly-js"></div>
</div>


