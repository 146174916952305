<div class="funs funs__form form__input" [ngClass]="{'error' : error }" [formGroup]="control.parent">
    <div class="input__label">
        <label>{{ label }}</label>
    </div>
    <div class="input__container">
        <input type="text" [formControl]="control" [type]="type" hidden>
        <div [id]="ID"></div>
    </div>
    <div class="input__error" *ngIf="error">
        <div *ngIf="error">
            La Descripción del proyecto debe ser un poco mas larga
        </div>
    </div>
</div>