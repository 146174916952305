
export interface IFunsLayout {
    type: string;
    cols: number[]
}

export interface IFunsOption {
    value: any;
    label: string;
}

export interface IFunsField {
    value: any;
    label: string;
    border: boolean;
    required: boolean;
    options?: IFunsOption[];
    type: 'text'| 'options' | 'textarea' | 'phone' | 'email' | 'password' | 'password';
    error: {
      check: (value: any, options?: any) => boolean,
      options?: any;
      text: string;
    }
}

export interface IFunsForm {
    title: string;
    layout: IFunsLayout;
    fields: {
        [key: string]: IFunsField
    }
}

export class FunsForm implements IFunsForm{
    
    public title: string;
    
    public layout: IFunsLayout;
    
    public fields: {
        [key: string]: IFunsField
    }
    
    constructor(){}


    getFields(): Array<{ name: string;  field: IFunsField}>{
        return Object.keys(this.fields).map( fieldName => ({
            name: fieldName,
            field: this.fields[fieldName]
        }));
    }

    setValueField(fieldName: string, fieldValue: any ){
        let value;
        if(['text', 'email', 'phone', 'password', 'textarea'].includes(this.fields[fieldName].type)) {
            value = fieldValue.target.value;
        }
        this.fields[fieldName].value = value;
    }

    getErrorCheckField(fieldName: string): boolean {
        const field = this.fields[fieldName];
        return field.error.check(field.value, {});
    }
    getErrorTextField(fieldName: string) {
        return this.fields[fieldName].error.text;
    }
    getBorderField(fieldName: string) {
        return this.fields[fieldName].border;
    }
    getLabelField(fieldName: string) {
        return this.fields[fieldName].label;
    }
    getTypeField(fieldName: string) {
        return this.fields[fieldName].type;
    }
    getCheckForm(): boolean {
        return Object.values(this.fields)
        .map( field => {
            if(field.required && field.error.check(field.value, this)){
                return field.error.text;
            } else {
                return null;
            }
        })
        .filter( errorMessage => 
            errorMessage && errorMessage.length > 0
        )
        .length === 0;
    }
}