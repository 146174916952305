<div class="page signup-page signup-page-foundation" >
  <form class="form-signup">
    <div class="form-sigin--header">
      <ui-title-auth-h1 [button]="'x'" [title]="'Regístrate v1'" (nClick)="doClose()"> </ui-title-auth-h1>
    </div>

    <div class="form-signup--fields">
      <div class="field" *ngFor="let fd of signUpForm.getFields(); let i=index ; trackBy: cmpare ">
          <ui-text-input-inline
            *ngIf="fd.field.type === 'text' || fd.field.type === 'email' || fd.field.type === 'password' || fd.field.type === 'phone'"
            (nChange)="signUpForm.setValueField(fd.name, $event)"
            [errorValue]="fd.field.error.check(fd.field.value, signUpForm)"
            [errorText]="fd.field.error.text"
            [border]="fd.field.border"
            [label]="fd.field.label"
            [type]="fd.field.type"
          ></ui-text-input-inline>
          <ui-text-area-inline
            *ngIf="fd.field.type === 'textarea'"
            (nChange)="signUpForm.setValueField(fd.name, $event)"
            [errorValue]="fd.field.error.check(fd.field.value)"
            [errorText]="fd.field.error.text"
            [border]="fd.field.border"
            [label]="fd.field.label"
            [type]="fd.field.type"
        ></ui-text-area-inline>
      </div>
    </div>

    <div class="form-signup--container" style="padding-top: 0em">
      <div class="row signup-btn">
        <div class="col-button">
          <h4 style="color: #9d9d9c; font-size: 20px; font-family: 'Poppins-Regular'">
            ¿Ya tienes una cuenta?
            <a [routerLink]="'/auth/signin'" style="color: #58b999">Iniciar sesión</a>
          </h4>
          <span style="width: 475px; font-size: 15px; color: #9d9d9c"
            >Al continuar aceptas todos nuestros <a style="color: #58b999" href="">términos y condiciones</a></span
          >
        </div>

        <div class="col-routes foundation"><button class="red" (click)="doConfirm()">REGISTRARME</button> <br /></div>
      </div>
    </div>

    <br>
    <br>

    <div class="form-signup--footer">
      <div class="row more-info">
        <h5>
          Para saber más sobre nuestras políticas y funcionamiento puedes hacer
          <a style="color: #58b999" href="">clic aqui</a>
        </h5>
      </div>
    </div>
  </form>
</div>
