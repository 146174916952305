import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByStatusInProgress',
})
export class FilterByStatusInProgressPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter((campaign) => 
    campaign.status === 'S5_IN_PAUSE' ||
    campaign.status === 'S6_IN_EXECUTION'
    ) : [];
  }
}
