import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';

@Component({
  selector: 'ui-home-call-to-actions',
  templateUrl: './home-call-to-actions.component.html',
  styleUrls: ['./home-call-to-actions.component.less'],
})
export class HomeCallToActionsComponent implements OnInit {

  public user = null;

  constructor(private router: Router, private $user: UserService) {}

  ngOnInit(): void {
    const storeUser = this.$user.getUser();
    const sessionUser: IUser = JSON.parse('' + sessionStorage.getItem('user')) as IUser; 
    const user:IUser = storeUser || (sessionUser as IUser) || null;
    this.user = user;
  }

  goToDonate(){
    this.router.navigate(['/campaigns/foundations']);
  }
  goToCampaign() {
    const user:IUser  = this.user;
    const isUserLogged = user && user.id;
    if(isUserLogged){
      const userType = user.rol;
      if(userType === 'USER'){
        this.router.navigate(['/create/campaign/type/cause']);
      } else {
        this.router.navigate(['/create/campaign/type/foundation']);
      }
    } else{
      this.router.navigate(['/create/campaign']);
    }  
  }

}
