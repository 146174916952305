import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IServerUser } from 'src/app/core/users/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(private $http: HttpClient) {}

  async doSignInWithEmailAndPassword(email: string, password: string) {
    const url = `${environment.server}/auth/signin/by/email`;
    const body = {
      email,
      password,
      env: environment.env,
      url: environment.url,
    };
    const userResponse = await this.$http.post<IServerUser[]>(url, body).toPromise();
    return userResponse;
  }

  doSignInWithFacebook() {}

  doSignInGoogle() {}

  async doRecoveryEmail(email: string) {
    const url = `${environment.server}/auth/recovery/password/by/email`;
    const body = {
      email,
      env: environment.env,
      url: environment.url,
    };
    const userResponse = await this.$http.post<IServerUser>(url, body).toPromise();
    return userResponse;
  }

  async doChangePassword(userId: string, token: string, password: string) {
    const url = `${environment.server}/auth/change/password/by/email`;
    const body = {
      userId,
      token,
      password,
    };
    const userResponse = await this.$http.post<IServerUser>(url, body).toPromise();
    return userResponse;
  }
}
