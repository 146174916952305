<div
  class="input radio-btn-input"
  [ngStyle]="{}"
  [ngClass]="{
    'color-red': color === 'red',
    'color-green': color === 'green',
    'color-gray': color === 'none'
  }"
>
  <div class="input-wrapper">
    <div class="circle"></div>
    <span>{{ text | translate }} </span>
  </div>
</div>
