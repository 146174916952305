<div class="campaign-commmets">
  <div class="campaing-comment" *ngFor="let comment of comments">
    <div class="col col-photo">
      <img *ngIf="!(comment && comment.user.photo_url)" class="profile--placeholder" src="../../../../../assets/icons/placeholder_profile.png" />
      <img *ngIf="comment && comment.user.photo_url" class="profile--real" [src]="comment.user.photo_url" />
    </div>

    <div class="col col-content">
      <div class="row row-name">
        <div *ngIf="!(comment && comment.user.name)" class="name--placeholder"></div>
        <div *ngIf="comment && comment.user.name" class="name--real">
          {{ comment.user.name }}
          <span *ngIf="comment.donation_amount > 0">{{ comment.donation_amount | currency }}</span>
        </div>
      </div>
      <div class="row row-text">
        <div *ngIf="!(comment && comment.content)" class="text--placeholder"></div>
        <div *ngIf="comment && comment.content" class="text--real">
          {{ comment.content }}
        </div>
      </div>

      <div class="row row-photos">
        <div *ngIf="!(comment && comment.photos)" class="photos--placeholder"></div>
        <div *ngIf="comment && (comment.photo_1 || comment.photo_2 || comment.photo_3 || comment.photo_4)" class="photos--real">
          <img *ngIf="comment.photo_1 && comment.photo_1 !== '-'" [src]="comment.photo_1 + comment.id" />
          <img *ngIf="comment.photo_2 && comment.photo_2 !== '-'" [src]="comment.photo_2 + comment.id" />
          <img *ngIf="comment.photo_3 && comment.photo_3 !== '-'" [src]="comment.photo_3 + comment.id" />
          <img *ngIf="comment.photo_4 && comment.photo_4 !== '-'" [src]="comment.photo_4 + comment.id" />
        </div>
      </div>

      <div class="row row-date">
        <div *ngIf="!(comment && comment.created_at)" class="date--placeholder"></div>
        <div *ngIf="comment && comment.created_at" class="date--real">
          Hace {{ (comment.created_at | dateDiffTime).time | number: '1.0-0' }}
          {{ (comment.created_at | dateDiffTime).label }}
        </div>
      </div>

      <div class="row row-response">
        <div *ngIf="!(comment && comment.response)" class="response--placeholder"></div>
        <div *ngIf="comment && comment.response" class="response--real"></div>
      </div>
    </div>
  </div>
</div>
