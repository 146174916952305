import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private _results: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private results: Observable<any[]> = this._results.asObservable();

  constructor(private $http: HttpClient) {}

  async onKeywordChange(keyword: string): Promise<any> {
    const result: any[] = (await this.$http.post(`${environment.server}/campaigns/search/by/keyword/`, { keyword }).toPromise()) as any[];
    const results = result.map((item) => {
      return {
        src: item.photo_1,
        url: '/campaing/' + item.id,
        description: item.title,
      };
    });

    this.setResults(results);
    console.log(' ');
    console.log(' results ');
    console.log(results);
    console.log(' ');
    return results;
  }

  setResults(results: any[]) {
    this._results.next(results);
  }

  getResults(): any[] {
    return this._results.getValue();
  }

  syncResults(): Observable<any[]> {
    return this.results;
  }
}
