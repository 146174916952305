import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-place-department-input',
  templateUrl: './place-department-input.component.html',
  styleUrls: ['./place-department-input.component.less']
})
export class PlaceDepartmentInputComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
