<div class="profile-page--main">
  <!-- <div class="main--tabs-header">
    <h1 [ngClass]="{ active: tab === 'admin_campaigns' }" (click)="setTab('admin_campaigns')">CAMPAÑAS</h1>
    <h1 [ngClass]="{ active: tab === 'admin_settings' }" (click)="setTab('admin_settings')">CONFIGURACIÓN</h1>
  </div> -->

  <div class="main--tabs-content">
    <div class="tab" *ngIf="tab === 'admin_campaigns'">
      <br />
      <br />
      <ngx-table class="table" [configuration]="configuration" [data]="data" [columns]="columns"
        (event)="onEvent($event)">
        <ng-template class="tabla" let-row>


          <!-- COLUMN::ID -->
          <td class="col__id">
            <div class="col__id-r1">
              <div class="container__id">
                <span>{{ row?.id }}</span>
              </div>
              <div class="container__icon" *ngIf="false">
                <button class="btn btn-primary btn-ic" (click)="showModal(row)">
                  <img src="../../../../../assets/icons/ojo.png">
                </button>
              </div>
              <div class="container__link">
                <button class="btn btn-primary btn-ic" (click)="showLink(row)">
                  <img src="../../../../../assets/icons/link.png">
                </button>
              </div>
            </div>
            <div class="container__home">
              <img src="../../../../../assets/icons/home_gray.png">
              <p-inputSwitch [(ngModel)]="row.isInCover" (onChange)="onChangeIsInCover($event)"></p-inputSwitch>
            </div>
            <div class="container__favorites">
              <img src="../../../../../assets/icons/star_gray.png">
              <p-inputSwitch [(ngModel)]="row.isInOutstanding" (onChange)="onChangeIsInOutstanding($event)"></p-inputSwitch>
            </div>
            <div class="container__save">
              <button (click)="saveCamapignVisibility(row)">
                <img src="../../../../../assets/icons/diskette_gray.png" *ngIf="!row.isSaving">
                <img src="../../../../../assets/icons/loading_gif.gif" *ngIf="!!row.isSaving">
              </button>
            </div>
          </td>


          <td>
            <div class="font-title" style="text-align: left; font-family: Poppins-Regular; font-size: 16px;">{{
              row.title | truncateText: 40}}</div>
            <div class="font-small">
              <img style="width: 150px; object-fit: contain; object-position: center; border-radius: 1em"
                [src]="row.photo" />
            </div>
          </td>
          <td>
            <span class="font-small">{{ row?.company}} <br>
              <h1>Responsable:{{ row?.organizer }}</h1>
            </span>
          </td>
          <td>
            <span class="font-small">{{ formatCategory(row?.category) | translate }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.city }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.amount | currency: 'USD':true:'2.2-4' }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.date_start }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.date_end }}</span>
          </td>
          <td>

            <h6 class="status" *ngIf="row.status">
              <br>
              <select (change)="setStatus($event,row?.id)" [disabled]="isStatusL1Disabled(row?.status)">
                <option *ngFor="let status of StatusL1"
                  [ngStyle]="{ 'color' :  (isStatusDisabled(row?.status, status) ? 'red' : 'black')  }"
                  [value]="status.value" [disabled]="isStatusDisabled(row?.status, status)"
                  [selected]="row?.status === status.value">
                  {{ status.value | campaignStatusLabel }}
                </option>
              </select>
            </h6>

            <h6 class="status" *ngIf="row.status">
              <select (change)="setStatus($event,row?.id)" [disabled]="isStatusL2Disabled(row?.status)">
                <option selected *ngIf="isStatusL2Disabled(row?.status)"></option>
                <ng-container *ngIf="!isStatusL2Disabled(row?.status)">
                  <option *ngFor="let status of StatusL2"
                    [ngStyle]="{ 'color' :  (isStatusDisabled(row?.status, status) ? 'red' : 'black')  }"
                    [value]="status.value" [disabled]="isStatusDisabled(row?.status, status)"
                    [selected]="row?.status === status.value">
                    {{ status.value | campaignStatusLabel }}
                  </option>
                </ng-container>
              </select>
            </h6>

            <span class="font-small">
              <h2>Detalle</h2>
            </span>
            <div class="actions-row">
              <button class="btn btn-primary btn-ic" (click)="deleteCampaign(row)">
                <img class="trash" src="../../../../../assets/icons/trash.png">
              </button>
            </div>
          </td>
        </ng-template>
      </ngx-table>

      <div *ngIf="modal && campaign" class="modal active">
        <div class="container">
          <div class="row x2">
            <div class="col">
              <img class="picture-cover" [src]="campaign.photos[0]" />
            </div>
            <div class="col">
              <div class="row">
                <h1 class="title">{{ campaign.title }}</h1>
              </div>
              <div class="row profile-info">
                <div class="col profile" style="width: 5em">
                  <img class="picture-profile" [src]="campaign.organizer.photo_url" />
                </div>
                <div class="col info" style="width: calc(100% - 5em)">
                  <h1 class="company">{{ campaign.company }}</h1>
                  <h2 class="organizer">{{ campaign.organizer.name }}</h2>
                  <h2 class="organizer">{{ campaign.place.city }}</h2>
                </div>
              </div>
              <br />

              <div class="row status">
                <h3 class="status">
                  Categoria:
                  <select (change)="setStatus($event)">
                    <option [disabled]="campaign.status === 'PENDING'">PENDING</option>
                    <option [disabled]="campaign.status === 'COLLECTING'">COLLECTING</option>
                    <option [disabled]="campaign.status === 'OPEN' || campaign.status === 'IN_PROGRESS'">IN PROGRESS /
                      OPEN</option>
                    <option [disabled]="campaign.status === 'DONE'">DONE</option>
                    <option [disabled]="campaign.status === 'AWARD'">AWARD</option>
                    <option [disabled]="campaign.status === 'ARCHIVED'">ARCHIVED</option>
                  </select>
                </h3>
              </div>
            </div>
          </div>

          <div>
            <div class="row description">
              <p style="box-sizing: border-box; width: 100%; padding: 1em; line-height: 1.2em">
                {{ campaign.description }}
              </p>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-primary btn-sm" (click)="hideModal()">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tab === 'admin_settings'">
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <ui-admin-home-settings-form></ui-admin-home-settings-form>
          </div>
          <div class="col-sm">
            <ui-admin-home-settings-form></ui-admin-home-settings-form>
          </div>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tab === 'admin_in_pending'">
      <br />
      <br />

      <ngx-table [configuration]="configuration" [data]="data" [columns]="columns" (event)="onEvent($event)">
        <ng-template let-row>
          <td style="width: 10px">
            <div class="font-small">{{ row.id }}</div>
          </td>
          <td>
            <div class="font-small"><img
                style="width: 100%; object-fit: contain; object-position: center; border-radius: 1em"
                [src]="row.photo" /></div>
          </td>
          <td>
            <div class="font-small">{{ row.title }}</div>
          </td>
          <td>
            <span class="font-small">{{ row.organizer }}</span>
          </td>
          <td>
            <span class="font-small">{{ formatCategory(row.category) | translate }}</span>
          </td>
          <td>
            <span class="font-small">{{ row.city }}</span>
          </td>
          <td>
            <span class="font-small">{{ row.amount | currency: 'USD':true:'2.2-4' }}</span>
          </td>
          <td>
            <span class="font-small">{{ row.date_start }}</span>
          </td>
          <td>
            <span class="font-small">{{ row.date_end }}</span>
          </td>
          <td>
            <span class="font-small">{{ formatStatus(row.status) | translate }}</span>
          </td>
          <td>
            <div class="actions-row">
              <button class="btn btn-primary btn-sm" (click)="showModal(row)">
                <i class="fas fa-eye"></i>
              </button>
              <button class="btn btn-primary btn-sm" (click)="goToCampaing(row)">
                <i class="fas fa-link"></i>
              </button>
              <button class="btn btn-primary btn-sm" (click)="sendMessage(row)">
                <i class="fas fa-envelope"></i>
              </button>
            </div>
          </td>
        </ng-template>
      </ngx-table>

      <div *ngIf="modal" class="modal active">
        <div class="container">
          <div class="row x2">
            <div class="col">
              <img class="picture-cover" [src]="campaign.photos[0]" />
            </div>
            <div class="col">
              <div class="row">
                <h1 class="title">{{ campaign.title }}</h1>
              </div>
              <div class="row profile-info">
                <div class="col profile" style="width: 5em">
                  <img class="picture-profile" [src]="campaign.organizer.photo_url" />
                </div>
                <div class="col info" style="width: calc(100% - 5em)">
                  <h1 class="company">{{ campaign.company }}</h1>
                  <h2 class="organizer">{{ campaign.organizer.name }}</h2>
                  <h2 class="organizer">{{ campaign.place.city }}</h2>
                </div>
              </div>
              <br />

              <div class="row status">
                <h3 class="status">
                  Categoria:
                  <select (change)="setStatus($event)">
                    <option [disabled]="campaign.status === 'PENDING'">PENDING</option>
                    <option [disabled]="campaign.status === 'COLLECTING'">COLLECTING</option>
                    <option [disabled]="campaign.status === 'OPEN' || campaign.status === 'IN_PROGRESS'">IN PROGRESS /
                      OPEN</option>
                    <option [disabled]="campaign.status === 'DONE'">DONE</option>
                    <option [disabled]="campaign.status === 'AWARD'">AWARD</option>
                    <option [disabled]="campaign.status === 'ARCHIVED'">ARCHIVED</option>
                  </select>
                </h3>
              </div>
            </div>
          </div>

          <div>
            <div class="row description">
              <p style="box-sizing: border-box; width: 100%; padding: 1em; line-height: 1.2em">
                {{ campaign.description }}
              </p>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-primary btn-sm" (click)="hideModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="tab" *ngIf="tab === 'admin_in_collecting'">
      <ngx-table [configuration]="configuration" [data]="getCampaingsAsTable() | filterByStatusInCollection"
        [columns]="columns"> </ngx-table>
    </div>
    <div class="tab" *ngIf="tab === 'admin_in_progress'">
      <ngx-table [configuration]="configuration" [data]="getCampaingsAsTable() | filterByStatusInProgress"
        [columns]="columns"> </ngx-table>
    </div>
    <div class="tab" *ngIf="tab === 'admin_in_closed'">
      <ngx-table [configuration]="configuration" [data]="getCampaingsAsTable() | filterByStatusFinished"
        [columns]="columns"> </ngx-table>
    </div>
  </div>
</div>