<button class="button epayco-btn"  [ngClass]="{ selected: selected }" [disabled]="disabled">
  <ui-solid-btn
    [color]="'red'"
    style="width: 100%; padding-bottom: 1em; padding-top: 1em"
    [minWidth]="'100%'"
    [margins]="'0em'"
    [paddings]="'1.25em 2em'"
    [text]="'DONAR AHORA'"
    type="submit"
    (nClick)="onSubmit()"
  ></ui-solid-btn>
</button>
<img style="display: block; margin: 0 auto;"src="../../../../../assets/icons/btn-epayco.png">


  
  <ng-progress #progressBar [color]="'#58b999'" [thick]="false"></ng-progress>