import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterByPlacePipe',
})
export class FilterByPlacePipe implements PipeTransform {
  transform(values: any[], placeFilter: { country: string; state: string; city:string}): any[] {
    let  valus = [];
    console.log('  placeFilter ')
    console.log(placeFilter)
    if (placeFilter) {
        valus = values;
        if(placeFilter.country){
          valus = values.filter(value => JSON.stringify(value).toLocaleLowerCase().includes(placeFilter.country.toLocaleLowerCase()));
          return JSON.parse('' + JSON.stringify(valus));
        } else {
          return JSON.parse('' + JSON.stringify(values));
        }
    } else {
      return JSON.parse('' + JSON.stringify(values));
    }
  }
}
