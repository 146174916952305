<div class="filter filter-type" style="padding-top: 1em">
  <h1 style="padding-bottom: 0.5em">Tipo</h1>
  <div class="inputs">
    <input type="radio" id="input-type-cause" [(ngModel)]="realm" name="realm" value="cause" (change)="handleChange($event)" />
    <label for="input-type-cause">Causa</label>
    <br />
    <input type="radio" id="input-type-project" [(ngModel)]="realm" name="realm" value="project" (change)="handleChange($event)" />
    <label for="input-type-project">Proyecto</label>
    <br />
    <input type="radio" id="input-type-both" [(ngModel)]="realm" name="realm" value="both" (change)="handleChange($event)" />
    <label for="input-type-both">Ambos</label>
  </div>
</div>
