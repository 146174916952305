import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-paypal-btn',
  templateUrl: './paypal-btn.component.html',
  styleUrls: ['./paypal-btn.component.less'],
})
export class PaypalBtnComponent implements OnInit {
  constructor() {}

  @Output()
  nSelect = new EventEmitter();

  @Input()
  selected = false;

  ngOnInit(): void {}

  public onSubmit() {}
  public doSelectPayPal(): void {
    this.nSelect.emit();
  }
}
