import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-solid-btn',
  templateUrl: './solid-btn.component.html',
  styleUrls: ['./solid-btn.component.less'],
})
export class SolidBtnComponent implements OnInit {
  @Input()
  text: string;
  @Input()
  disabled: boolean;

  @Input()
  minWidth: string;

  @Input()
  color: string;

  @Input()
  margins: string;

  @Input()
  paddings: string;

  @Output()
  nClick = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.nClick.emit(true);
  }
}
