import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-legal-page',
  templateUrl: './legal-page.component.html',
  styleUrls: ['./legal-page.component.less'],
})
export class LegalPageComponent implements OnInit {
  constructor() {}

  public docs = [
    {
      text: 'Contamos con un informe anual de resultados o Informe de gestión para el Sector Cooperativo que provee datos sobre sus proyectos en curso y los finalizados, los ingresos, los contratos realizados, subsidios y aportes recibidos, así como las metas logradas en beneficio de la comunidad.',
      link: '',
    },
    {
      text: 'Los estados financieros de la entidad.',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/2.+Estados+Financieros.pdf',
    },
    {
      text: 'Certificación de Requisitos del representante legal o revisor fiscal en el que evidencia que han cumplido con todos los requisitos durante el respectivo año del respectivo año. (Numeral 13 del parágrafo 2 del artículo 364-5 E.T.)',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/2.+Estados+Financieros.pdf',
    },
    {
      text: 'Copia de la escritura pública, documento privado o acta de constitución en donde conste que está legalmente constituida.',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/4.+Acta+de+constituci%C3%B3n.pdf',
    },
    {
      text: 'Certificado de existencia y representación legal expedido por la autoridad competente, en el cual figuren sus administradores, representantes y miembros del consejo u órgano directivo cuando hubiere lugar a ello. ',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/5.+Certificado+de+existencia+Representaci%C3%B3n+Legal.pdf',
    },
    {
      text: 'Copia del acta de la Asamblea General o máximo órgano de dirección, en la que se autorice al representante legal para que solicite que la entidad permanezca como entidad del Régimen Tributario Especial del Impuesto sobre la Renta. ',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/6.+Acta+unificada.pdf',
    },
    // 7
    {
      text: 'Copia de los estatutos de la entidad o acta del máximo órgano directivo donde se indique que el objeto social principal corresponde a una actividad meritoria, que los aportes no son reembolsados, que sus excedentes no son distribuidos y que se identifique los cargos directivos de la entidad. (Numeral 4 del Art. 1.2.1.5.1.8 del Decreto 1625 del 2016). ',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/7.+Estatutos.pdf',
    },
    // 8
    {
      text: 'Certificación del Representante Legal de los antecedentes judiciales y declaración de caducidad de contratos estatales de los miembros de la junta directiva, fundadores, representantes legales o miembros de los órganos de dirección de conformidad con el numeral 3 artículo 364-3 E.T. ',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/8.+Certificaci%C3%B3n+antecedentes+judiciales.pdf',
    },
    // 9
    {
      text: 'Acta de Asamblea General o máximo órgano de dirección que indica el estado de las asignaciones permanentes de los años gravables anteriores de conformidad con el artículo 1.2.1.5.1.27 del Decreto 1625 de 2026, si hay lugar a ello.',
      link: '',
    },
    // 10
    {
      text: 'Acta de Asamblea General o máximo órgano de dirección en el que se aprobó la distribución del beneficio neto o excedente del respectivo año gravable, si hay lugar a ello',
      link: '',
    },
    // 11
    {
      text: ' Registrar la Memoria Económica cuando los ingresos brutos, incluidas las ganancias ocasionales, del año gravable 2019 fueron iguales o superiores a 160.000 UVT ($5.483.200.000.) ',
      link: '',
    },
    // 12
    {
      text: 'Formatos 2530, 2531, 2532 XML',
      link: '',
    },
    // 13
    {
      text: 'Declaración de renta',
      link: 'https://ng-docs.s3.us-east-2.amazonaws.com/13.+Declaraci%C3%B3n+de+renta.pdf',
    },
    // 14
    {
      text: 'Certificación remuneración directivos',
      link: '',
    },
  ];

  ngOnInit(): void {}
}
