<div class="funs funs__form form__input" [ngClass]="{'error' : error, 'focus' : focus}" [formGroup]="control.parent">
    <div class="input__label">
        <label>{{ label }}</label>
    </div>
    <div class="input__container">
        <input type="text" [formControl]="control" [type]="type" (focus)="onFocus()" (blur)="onBlur()">
    </div>
    <div class="input__error" *ngIf="false">
        <div *ngIf="control.errors?.required">
            {{ label }} es necesario
        </div>
    </div>
</div>
