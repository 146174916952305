import Swal from 'sweetalert2';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SignupService } from '../../../../app/auth/services/signup.service';

import { signUpForm } from '../../../auth/forms/signup.company.form';

@Component({
  selector: 'ui-signup-foundation',
  templateUrl: './signup-foundation.component.html',
  styleUrls: ['./signup-foundation.component.less'],
})
export class SignupFoundationComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private signupService: SignupService) { }

  public nameError = '';
  public nitError = '';
  public emailError = '';
  public phoneError = '';
  public passwordError = '';
  public authError = '';

  // public type = 'user'; // user || company
  public user: any = {};
  public confirm = false;

  public signUpForm = signUpForm;

  cmpare(index) {
    return index;
  }

  doClose() { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      // this.type = params.get('type');
    });
  }

  setName($event) {
    this.user.name = $event.target.value;
    if (!this.user.name) {
      this.nameError = 'Nombre Invalido';
    } else {
      this.nameError = '';
    }
  }
  setNIT($event) {
    this.user.nit = $event.target.value;
    if (!this.user.nit) {
      this.nitError = 'NIT Invalido';
    } else {
      this.nitError = '';
    }
  }
  setEmail($event) {
    this.user.email = $event.target.value;
    if (!this.user.email) {
      this.emailError = 'Correo Invalido';
    } else {
      this.emailError = '';
    }
  }
  setPhone($event) {
    this.user.phone = $event.target.value;
    if (!this.user.phone && this.user.phone.length < 10) {
      this.phoneError = 'Telefono Invalido';
    } else {
      this.phoneError = '';
    }
  }
  setMobile($event) {
    this.user.mobile = $event.target.value;
    if (!this.user.mobile && this.user.mobile.length < 10) {
      this.phoneError = 'Celular Invalido';
    } else {
      this.phoneError = '';
    }
  }
  setPassword($event) {
    this.user.password = $event.target.value;
    if (!this.user.password) {
      this.passwordError = 'Contraseña Invalida';
    } else {
      this.passwordError = '';
    }
  }

  async doConfirm() {
    this.doSignUpAsCompany();
  }
  async doSignUpAsCompany() {
    try {
      const user: any = {};
      user.name = this.signUpForm.fields['company'].value;
      user.email =  this.signUpForm.fields['email'].value;
      user.password = this.signUpForm.fields['password1'].value;
      user.phone = this.signUpForm.fields['phone'].value;
      user.mobile = this.signUpForm.fields['mobile'].value;
      user.nid_number = this.signUpForm.fields['nit'].value;
      user.nid_type = 'NIT';
      user.company = user.name;
      user.position = 'Fundación';
      user.city = '';
      user.address = '';
      user.rol = 'FOUNDATION';
      user.description = this.signUpForm.fields['description'].value;
      if(this.signUpForm.getCheckForm()){
        const userResponse: any = await this.signupService.doSignUpWithEmailAndPassword(user);
        const userId = userResponse.id;
        this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
      }
    } catch (error) {
      Swal.fire('Error',error,'error');
    }
  }

  onClickedOutside($event) {
    this.confirm = false;
  }
}
