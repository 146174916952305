import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'ui-ng-form-input-single-check',
  templateUrl: './ng-form-input-single-check.component.html',
  styleUrls: ['./ng-form-input-single-check.component.less']
})
export class NgFormInputSingleCheckComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() type: string;

  get error(): boolean{
    if(this.control){
      return this.control.invalid && (this.control.dirty || this.control.touched)
    }
    return false;
  }
}
