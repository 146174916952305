<div class="profile-page--main">
  <div class="main--tabs-header">
    <h1 [ngClass]="{ active: tab === 'my_following_donations' }" (click)="setTab('my_following_donations')">CAMPAÑAS QUE SIGO</h1>
    <h1 [ngClass]="{ active: tab === 'my_donations' }" (click)="setTab('my_donations')">MIS DONACIÓNES</h1>
    <h1 [ngClass]="{ active: tab === 'my_campaigns' }" (click)="setTab('my_campaigns')">MIS CAMPAÑAS</h1>
  </div>

  <div class="main--tabs-content">
    <div class="tab" *ngIf="tab === 'my_following_donations'">
      <div class="campaigns-list">
        <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsLiked">
          <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
        </div>

        <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
          <ui-card-empty [card_type]="'like_tutorial_step_1'"></ui-card-empty>
        </div>
        <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
          <ui-card-empty [card_type]="'like_tutorial_step_2'"></ui-card-empty>
        </div>
        <div class="campaing-card" *ngIf="(campaigns | filterByIsLiked)?.length < 1">
          <ui-card-empty [card_type]="'like_tutorial_step_3'"></ui-card-empty>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tab === 'my_donations'">
      <!-- CASE 0 :: NONE -->
      <div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length === 0">
        <div class="campaing-card">
          <ui-card-empty [card_type]="'donate'"></ui-card-empty>
        </div>
        <div class="campaing-card">
          <ui-card-empty [card_type]="'donate'"></ui-card-empty>
        </div>
        <div class="campaing-card">
          <ui-card-empty [card_type]="'donate'"></ui-card-empty>
        </div>
      </div>

      <!-- CASE 1 :: FULL MULTIPLOS DE 3,6,9,12 -->
      <div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length % 3 == 0">
        <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsDonated">
          <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
        </div>
      </div>

      <!-- CASE 2 :: Toca Rellenar -->
      <div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length > 0 && (campaigns | filterByIsDonated)?.length % 3 != 0">
        <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsDonated">
          <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
        </div>
        <div class="campaing-card" *ngFor="let campaign of [].constructor(getHowManyCardsToFill((campaigns | filterByIsDonated).length, 3))">
          <ui-card-empty [card_type]="'donate'"></ui-card-empty>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tab === 'my_campaigns'">

      <ui-campaign-profile-tabs [campaigns]="(campaingsProfile$ | async)"></ui-campaign-profile-tabs>



    </div>
  </div>
</div>

