<div class="page profile-page">
  <div class="profile-page--container">
    <div class="profile-page--header">
      <div class="header--col">
        <img class="profile--avatar" *ngIf="user" [src]="user?.photo_url" />
        <div class="profile--titles-info">
          <div *ngIf="user?.company" class="user-type-company">
            <h1>{{ user?.company }}</h1>
            <h2>Responsable: {{ user?.name }}</h2>
            <h3>{{ user?.email }}</h3>
          </div>

          <div *ngIf="!user?.company" class="user-type-regular">
            <h1>{{ user?.name }}</h1>
            <h2>{{ user?.email }}</h2>
            <h3 *ngIf="user?.position">{{ user?.position }}</h3>
          </div>
        </div>
        <div class="profile--titles-stats">
          <br />
          <h4>
            <strong>{{ campaignsDonatedNumber }}</strong> donativos
          </h4>
          <h4>
            <strong>{{ campaignsReceivedNumber }}</strong> recaudados
          </h4>
        </div>
      </div>
      <div class="header--col">
        <div class="header--row">
          <ul class="header-nav-menu">
            <li [routerLink]="''">Cuéntanos tu experiencia</li>
            <li [routerLink]="''">Cómo funciona</li>
            <li [routerLink]="''">Configuración</li>
            <li [routerLinkActive]="['is-active']" [routerLink]="'/profile2/'+user?.id+'/campaigns/liked'">Usuario</li>
            <li *ngIf="user.rol === 'ADMIN'" [routerLinkActive]="['is-active']" [routerLink]="'/admin/'+user?.id+'/campaigns'">Admin</li>
          </ul>
        </div>
        <div class="header--row">
          <ui-solid-btn
            [color]="'red'"
            [minWidth]="'200px'"
            [margins]="'0em 1em 0em 0em'"
            [paddings]="'0.5em 1em'"
            [text]="'campaigns.donate'"
            (nClick)="goCampaigns()"
          ></ui-solid-btn>
          <ui-solid-btn
            [color]="'green'"
            [minWidth]="'200px'"
            [margins]="'0em'"
            [paddings]="'0.5em 1em'"
            [text]="'INICIAR CAMPAÑA'"
            (nClick)="goStartCampaign()"
          ></ui-solid-btn>
        </div>
      </div>
    </div>


    <div class="profile-page--sidebar-and-main">
      <!-- 
      <ui-profile-filters [mode]="mode" (nChange)="setFilter($event)"></ui-profile-filters>
      -->
      <ui-profile-tabs-user *ngIf="!(isAdmin$ | async)" [filter]="filter" [campaigns]="(campaingsProfile$ | async) || []" [user]="user"></ui-profile-tabs-user>
      <ui-profile-tabs-admin *ngIf="(isAdmin$ | async)" [filter]="filter" [campaigns]="(campaigns$ | async) || []"></ui-profile-tabs-admin>
    </div>
  </div>

  <section class="profile-page--outstandings-campaigns">
    <div class="section--wrapper">
      <h1 class="section-title">CAMPAÑAS DESTACADAS</h1>
      <ui-home-outstandings-campaigns [campaigns]="campaingsCover$ | async"></ui-home-outstandings-campaigns>
      <br />
    </div>
  </section>
</div>
