import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { Observable, Subscription } from 'rxjs';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';

@Component({
  selector: 'ui-profile2-campaigns-donated-view',
  templateUrl: './profile2-campaigns-donated-view.component.html',
  styleUrls: ['./profile2-campaigns-donated-view.component.less']
})
export class Profile2CampaignsDonatedViewComponent implements OnInit, OnDestroy{
  
  public campaigns: ICampaign[] = [];
  public campaigns$: Observable<ICampaign[]>;

  public subs: Subscription = new Subscription();

  constructor(private $campaigns: CampaignsService){
    this.campaigns$ = this.$campaigns.syncProfileCampaigns();
  }

  public ngOnInit(){
    const subsCampaigns = this.campaigns$.subscribe((campaigns) => this.campaigns = campaigns)
    this.subs.add(subsCampaigns)
  }

  public ngOnDestroy(){
    this.subs.unsubscribe();
  }

  getHowManyCardsToFill(size: number, col: number) {
    if (size >= col) {
      return size - Math.floor(size / col) * col;
    } else {
      return col - (size - Math.floor(size / col) * col);
    }
  }

}
