import { Pipe, PipeTransform } from '@angular/core';
import { IStatusOption, IStatusValue } from './../../core/campaigns/models/status.model';

const IStatusTag_To_Label_Dic: { [key in IStatusValue]: string } = {
  'S0_IN_PROCESS': 'EN PROCESO',
  'S1_IN_PENDING': 'PENDIENTE',
  'S2_IN_CORRECTING': 'EN CORRECIÓN',
  'S3_PUBLISHED': 'PUBLICADO',
  'S4_IN_COLLECTING': 'EN RECAUDO',
  'S5_IN_PAUSE': 'EN PAUSA',
  'S6_IN_EXECUTION': 'EN EJECUCIÓN',
  'S7_CANCELLED': 'CANCELADO',
  'S8_FINISHED': 'FINALIZADO',
  'S9_EXPIRED': 'EXPIRADO',
}

@Pipe({
  name: 'campaignStatusLabel',
})
export class CampaignStatusLabelPipe implements PipeTransform {
  transform(state: string): string {
    return IStatusTag_To_Label_Dic[state];
  }
}
