<div class="footer" *ngIf="show">
  <div class="wrapper">
    <div class="row row-layout--to-top">
      <div class="col col-founds">
        <h3 class="footer-title">{{ 'footer.raiseFoundsFor' | translate }}</h3>
        <ul class="footer-items">
          <li class="footer-item" (click)="goToFoundations()">{{ 'footer.kids' | translate }}</li>
          <li class="footer-item" (click)="goToFoundations()">{{ 'footer.young' | translate }}</li>
          <li class="footer-item" (click)="goToFoundations()">{{ 'footer.elder' | translate }}</li>
          <li class="footer-item" (click)="goToFoundations()">{{ 'footer.animals' | translate }}</li>
        </ul>
      </div>
      
      <div class="col col-community">
        <h3 class="footer-title">{{ 'footer.community' | translate }}</h3>
        <ul class="footer-items">
          <li class="footer-item" (click)="goToFoundations()">{{ 'footer.foundations' | translate }}</li>
          <li class="footer-item" (click)="goToCauses()">{{ 'footer.causes' | translate }}</li>
          <!-- <li class="footer-item">{{ 'footer.allies' | translate }}</li>
          <li class="footer-item">{{ 'footer.zones' | translate }}</li>
          <li class="footer-item">{{ 'footer.blog' | translate }}</li> -->
        </ul>
      </div>
      <div class="col col-about">
        <h3 class="footer-title">{{ 'footer.aboutUs' | translate }}</h3>
        <ul class="footer-items">
          <li class="footer-item" (click)="goToAboutUs()">
            {{ 'footer.who-are-we' | translate }}
          </li>
          <li class="footer-item" (click)="goToAboutWork()">
            {{ 'footer.how-we-work' | translate }}
          </li>
          <li class="footer-item" (click)="goToPolitics()">
            {{ 'footer.privacy-policies' | translate }}
          </li>
          <li class="footer-item" (click)="goToTerms()">
            {{ 'footer.terms' | translate }}
          </li>
          <li class="footer-item" (click)="goToRequiriments()">
            {{ 'footer.requeriments' | translate }}
          </li>
        </ul>
      </div>
      <div class="col col-social">
        <ul class="footer-social-icons">
          <li class="footer-social-icon">
            <a href="https://www.facebook.com/funs.com.co">
              <img src="assets/icons/icon-fb.png" alt="Facebook"/>
            </a>
            
          </li>
          <li class="footer-social-icon">
            <a href="https://www.instagram.com/funssoliun?igsh=dXc5ODRvY2wyeWly">
              <img src="assets/icons/icon-ig.png" alt="Instagram" />
            </a>
            
          </li>
          <li class="footer-social-icon">
            <a href="https://twitter.com/Funs_Soliun">
              <img src="assets/icons/icon-x.png" alt="X - Antes Twitter"/>
            </a>
            
          </li>
        </ul>
      </div>
    </div>
    <div class="row row-footer-2">
      <div class="col-logo">
        <img src="assets/icons/main-logo-green.png" />
      </div>
      <div class="col-contact">
        <h5 class="contact-phone">Teléfonos: <strong>311 521 6216</strong> / (601) 765 3016.
          / contacto@funsoliun.com</h5>
        <h5 class="contact-address">Carrera 70 C # 48-55, Bogotá D.C. / NIT: 901310814-7</h5>
      </div>
      <div class="col-language">
        <div class="wrapper">
          <ui-footer-language></ui-footer-language>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-copyright">
      <h1>© 2020 {{ 'footer.copyright' | translate }}</h1>
    </div>
  </div>
</div>
