<!-- ----------------------------------- -->
<!-- --- home-page purposes --------------- -->
<!-- ----------------------------------- -->
<div class="home-page purposes">
  <h1 class="purposes--title">TENEMOS 3 PROPÓSITOS</h1>

  <div class="purposes--row x3">
    <div class="purposes--col">
      <img src="../../../../../assets/icons/purposes-p1.png" />
      <p class="purposes--p">
        Gestionar los recursos técnicos y financieros a nivel interno y externo para lograr la sostenibilidad operativa y financiera de la Fundación y sus
        afiliadas.
      </p>
      <a [routerLink]="'/about/work'" >
        VER MÁS
      </a>
    </div>
    <img src="../../../../../assets/icons/separator1.png" width="1px" />
    <div class="purposes--col">
      <img src="../../../../../assets/icons/purposes-p2.png" />
      <p class="purposes--p">
        Identificar fundaciones legalmente constituidas con el objetivo de realizar un proceso de articulación y brindar atención a personas para así
        visibilizar las personas y fundaciones que contribuyen a cumplir nuestra misión.
      </p>
    </div>
    <img src="../../../../../assets/icons/separator1.png" width="1px" />
    <div class="purposes--col">
      <img src="../../../../../assets/icons/purposes-p3.png" />
      <p class="purposes--p">
        Atender personas en condición de vulnerabilidad o pobreza, que presenten situaciones de emergencias sociales que no cuenten con la capacidad para
        enfrentarlas y aportar a la estabilidad de su situación.
      </p>
    </div>
  </div>
</div>
