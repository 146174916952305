import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { Observable, Subscription } from 'rxjs';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';

@Component({
  selector: 'ui-profile2-campaigns-liked-view',
  templateUrl: './profile2-campaigns-liked-view.component.html',
  styleUrls: ['./profile2-campaigns-liked-view.component.less']
})
export class Profile2CampaignsLikedViewComponent implements OnInit, OnDestroy{
  
  public campaigns: ICampaign[] = [];
  public campaigns$: Observable<ICampaign[]>;

  public subs: Subscription = new Subscription();

  constructor(private $campaigns: CampaignsService){
    this.campaigns$ = this.$campaigns.syncProfileCampaigns();
  }

  public ngOnInit(){
    const subsCampaigns = this.campaigns$.subscribe((campaigns) => this.campaigns = campaigns)
    this.subs.add(subsCampaigns)
  }

  public ngOnDestroy(){
    this.subs.unsubscribe();
  }

  getCampaignTheme(campaign: ICampaign){
    return campaign.status
  }

  getHowManyCardsToFill(size: number, col: number) {
    if (size >= col) {
      return size - Math.floor(size / col) * col;
    } else {
      return col - (size - Math.floor(size / col) * col);
    }
  }
}


/*

 {
      value: 'S0_IN_PROCESS',
      blockers: [],
    },
    {
      value: 'S1_IN_PENDING',
      blockers: ['S3_PUBLISHED'],
    },
    {
      value: 'S2_IN_CORRECTING',
      blockers: [],
    },
    {
      value: 'S3_PUBLISHED',
      blockers: ['S0_IN_PROCESS'],
    },
    {
      value: 'S4_IN_COLLECTING',
      blockers: ['S0_IN_PROCESS', 'S1_IN_PENDING', 'S2_IN_CORRECTING'],
    },
    {
      value: 'S5_IN_PAUSE',
      blockers: ['S0_IN_PROCESS', 'S1_IN_PENDING', 'S2_IN_CORRECTING'],
    },
    {
      value: 'S6_IN_EXECUTION',
      blockers: ['S0_IN_PROCESS', 'S1_IN_PENDING', 'S2_IN_CORRECTING'],
    },
    {
      value: 'S7_CANCELLED',
      blockers: ['S0_IN_PROCESS', 'S1_IN_PENDING', 'S2_IN_CORRECTING'],
    },
    {
      value: 'S8_FINISHED',
      blockers: ['S0_IN_PROCESS', 'S1_IN_PENDING', 'S2_IN_CORRECTING'],
    },

    */
