import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IUser } from 'src/app/core/users/models/user.model';

@Component({
  selector: 'ui-profile-tabs-user',
  templateUrl: './profile-tabs-user.component.html',
  styleUrls: ['./profile-tabs-user.component.less'],
})
export class ProfileTabsUserComponent implements OnInit {

  @Input()
  user: IUser;

  @Input()
  campaigns: ICampaign[];

  @Input()
  filter: any;

  public tab = 'my_following_donations';
  public subtab = 'my_campaigns_recaudo';
  public campaingsProfile$;

  constructor(private $campaigns: CampaignsService) {
    this.campaingsProfile$ = this.$campaigns.syncProfileCampaigns();
  }

  async ngOnInit() {
    const userId = this.user.id;
    const myProfileCampaigns = await this.$campaigns.fetchMyProfileCampaigns(userId);
  }

  setTab(tabName) {
    this.tab = tabName;
    this.subtab === 'my_campaigns_recaudo';
  }

  getHowManyCardsToFill(size: number, col: number) {
    if (size >= col) {
      return size - Math.floor(size / col) * col;
    } else {
      return col - (size - Math.floor(size / col) * col);
    }
  }
}
