<div class="filter filter-city" style="padding-top: 1em; z-index: 1000">
  <h1 style="padding-bottom: 0.5em">Ciudad</h1>
  <ng-autocomplete
    [data]="data"
    [searchKeyword]="keyword"
    (selected)="selectEvent($event)"
    (inputChanged)="onChangeSearch($event)"
    (inputFocused)="onFocused($event)"
    (inputCleared)="onCleared()"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
  >
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>
