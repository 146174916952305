import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
import { IStatusEnum } from '../models/status.model';
@Pipe({
  name: 'filterByStatusFinished',
})
export class FilterByStatusFinishedPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter((campaign) => (campaign.status == IStatusEnum.S7_CANCELLED || campaign.status == IStatusEnum.S8_FINISHED || campaign.status == IStatusEnum.S9_EXPIRED)) : [];
  }
}


@Pipe({
  name: 'filterByStatusNotFinished',
})
export class FilterByStatusNotFinishedPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter((campaign) => 
    !(campaign.status == IStatusEnum.S7_CANCELLED || campaign.status == IStatusEnum.S8_FINISHED || campaign.status == IStatusEnum.S9_EXPIRED)) : [];
  }
}