import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-filter-type',
  templateUrl: './filter-type.component.html',
  styleUrls: ['./filter-type.component.less'],
})
export class FilterTypeComponent implements OnInit {
  constructor() {}

  public realm: any = {};

  @Output()
  nChange = new EventEmitter();

  ngOnInit(): void {}

  handleChange(evt) {
    this.nChange.emit(this.realm);
  }
}
