import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SigninService } from '../../../../app/auth/services/signin.service';
import { UserService } from '../../../../app/core/users/services/user.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.less'],
})
export class SignInPageComponent implements OnInit {
  public user: any = {};
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private $user: UserService,
    private signinService: SigninService
  ) {}

  ngOnInit(): void {
    const storeUser = this.$user.getUser();
    const sessionUser = JSON.parse('' + sessionStorage.getItem('user'));
    const user = storeUser || sessionStorage || null;

    if (user && user.id) {
      this.router.navigate([`/profile2/${user.id}/campaigns/liked`]);
    }

    this.doSetUpForm();
  }

  doSetUpForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  public markAllControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      // If the control is a nested form group, recursively mark its controls as touched
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

  async doSignInWithEmailAndPassword() {
    this.markAllControlsAsTouched(this.form);

    try {
      if (!this.form.valid) {
        throw 'Formulario Invalido';
      }

      const email = this.form.value.email;
      const password = this.form.value.password;
      const userResponse: any = await this.signinService.doSignInWithEmailAndPassword(email, password);
      const userId = userResponse.id;

      sessionStorage.setItem('user', JSON.stringify(userResponse));
      this.$user.setUser(userResponse);

      setTimeout(() => {
        this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
      }, 500);
    } catch (error) {
      this.toastr.error(error, 'Posible error con los parametros para login');
    }
  }
}
