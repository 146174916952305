import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignPhotosComponent } from './components/campaign-photos/campaign-photos.component';
import { CampaignDescriptionComponent } from './components/campaign-description/campaign-description.component';
import { CampaignCommentsComponent } from './components/campaign-comments/campaign-comments.component';
import { CampaignLastDonorsComponent } from './components/campaign-last-donors/campaign-last-donors.component';
import { CampaignInfoComponent } from './components/campaign-info/campaign-info.component';
import { CampaignMapComponent } from './components/campaign-map/campaign-map.component';
import { CampaignDonorsComponent } from './components/campaign-donors/campaign-donors.component';
import { CampaignFaqComponent } from './components/campaign-faq/campaign-faq.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DateDiffTimePipe } from './pipes/dateDiffTime.pipe';
import { CampaignButtonsComponent } from './components/campaign-buttons/campaign-buttons.component';
import { CampaignTabsComponent } from './components/campaign-tabs/campaign-tabs.component';
import { CampaignTabComponent } from './components/campaign-tab/campaign-tab.component';
import { CampaignTabs2Component } from './components/campaign-tabs2/campaign-tabs2.component';

import { FilterByIsLikedPipe } from './pipes/filterByIsLiked.pipe';
import { FilterByIsOrganizedPipe } from './pipes/filterByIsOrganized.pipe';
import { FilterByIsDonatedPipe } from './pipes/filterByIsDonated.pipe';

import { FilterByStatusInProgressPipe } from './pipes/filterByStatusInProgress.pipe';
import { FilterByStatusInCollectionPipe } from './pipes/filterByStatusInCollection.pipe';
import { FilterByStatusFinishedPipe, FilterByStatusNotFinishedPipe } from './pipes/filterByStatusFinished.pipe';
import { FilterByStatusInPendingPipe } from './pipes/filterByStatusInPending.pipe';
import { FilterByCityNamePipe } from './pipes/filterByCityName.pipe';
import { FilterByCampaingTypePipe } from './pipes/filterByCampaingType.pipe';
import { FilterByIsActivePipe } from './pipes/filterByIsActive.pipe';
import { CampaignProfileTabsComponent } from './components/campaign-profile-tabs/campaign-profile-tabs.component';
import { CampaignsTabInCollectingComponent } from './components/campaign-profile-tabs/components/campaigns-tab-in-collecting/campaigns-tab-in-collecting.component';
import { CampaignsTabInExecutionComponent } from './components/campaign-profile-tabs/components/campaigns-tab-in-execution/campaigns-tab-in-execution.component';
import { CampaignsTabInFinishedComponent } from './components/campaign-profile-tabs/components/campaigns-tab-in-finished/campaigns-tab-in-finished.component';

const COMPONENTS = [
  CampaignPhotosComponent,
  CampaignDescriptionComponent,
  CampaignCommentsComponent,
  CampaignLastDonorsComponent,
  CampaignInfoComponent,
  CampaignMapComponent,
  CampaignDonorsComponent,
  CampaignFaqComponent,
  CampaignButtonsComponent,
  CampaignTabsComponent,
  CampaignTabComponent,
  CampaignTabs2Component,
  CampaignProfileTabsComponent,
  CampaignsTabInCollectingComponent,
  CampaignsTabInExecutionComponent,
  CampaignsTabInFinishedComponent
];

const PIPES = [
  FilterByIsLikedPipe,
  FilterByIsDonatedPipe,
  FilterByIsOrganizedPipe,
  FilterByStatusInProgressPipe,
  FilterByStatusFinishedPipe,
  FilterByStatusInCollectionPipe,
  FilterByStatusInPendingPipe,
  FilterByCityNamePipe,
  FilterByCampaingTypePipe,
  FilterByIsActivePipe,
  FilterByStatusNotFinishedPipe
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [...COMPONENTS, DateDiffTimePipe, ...PIPES ],
  exports: [...COMPONENTS, ...PIPES],
})
export class CampaignsModule {}


/*¨


    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_3CRs4v0BgAGAlWgNrgpHohYN95a7pkY',
    }),


    */
