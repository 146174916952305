import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-admin-home-settings-form',
  templateUrl: './admin-home-settings-form.component.html',
  styleUrls: ['./admin-home-settings-form.component.less'],
})
export class AdminHomeSettingsFormComponent implements OnInit {
  public OustandingType = '';

  constructor() {}

  ngOnInit(): void {
    this.OustandingType = 'AUTO';
  }

  setOutstandingType($event) {
    this.OustandingType = $event.target.value;
  }

  async save() {}
}
