import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';

@Component({
  selector: 'ui-text-area-inline',
  templateUrl: './text-area-inline.component.html',
  styleUrls: ['./text-area-inline.component.less'],
})
export class TextAreaInlineComponent implements OnInit, OnDestroy {

  @Input()
  advance: boolean = false;
  @Input()
  type: string;
  @Input()
  label: string;
  @Input()
  width: string;
  @Input()
  border: boolean;
  @Input()
  placeholder: string;
  @Input()
  value: string;
  @Input()
  error: any;
  @Input()
  errorText: any;
  @Input()
  colorCian: boolean;
  @Output()
  nChange = new EventEmitter();
  isFocus = false;
  editor: any = null;
  outputData = '';
  isFirstTime = false;

  get showError(){ return this.isFirstTime && !(this.outputData && this.outputData.length > 0);}
  constructor() {}

  ngOnInit(): void {
    if(this.advance){
      this.editor = new EditorJS({
        minHeight: 100,
        holderId: 'campaign-description-editor-js',
        autofocus: true,
        readOnly: false,
        placeholder: 'Descripción de la campaña ...',
        tools: {
          link: Link,
          header: Header,
          list: List
        },
        onChange: () => {
          this.editor.save().then((outputData) => {
            this.isFirstTime = true;
            this.outputData = (outputData.blocks);
            this.nChange.emit({ target: { value: JSON.stringify(outputData)} })
          }).catch((error) => {
            console.log('Saving failed: ', error)
          });
        }
      });
    }
  }

  ngOnDestroy(){
  }

  onFocus() {
    this.isFocus = true;
  }
  offFocus() {
    this.isFocus = false;
  }

  setText($event) {
    this.nChange.emit($event);
  }
}
