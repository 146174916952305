import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-dropdown-input-c',
  templateUrl: './dropdown-input-c.component.html',
  styleUrls: ['./dropdown-input-c.component.less'],
})
export class DropdownInputCComponent implements OnInit {

  @Input()
  i18: boolean = false;

  @Input()
  option: string;

  @Input()
  options: any[];

  @Input()
  default: any = null;

  @Output()
  nChange = new EventEmitter<any>();

  @Input()
  label: string;

  @Input()
  border: boolean;

  @Input()
  loading: boolean = false;

  @Input()
  errorFn = (value) => {};

  @Input()
  disabledSelectedLabel: string = '';

  constructor() {}

  ngOnInit(): void {}

  setOption($event) {
    if(this.option) {
      this.nChange.emit(
        this.options.find(item =>
          item[this.option] === $event.target.value
        )
      );
    }
    if(!this.option) this.nChange.emit($event.target.value);
  }
}
