<div class="campaign-buttons">
  <div class="wrapper x-extremes y-center">
    <!--
        <ui-solid-btn
            [color]="'red'"
            [text]="'campaing.donate'"
            (nClick)="doDonate()"
        ></ui-solid-btn>
        
        <iu-outline-btn
            [color]="'red'"
            [text]="'campaing.share'"
            (nClick)="doShare()"
        ></iu-outline-btn>
        -->
  </div>
</div>
