import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';

@Component({
  selector: 'ui-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.less'],
})
export class SuccessModalComponent implements OnInit {
  @Output()
  nClose = new EventEmitter<boolean>();

  public shareURL = '';
  public href = '';

  public isSharePictureTab = false;

  constructor() {}

  ngOnInit(): void {
    console.log('success modal');

    this.href = location.href;
    this.href = 'https://stackoverflow.com/questions/38892771/cant-bind-to-ngmodel-since-it-isnt-a-known-property-of-input';
    this.shareURL = location.href;
  }

  sharePicture() {
    this.isSharePictureTab = true;
  }

  closeModal() {
    this.nClose.emit(true);
  }

  onClickedOutside($event) {
    if ($event.target.className.includes('success-modal')) {
      this.closeModal();
    }
  }
  doShareCopyURL() {
    const str = this.shareURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  doShareCopyText() {
    const str = this.shareURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  doDownloadImage() {
    window.alert('DOWNLOADING PICTURE');
    window.scrollTo(0, 0);
    html2canvas(document.querySelector('#capture'), {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      document.body.appendChild(canvas);
      const a = document.createElement('a');
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
      a.download = 'soliun_share.jpg';
      a.click();
    });
  }
}
