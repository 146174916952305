<div class="profile-page--main">
  <div class="main--tabs-content">
    <div class="tab">
      <br />
      <br />
      <ngx-table class="table" [configuration]="configuration" [data]="data" [columns]="columns" >
        <ng-template class="tabla" let-row>

          <!-- COLUMN::ID -->
          <td class="col__id">
            <div class="col__id-r1">
              <div class="container__id">
                <span>{{ row?.id }}</span>
              </div>
              <div class="container__icon" *ngIf="false">
                <button class="btn btn-primary btn-ic" (click)="showModal(row)">
                  <img src="../../../../../assets/icons/ojo.png" />
                </button>
              </div>
              <div class="container__link">
                <button class="btn btn-primary btn-ic" (click)="showLink(row)">
                  <img src="../../../../../assets/icons/link.png" />
                </button>
              </div>
            </div>
            <div class="container__home">
              <img src="../../../../../assets/icons/home_gray.png" />
              <p-inputSwitch name="fieldRowIsHome" [(ngModel)]="row.isInCover" ></p-inputSwitch>
            </div>
            <div class="container__favorites">
              <img src="../../../../../assets/icons/star_gray.png" />
              <p-inputSwitch name="fieldRowIsOutstanding" [(ngModel)]="row.isInOutstanding" ></p-inputSwitch>
            </div>
            <div class="container__save">
              <button (click)="saveCamapignVisibility(row)">
                <img src="../../../../../assets/icons/diskette_gray.png" *ngIf="isSavingCampaignId !== row.id" />
                <img src="../../../../../assets/icons/loading_gif.gif" *ngIf="isSavingCampaignId === row.id" />
              </button>
            </div>
          </td>

          <td>
            <div class="font-title" style="text-align: left; font-family: Poppins-Regular; font-size: 16px">{{ row.title | truncateText : 40 }}</div>
            <div class="font-small">
              <img style="width: 150px; object-fit: contain; object-position: center; border-radius: 1em" [src]="row.photo" />
            </div>
          </td>
          <td>
            <span class="font-small"
              >{{ row?.company }} <br />
              <h1>Responsable:{{ row?.organizer }}</h1>
            </span>
          </td>
          <td>
            <span class="font-small">{{ formatCategory(row?.category) | translate }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.city }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.amount | currency : 'USD' : true : '2.2-4' }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.date_start }}</span>
          </td>
          <td>
            <span class="font-small">{{ row?.date_end }}</span>
          </td>
          <td>
            <h6 class="status" *ngIf="row.status">
              <br />
              <select (change)="setStatus($event, row?.id)" [disabled]="isStatusL1Disabled(row?.status)">
                <option
                  *ngFor="let status of StatusL1"
                  [ngStyle]="{ color: isStatusDisabled(row?.status, status) ? 'red' : 'black' }"
                  [value]="status.value"
                  [disabled]="isStatusDisabled(row?.status, status)"
                  [selected]="row?.status === status.value"
                >
                  {{ status.value | campaignStatusLabel }}
                </option>
              </select>
            </h6>

            <h6 class="status" *ngIf="row.status">
              <select (change)="setStatus($event, row?.id)" [disabled]="isStatusL2Disabled(row?.status)">
                <option selected *ngIf="isStatusL2Disabled(row?.status)"></option>
                <ng-container *ngIf="!isStatusL2Disabled(row?.status)">
                  <option
                    *ngFor="let status of StatusL2"
                    [ngStyle]="{ color: isStatusDisabled(row?.status, status) ? 'red' : 'black' }"
                    [value]="status.value"
                    [disabled]="isStatusDisabled(row?.status, status)"
                    [selected]="row?.status === status.value"
                  >
                    {{ status.value | campaignStatusLabel }}
                  </option>
                </ng-container>
              </select>
            </h6>

            <span class="font-small">
              <h2>Detalle</h2>
            </span>
            <div class="actions-row">
              <button class="btn btn-primary btn-ic" (click)="deleteCampaign(row)">
                <img class="trash" src="../../../../../assets/icons/trash.png" />
              </button>
            </div>
          </td>
        </ng-template>
      </ngx-table>
  </div>
</div>
