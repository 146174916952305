
export enum IStatusEnum {
    S0_IN_PROCESS = 'S0_IN_PROCESS',
    S1_IN_PENDING  = 'S1_IN_PENDING',
    S2_IN_CORRECTING  = 'S2_IN_CORRECTING',
    S3_PUBLISHED  = 'S3_PUBLISHED',

    S4_IN_COLLECTING  = 'S4_IN_COLLECTING',
    S5_IN_PAUSE  = 'S5_IN_PAUSE',
    S6_IN_EXECUTION  = 'S6_IN_EXECUTION',
    S7_CANCELLED  = 'S7_CANCELLED',
    S8_FINISHED  = 'S8_FINISHED',
    S9_EXPIRED  = 'S9_EXPIRED',
}



export const IStatusValues = Object.values(IStatusEnum);

export type IStatusValue = typeof IStatusValues[number];


export interface IStatusOption {

    value: IStatusValue;
    
    next?: IStatusValue,
    prev?: IStatusValue,

    blockers: IStatusValue[];
}