import { FunsForm, IFunsForm } from "../../shared/models/forms.model";
const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;

const SignUpForm = new FunsForm();

SignUpForm.title = 'SignUp Form';

SignUpForm.layout = {
    type: 'default',
    cols: [1,2,1,1]
};

SignUpForm.fields = {
    'company': {
        value: '',
        label: 'Nombre de la Fundación',
        type: 'text',
        border: true,
        required: true,
        error: {
          text: 'Error en nombre de fundación',
          check: (value) => {
            return value.length < 2;
          }
        }
    },
    'phone': {
      value: '',
      label: 'Teléfono',
      type: 'phone',
      border: true,
      required: false,
      error: {
        text: 'Error en el teléfono de la fundación',
        check: (value) => {
          return value.length < 2 || !Number(value.replace(/\s+/g, '').replace(/\+/g, ''));
        }
      }
    },
    'mobile': {
      value: '',
      label: 'Celular',
      type: 'phone',
      border: true,
      required: true,
      error: {
        text: 'Error en el celular de la fundación',
        check: (value) => {
          return value.length < 2 || !Number(value.replace(/\s+/g, '').replace(/\+/g, ''));
        }
      }
    },
    'nit': {
      value: '',
      label: 'NIT',
      type: 'text',
      border: true,
      required: true,
      error: {
        text: 'Error en el nit de la fundación',
        check: (value) => {
          return value.length < 2 || !Number(value);
        }
      }
    },
    'email': {
      value: '',
      label: 'Correo',
      type: 'email',
      border: true,
      required: true,
      error: {
        text: 'Error en el correo de la fundación',
        check: (value) => {
          return value.length < 2 || !value.includes('@');
        }
      }
    },
    'password1': {
      value: '',
      label: 'Ingresa tu Contraseña',
      type: 'password',
      border: true,
      required: true,
      error: {
        text: 'Tu contraseña debe tener al menos X (8) caracteres y una letra mayúscula.',
        check: (pass1) => {
          return pass1.length < 3;
          // return pass1.length < 8 || ((pass1 as string).toLowerCase() ===  (pass1 as string));
        }
      }
    },
    'password2': {
      value: '',
      label: 'Repite tu Contraseña',
      type: 'password',
      border: true,
      required: true,
      error: {
        text: 'Error en la contraseña de la fundación',
        check: (pass2, form: IFunsForm) => {
          return !(form.fields['password1'].value === pass2);
        }
      }
    },
    'description': {
      value: '',
      label: '¿Que haces como fundación?',
      type: 'textarea',
      border: true,
      required: true,
      error: {
        text: 'Error en la descripción de la fundación o muy corto',
        check: (text) => {
          return text.length < 10;
        }
      }
    }
}

export const signUpForm = SignUpForm;
