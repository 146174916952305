import { Component } from '@angular/core';

@Component({
  selector: 'ui-mobile-home-view',
  templateUrl: './mobile-home-view.component.html',
  styleUrls: ['./mobile-home-view.component.less']
})
export class MobileHomeViewComponent {

}
