import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from '../models/campaign.model';
@Pipe({
  name: 'filterByStatusInPending',
})
export class FilterByStatusInPendingPipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    return campaigns ? campaigns.filter((campaign) => campaign.status === 'PENDING') : [];
  }
}
