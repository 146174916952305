<!-- ----------------------------------- -->
<!-- --- home-page outstanding-blogs --- -->
<!-- ----------------------------------- -->
<div class="home-page category-campaigns" *ngIf="campaigns && campaigns.length > 2">
  <!-- ------------------------------------ -->
  <!-- --- Swiper ------------------------- -->
  <!-- ------------------------------------ -->
  <swiper [config]="config" [(index)]="index">
    <div class="category-campaigns--slide" *ngFor="let campaign of campaigns | slice: 0:10">
      <div class="slide--wrapper">
        <!-- CARDS -->
        <ui-card-campaign [campaign]="campaign"></ui-card-campaign>
        <br />
        <br />
      </div>
    </div>
    <div class="swiper-scrollbar"></div>
    <!-- <div class="swiper-pagination"></div> -->
  </swiper>
  <!-- ----------------------------------- -->
</div>
<!-- ----------------------------------- -->
