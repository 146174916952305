<div class="funs funs__form form__input" [ngClass]="{'error': error}" [formGroup]="control.parent">
    
    <div class="input__label">
        <label>{{ label }}:*</label>
    </div>

    <div class="input__container">
        <select [formControl]="control" (change)="onChange($event.target.value)">
            <option selected disabled *ngIf="placeholder"> {{ placeholder }}</option>
            <option *ngFor="let option of options" [value]="option.value">{{ option.label | translate  }}</option>
        </select>
    </div>
    <div class="input__error" *ngIf="error && false">
        <div *ngIf="control.errors?.required">
            {{ label }} es necesario
        </div>
    </div>
</div>
