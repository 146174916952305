import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
// ['MAS DONADAS', 'RECIEN CREADAS', 'POR FINALIZAR'];

@Pipe({
  name: 'sortByPipe',
})
export class SortByPipe implements PipeTransform {
  transform(values: ICampaign[], sort: string): any[] {


    if(sort === 'MAS DONADAS'){
      return values.sort((a: ICampaign, b:ICampaign) => b.donated - a.donated);
    }
    else if(sort === 'MENOS DONADAS'){
      return values.sort((a: ICampaign, b:ICampaign) => a.donated - b.donated);
    }

    else if(sort === 'RECIEN CREADAS'){
      return values.sort((a: ICampaign, b:ICampaign) => new Date(a.created_at).getTime() -  new Date(b.created_at).getTime());
    }
    else if(sort === 'POR FINALIZAR'){
      return values.sort((a: ICampaign, b:ICampaign) => 
        new Date(a.date_end).getTime() -  
        new Date(b.date_end).getTime()
      );
    }

    else return values;
  }
}
