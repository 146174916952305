import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-campaign-donors',
  templateUrl: './campaign-donors.component.html',
  styleUrls: ['./campaign-donors.component.less'],
})
export class CampaignDonorsComponent implements OnInit {

  @Input()
  id!: number;

  @Input()
  total: number;

  @Input()
  last24hrs: number;

  constructor() {}

  ngOnInit(): void {}

  public get getID(){
    return String(this.id).padStart(6,'0')
  }
}
