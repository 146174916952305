<div class="header">
  <div class="row row-layout--to-right row-language">
    <div class="row-language-wrapper">
      <ui-header-language></ui-header-language>
    </div>
  </div>

  <div class="row-container">
    <div class="row row-content font-poppings">
      <div class="wrapper">
        <div class="row row-layout--to-left row-layout--to-bottom">
          <div class="col col-logo" (click)="goToHome()">
            <img class="logo" src="../../../../assets/logos/main-logo.png" />
            <span>Release 1.8.0</span>
          </div>

          <div class="col col-buttons">
            <ui-outline-btn id="header-foundations-btn" [minWidth]="'225px'" [color]="'red'" [text]="'Donar Ahora'" (nClick)="goToDonateNow()"> </ui-outline-btn>
            <ui-outline-btn id="header-campaigns-btn" [minWidth]="'225px'" [margins]="'0px 0px 0px 18px'" [color]="'green'" [text]="'Iniciar Campaña'" (nClick)="goToStartCampaign()"></ui-outline-btn>
          </div>
          <div class="col col-search-and-auth">
            <div class="row row-layout--to-right">
              <ui-header-search *ngIf="!isLegal"></ui-header-search>
            </div>
            <div class="row row-layout--to-right">
              <ui-header-auth *ngIf="!isLegal"></ui-header-auth>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
