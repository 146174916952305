<section class="page-section modal share-modal">
  <div class="modal-wrapper" (clickOutside)="onClickedOutside($event)">
    <div class="modal-header">
      <h1 *ngIf="!isSharePictureTab">
        <div class="form-sigin--header">
          <ui-title-auth-h1 [button]="'x'" (nClick)="closeModal()"> </ui-title-auth-h1>
        </div>
        <!-- <span (click)="closeModal()">x</span> -->
        <img src="../../../assets/icons/success.png" style="width: 150px" /><br />
        <h2 style="margin-top: 10px">¡Gracias por tu donación!</h2>
      </h1>
      <h1 *ngIf="isSharePictureTab">
        <span class="toLeft" (click)="isSharePictureTab = false">Atrás</span>
        Compartir imagen <span class="toRight" (click)="closeModal()">x</span>
      </h1>
    </div>

    <div class="modal-content" style="border: 0" *ngIf="!isSharePictureTab">
      <h1 style="font-family: 'Poppins-SemiBold'; font-size: 21px">
        Trabajaremos para que tu dinero llegue a su destino, te estaremos notificando paso a paso del proceso de esta campaña en tu perfil.
      </h1>
      <img src="../../../assets/icons/circles.png" style="width: 150px; margin-top: 10px; margin-left: 25pc" />
    </div>
  </div>
</section>
