<article class="mobile-checkout page--container">
  <section class="mobile-checkout__section mobile-checkout__section--header background-color--red" *ngIf="campaign">
    <div class="section__container">
      <h1 class="mobile__h1_title mobile__title--red">{{ campaign?.title }}</h1>
      <h2 class="mobile__h1_subtitle mobile__title--gray">{{ campaign?.company }}</h2>
    </div>
  </section>

  <hr style="border: none" />

  <section class="mobile-checkout__section mobile-checkout__section--amount background-color--red" [hidden]="STEP !== 0">
    <div class="section__container">
      <h1 class="mobile__h1_title mobile__title--green">Cantidad a donar</h1>
      <h1 class="mobile__h1_subtitle mobile__title--darkgreen" style="text-align: left; margin: 10px 0px; padding-bottom: 1em">Selecciona tu moneda:</h1>
      <swiper [config]="config_currency" [(index)]="index_currency">
        <div (click)="doSelectCurrency('COP')" style="margin-top: 0.5em; margin-bottom: 1em">
          <button class="mobile__currency-btn" [ngClass]="{ selected: currency === 'COP' }"><span>COP</span></button>
        </div>
        <div (click)="doSelectCurrency('USD')" style="margin-top: 0.5em; margin-bottom: 1em">
          <button class="mobile__currency-btn" [ngClass]="{ selected: currency === 'USD' }"><span>USD</span></button>
        </div>
        <div (click)="doSelectCurrency('EUR')" style="margin-top: 0.5em; margin-bottom: 1em">
          <button class="mobile__currency-btn" [ngClass]="{ selected: currency === 'EUR' }"><span>EUR</span></button>
        </div>
      </swiper>
      <br />
      <swiper [config]="config_amount" [(index)]="index_amount">
        <div class="buttons_swiper">
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'COP' && amount === 10000 }"
            (click)="doSelectAmount(10000)"
            *ngIf="currency === 'COP'"
          >
            <span>{{ 10000 | currency : '$' : 'symbol' : '1.0-0' }}</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'USD' && amount === 5 }"
            (click)="doSelectAmount(5)"
            *ngIf="currency === 'USD'"
          >
            <span>$5</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'EUR' && amount === 5 }"
            (click)="doSelectAmount(5)"
            *ngIf="currency === 'EUR'"
          >
            <span>$5</span>
          </button>
          <img src="../../../../../assets/v2/HEART_ICON.png" width="30px" />
        </div>
        <div class="buttons_swiper">
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'COP' && amount === 15000 }"
            (click)="doSelectAmount(15000)"
            *ngIf="currency === 'COP'"
          >
            <span>{{ 15000 | currency : '$' : 'symbol' : '1.0-0' }}</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'USD' && amount === 10 }"
            (click)="doSelectAmount(10)"
            *ngIf="currency === 'USD'"
          >
            <span>$10</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'EUR' && amount === 10 }"
            (click)="doSelectAmount(10)"
            *ngIf="currency === 'EUR'"
          >
            <span>$10</span>
          </button>
          <img src="../../../../../assets/v2/HEART_ICON.png" width="30px" />
        </div>
        <div class="buttons_swiper">
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'COP' && amount === 20000 }"
            (click)="doSelectAmount(20000)"
            *ngIf="currency === 'COP'"
          >
            <span>{{ 20000 | currency : '$' : 'symbol' : '1.0-0' }}</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'USD' && amount === 15 }"
            (click)="doSelectAmount(15)"
            *ngIf="currency === 'USD'"
          >
            <span>$15</span>
          </button>
          <button
            class="mobile__currency-btn"
            [ngClass]="{ selected: currency === 'EUR' && amount === 15 }"
            (click)="doSelectAmount(15)"
            *ngIf="currency === 'EUR'"
          >
            <span>$15</span>
          </button>
        </div>
      </swiper>
      <h1 class="mobile__p-medium mobile__title--darkgreen" style="text-align: left; margin: 10px 0px; padding-bottom: 1em">
        Puedes elegir una cantidad sugerida o escribir la cifra que quieras donar :
      </h1>
      <br />

      <div class="mobile__currency">
        <label class="currency__symbol" for="">$</label>
        <input class="currency__amount" type="text" placeholder="0" (keyup)="onAmountChange($event)" [(ngModel)]="amount" />
        <label class="currency__text" for="">{{ currency }}</label>
      </div>
      <br />
      <h1 class="mobile__p-medium mobile__title--darkgreen" style="text-align: left; margin: 10px 0px; padding-bottom: 1em">
        (Máximo $1.000.000 COP/$260 USD por día).
      </h1>
      <br />
      <button class="mobile-campaign__button mobile-button button--outline button--red shadow--red" (click)="setStep(1)">SIGUENTE ></button>
      <br />
      <br />
      <br />
    </div>
  </section>

  <section class="mobile-checkout__section mobile-checkout__section--amount background-color--red" [hidden]="STEP !== 1">
    <div class="section__container">
      <h1 class="mobile__h1_title mobile__title--green">Tus datos</h1>
      <h1 class="mobile__h1_subtitle mobile__title--darkgreen" style="text-align: left; margin: 10px 0px; padding-bottom: 0em">
        Recuerda que todos los campos con asterisco * son obligatorios para continuar.
      </h1>
      <ui-funs-mobile-place (nChange)="setPlace($event)"> </ui-funs-mobile-place>
      <br />
      <div class="mobile-person-type">
        <h1 class="person__title">Tipo de Persona</h1>
        <div class="person__buttons">
          <button [ngClass]="{ active: TYPE == 'person' }" (click)="onTypeChange('person')">Natural</button>
          <button [ngClass]="{ active: TYPE == 'company' }" (click)="onTypeChange('company')">Juridica</button>
        </div>
        <br />
        <br />
        <ng-container *ngIf="form.controls.user_firstname">
          <div *ngIf="TYPE == 'person'">
            <funs-mobile-input-text label="Nombre:*" type="text" [disabled]="false" [control]="form.controls.user_firstname"></funs-mobile-input-text>
            <funs-mobile-input-text
              label="Apellido:*"
              type="text"
              [disabled]="false"
              [control]="form.controls.user_lastname"
              label="Apellido"
            ></funs-mobile-input-text>

            <funs-mobile-input-options
              [disabled]="false"
              [control]="form.controls.user_nid_type"
              label="Tipo de Documento"
              [options]="OPTIONS_NID_TYPES"
              [placeholder]="OPTIONS_NID_TYPES[0].label"
            ></funs-mobile-input-options>

            <funs-mobile-input-text
              label="Número de documento:*"
              type="number"
              [disabled]="false"
              [control]="form.controls.user_nid_number"
            ></funs-mobile-input-text>

            <funs-mobile-input-text label="E-mail:*" type="email" [disabled]="false" [control]="form.controls.user_email"></funs-mobile-input-text>

            <funs-mobile-input-text label="Número de teléfono:*" type="number" [disabled]="false" [control]="form.controls.user_phone"></funs-mobile-input-text>
          </div>
        </ng-container>
        <ng-container *ngIf="form.controls.company_name">
          <div *ngIf="TYPE == 'company'">
            <funs-mobile-input-text label="Razón social:*" type="text" [disabled]="false" [control]="form.controls.company_name"></funs-mobile-input-text>
            <funs-mobile-input-text
              label="Número de NIT:*"
              type="number"
              [disabled]="false"
              [control]="form.controls.company_nid_number"
            ></funs-mobile-input-text>
            <funs-mobile-input-text label="E-mail:*" type="email" [disabled]="false" [control]="form.controls.company_email"></funs-mobile-input-text>
            <funs-mobile-input-text
              label="Número de teléfono*"
              type="number"
              [disabled]="false"
              [control]="form.controls.company_phone"
            ></funs-mobile-input-text>
          </div>
        </ng-container>
        <ng-container *ngIf="false && form && form.controls && form.controls.donation_origin && form.controls.donation_postcode">
          <funs-mobile-input-options
            [control]="form.controls.donation_origin"
            label="Como te enteraste?"
            [options]="OPTIONS_ORIGINS"
            placeholder="Como te enteraste?"
          ></funs-mobile-input-options>
          <funs-mobile-input-text-rounded
            [control]="form.controls.donation_postcode"
            label="Codigo Postal (Opcional)"
            type="text"
          ></funs-mobile-input-text-rounded>
        </ng-container>

        <br />
        <br />
        <br />
      </div>
    </div>
  </section>

  <section [hidden]="STEP !== 1">
    <div class="input-checkbox">
      <div class="input-checkbox__control">
        <input
          (change)="onAnonymousChange($event)"
          [formControl]="form.controls.donation_is_anonymous"
          type="checkbox"
          name="checkout-anonymous-donation"
          id="checkout-anonymous-donation"
        />
      </div>
      <div class="input-checkbox__label">
        DONACIÓN ANÓNIMA: Al habilitar esta casilla, tu informacion será guardada para efectos de facturación, control y seguridad, pero ni tu nombre ni tu foto
        se publicarán en el apartado de donantes.
      </div>
    </div>
    <br />
    <button class="mobile-campaign__button mobile-button button--solid button--red shadow--red" (click)="doEpayCo()">Donar ahora</button>
    <br />
  </section>

  <section [hidden]="STEP !== 1" class="background-color--gray" style="display: flex; justify-content: center; align-items: center">
    <img src="../../../../../assets/v2/LOGO_COMPRAS_BANKS.png" alt="" style="width: 300px; padding-bottom: 50px; padding-top: 50px; margin: 0 auto" />
  </section>
</article>
