<!-- CASE 0 :: NONE -->
<div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length === 0">
  <div class="campaing-card">
    <ui-card-empty [card_type]="'donate'"></ui-card-empty>
  </div>
  <div class="campaing-card">
    <ui-card-empty [card_type]="'donate'"></ui-card-empty>
  </div>
  <div class="campaing-card">
    <ui-card-empty [card_type]="'donate'"></ui-card-empty>
  </div>
</div>

<!-- CASE 1 :: FULL MULTIPLOS DE 3,6,9,12 -->
<div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length % 3 == 0">
  <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsDonated">
    <ui-card-campaign [campaign]="campaign" ></ui-card-campaign>
  </div>
</div>

<!-- CASE 2 :: Toca Rellenar -->
<div class="campaigns-list" *ngIf="(campaigns | filterByIsDonated)?.length > 0 && (campaigns | filterByIsDonated)?.length % 3 != 0">
  <div class="campaing-card" *ngFor="let campaign of campaigns | filterByIsDonated">
    <ui-card-campaign [campaign]="campaign" ></ui-card-campaign>
  </div>
  
  <div class="campaing-card" *ngFor="let campaign of [].constructor(getHowManyCardsToFill((campaigns | filterByIsDonated).length, 3))">
    <ui-card-empty [card_type]="'donate'" ></ui-card-empty>
  </div>
</div>
