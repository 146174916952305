import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.less'],
})
export class FilterDateComponent implements OnInit {
  constructor() {}

  @Input()
  label: string;

  @Output()
  nChange = new EventEmitter({} as any);

  public start = new Date();
  public end = new Date();

  public options: any = {};
  public form: any = {};
  public myDateRange: any = {};

  ngOnInit(): void {}

  setStartDate($event) {
    this.start = $event.target.value;
    this.nChange.emit({
      start: this.start,
      end: this.end,
    });
  }
  setEndDate($event) {
    this.end = $event.target.value;
    this.nChange.emit({
      start: this.start,
      end: this.end,
    });
  }
}
