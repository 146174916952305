import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-glossary-modal',
  templateUrl: './glossary-modal.component.html',
  styleUrls: ['./glossary-modal.component.less']
})
export class GlossaryModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Output()
  nClose = new EventEmitter<boolean>();


  public isSharePictureTab = false;
  
  closeModal() {
    this.nClose.emit(true);
  }
  onClickedOutside($event) {
    if ($event.target.className.includes('share-modal')) {
      this.closeModal();
    }
  }
  sharePicture() {
    this.isSharePictureTab = true;
  }
}
