<div class="page-about page-about-us">
  <section class="section-title">
    <h1>SOMOS</h1>
  </section>

  <section class="section-green">
    <br />
    <br />
    <br />
    <p>
      Somos una Entidad Sin Ánimo de Lucro que busca:
      <br />
      1. Desarrollar iniciativas de emprendimiento social para disminuir la brecha de desigualdad en Colombia y el mundo, iniciando en Bogotá y sus Alrededores.
      <br />
      2. Conectar causas y fundaciones con el fin de favorecer a las personas. 
    </p>
    <br />
    <p>
      LA FUNDACIÓN SOLIUN, es una entidad sin ánimo de lucro, que figura como una persona jurídica de derecho privado,
      <strong>regulada por los artículos 633 a 652 del Código Civil Colombiano, el decreto 2150 de 1995</strong>
      y demás normas concordantes. Se constituye en el año 2019 como una entidad sin ánimo de lucro, de carácter permanente, independiente, autónomo y de
      utilidad común.
    </p>

    <h2>MISIÓN</h2>
    <h3>Disminuir la brecha de desigualdad en Colombia y el mundo.</h3>
    <p>
      Somos una organización sin ánimo de lucro que desarrolla iniciativas de emprendimiento social para conectar causas y fundaciones con el fin de favorecer a
      que las personas desarrollen su potencial, buscando mejorar sus condiciones de vida, la de sus familias y comunidades, además de contribuir con la
      reducción de la brecha de desigualdad en Colombia y el mundo.
    </p>
    <br />

    <h2>VISIÓN</h2>
    <p>
      Para el 2025 es tener un destacado reconocimiento  por la contribución al desarrollo integral de comunidades al ser un puente entre personas, organizadores de causas, fundaciones y empresas.
    </p>
    <!--En el año 2025 ser una organización sin ánimo de lucro reconocida, que contribuye con el desarrollo integral de la comunidad siendo un puente de
    comunicación y trabajo en equipo con entidades públicas, causas y fundaciones generando una comunidad a través de iniciativas de emprendimiento social.-->
  </section>

  <section class="section-pink">
    <br />
    <br />
    <br />

    <h2>VALORES INSTITUCIONALES</h2>

    <ul>
      <li>
        <strong>UNIDAD:</strong> Creemos en la colaboración con otras organizaciones e instituciones para potenciar sinergias y mejorar el rendimiento laboral
        de la fundación.
      </li>
      <li>
        <strong>COMPROMISO SOCIAL:</strong> Trabajar con población que se encuentra en situaciones de mayor vulnerabilidad, con los procesos de integración
        intercultural y deportiva de las personas.
      </li>
      <li><strong>CONFIANZA:</strong> Generamos relaciones a nivel interno y externo bajo dinámicas fluidas, eficaces y de comunicación asertiva.</li>
      <li><strong>HONESTIDAD:</strong> Honramos y defendemos el patrimonio de la Fundación siendo totalmente transparentes en nuestro accionar.</li>
      <li>
        <strong>COMPROMISO:</strong> Proporcionamos nuestro mayor esfuerzo para conseguir cada uno de nuestros objetivos, el de fundaciones aliadas y
        organizadores de causas.
      </li>
      <li>
        <strong>TRANSPARENCIA:</strong> Basamos nuestra labor en la honestidad, la mutua responsabilidad y el máximo acceso a la información como pilares de una
        rendición de cuentas sociales y económicas.
      </li>
    </ul>
  </section>

  <section class="section-red">
    <br />
    <br />
    <h2>OBJETIVOS</h2>
    <br />
    <h3>Objetivo principal:</h3>
    <p>
      <strong>Reducir la brecha de desigualad en Colombia, América Latina y el mundo.</strong>
    </p>
    <br />
    <br />

    <h3>Objetivos estratégicos:</h3>
    
      <h3>1. Vivir para ayudar y vivir de ayudar:</h3> <p>buscamos que toda persona que quiera ayudar pueda dedicar su vida a eso sin tener que preocuparse por sus ingresos, generalmente en los países en desarrollo es prácticamente imposible vivir de ayudar pues no es considerado un “trabajo” en FUNS buscamos que en Colombia, América latina y el mundo se pueda trabajar en el sector sin fines de lucro.
    </p>

      <h3>2. Proyectos con impacto social:</h3> <p> queremos encontrar proyectos con impacto social. Proyectos que generen un cambio positivo y medible en aspectos sociales, como la calidad de vida, la igualdad, la salud, la educación, el medio ambiente, entre otros.
    </p>

      <h3>3. Fomentar la cultura de donación masiva:</h3> <p> nos concentramos en generar conciencia social,  facilidad de donar, Transparencia y rendición de cuentas de los recursos manejados, además de incentivos a las personas que donan.
    </p>

      <h3>4. Tecnología en función del impacto social:</h3> <p> buscamos que los avances tecnológicos nos ayuden a generar impacto social mediante el uso de herramientas tecnológicas tales como software tecnológico, inteligencia artificial, redes sociales, entre otras.
    </p>
    <br />
    <br />
  </section>

  <!-- <section class="call-to-actions">
    <div class="section--wrapper">
      <ui-home-call-to-actions></ui-home-call-to-actions>
    </div>
  </section> -->
</div>
