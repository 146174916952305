import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { IUser } from "src/app/core/users/models/user.model";
import { UserService } from "src/app/core/users/services/user.service";
import { PicturesService } from "src/app/shared/services/pictures.service";
import { UIService } from "src/app/shared/services/ui.service";

@Component({
  selector: 'ui-profile-edit-sidebar',
  templateUrl: './profile-edit-sidebar.component.html',
  styleUrls: ['./profile-edit-sidebar.component.less']
})
export class ProfileEditSidebarComponent implements OnInit {

  @Input()
  user_id : number;

  @Output()
  onChange = new EventEmitter();

  public visible = false;
  public sidebarOpen$: Observable<boolean>;

  public selectedUser: IUser = null;


  constructor(
    private $ui: UIService,
    private toastr: ToastrService,
    private $user: UserService,
    private $pictures: PicturesService
  ){
    this.sidebarOpen$ = this.$ui.sidebar_profile$;
  }

  ngOnInit(){
    this.$user.syncUser().subscribe(user => {
      this.selectedUser = user;
    })
  }

  doChangeSidebar($event){
    this.$ui.setSidebarProfile($event);
  }

  // USER::PICTURES
  doRemovePicture(){
    this.selectedUser.photo_url = '/assets/placeholders/placeholder__user.png';
  }
  async onFileSelected($event){

    // Valid at least one file
    const files = $event.target.files;
    if (files.length === 0)
        return;

    // Valid image type
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        // Throw Error
        return;
    }


    const file:File = files[0];
    if (file) {

          const fileName = file.name;
          const formData = new FormData();
          formData.append("profile_picture_file", file);
          formData.append("profile_picture_name", fileName);

          // Upload Picture to Backend
          const url = await this.$pictures.upload(file);

          // Save new URL in user backend
          this.selectedUser.photo_url = url;



      }

  }

  onChangeName(event: string){
    this.selectedUser.name = event;
  }
  onChangeCompany(event: string){
    this.selectedUser.company = event;
  }
  onChangeEmail(event: string){
    this.selectedUser.email = event;
  }
  onChangePhone(event: string){
    this.selectedUser.phone = event;
  }

  async doUserSave(){
    const user = JSON.parse(JSON.stringify(this.selectedUser))
    sessionStorage.setItem('user', JSON.stringify(user));
    console.warn(user)
    try{
      const response = await this.$user.patchUserById(user.id, user);
      this.toastr.success('Todo Ok');
    } catch(err){
      this.toastr.error('ERROR')
    }

  }

}
