<div class="filter filter-city" style="padding-top: 1em">
  <h1 style="padding-bottom: 0.5em">Monto</h1>
  <h2>{{ range.low | number: '1.0-0' }} - {{ range.high | number: '1.0-0' }}</h2>
  <div>
    <div class="row">
      Min
      <input type="text" aria-label="Search" class="ngx-table__header-search" [(ngModel)]="low" id="search_photo" placeholder=" ">
      <!-- <nouislider
        style="display: inline-block; width: calc(100% - 1em); left: 18px; position: relative; background-color: #58B999;"
        (slide)="setLow($event)"
        [connect]="true"
        [min]="0"
        [max]="10000000"
        [(ngModel)]="low"
      ></nouislider> -->
    </div>
    <div class="row">
      Max
      <input type="text" aria-label="Search" class="ngx-table__header-search"  [(ngModel)]="high" id="search_photo" placeholder=" ">
      <!-- <nouislider
        style="display: inline-block; width: calc(100% - 1em); left: 18px; position: relative"
        (slide)="setHigh($event)"
        [connect]="true"
        [min]="0"
        [max]="10000000"
        [(ngModel)]="high"
      ></nouislider> -->
    </div>
  </div>
</div>
