import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import colombia from 'src/assets/countries/colombia';

@Component({
  selector: 'ui-filter-city',
  templateUrl: './filter-city.component.html',
  styleUrls: ['./filter-city.component.less'],
})
export class FilterCityComponent implements OnInit {
  @Output()
  nChange = new EventEmitter();

  constructor() {}
  keyword = 'name';
  data = [
    {
      id: 1,
      name: 'Usa',
    },
    {
      id: 2,
      name: 'England',
    },
  ];

  ngOnInit() {
    const data = [].concat
      .apply(
        [],
        colombia.map((department) => {
          return department.ciudades.map((ciudad) => {
            return {
              name: ciudad,
              department: department.departamento,
              city: ciudad,
            };
          });
        })
      )
      .map((city, index) => {
        return {
          id: index,
          name: city.name,
          city: city.city,
          department: city.department,
        };
      });

    this.data = data;
  }

  selectEvent(item) {
    // do something with selected item
    this.nChange.emit(item.city);
  }

  onCleared() {
    this.nChange.emit('NONE');
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
}
