import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-text-p',
  templateUrl: './text-p.component.html',
  styleUrls: ['./text-p.component.less'],
})
export class TextPComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
