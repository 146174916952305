import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Profile2PagesComponent } from './profile2-pages.component';
import { Profile2PagesRoutingModule } from './profile2-pages.routing';
import { Profile2CampaignsLikedViewComponent } from './views/profile2-campaigns-liked-view/profile2-campaigns-liked-view.component';
import { Profile2CampaignsDonatedViewComponent } from './views/profile2-campaigns-donated-view/profile2-campaigns-donated-view.component';
import { Profile2CampaignsOrganizedViewComponent } from './views/profile2-campaigns-organized-view/profile2-campaigns-organized-view.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileFilters2Component } from './components/profile-filters/profile-filters.component';
import { ProfileTabsUser2Component } from './components/profile-tabs-user/profile-tabs-user.component';
import { HomePageModule } from '../home-page/home-page.module';
import { ProfileEditSidebarComponent } from './components/profile-edit-sidebar/profile-edit-sidebar.component';

import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';


const PRIME_NG_COMPONENTS = [
  TabViewModule,
  SidebarModule,
  InputTextModule,
  ScrollPanelModule,
  InputTextareaModule,
  InputSwitchModule,
  ButtonModule
]
@NgModule({
  declarations: [
    Profile2PagesComponent,
    ProfileFilters2Component,
    ProfileTabsUser2Component,
    Profile2CampaignsLikedViewComponent,
    Profile2CampaignsDonatedViewComponent,
    Profile2CampaignsOrganizedViewComponent,
    ProfileEditSidebarComponent,
  ],
  exports: [ProfileFilters2Component],
  imports: [CommonModule, SharedModule, CoreModule,HomePageModule, Profile2PagesRoutingModule, ...PRIME_NG_COMPONENTS],
})
export class Profile2PagesModule {}
