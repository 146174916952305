<!-- ----------------------------------- -->
<!-- --- home-page call-to-actions ------ -->
<!-- ----------------------------------- -->
<div class="home-page call-to-actions">
  <h1>¿Cómo quieres ayudar al mundo?</h1>
  <div class="buttons">
    <div class="button red fill" (click)="goToDonate()">
      <span>DONAR AHORA</span>
    </div>
    <div class="button green fill" (click)="goToCampaign()">
      <span>INICIAR CAMPAÑA</span>
    </div>
  </div>
</div>
<!-- ----------------------------------- -->
