import { Component, Input, OnInit } from '@angular/core';
import { IFunsLayout } from 'src/app/shared/models/forms.model';

@Component({
  selector: 'ui-form-single-column',
  templateUrl: './form-single-column.component.html',
  styleUrls: ['./form-single-column.component.less']
})
export class FormSingleColumnComponent implements OnInit {

  @Input()
  layout!: IFunsLayout;

  constructor() { }

  ngOnInit(): void {
  }

  getClass(){
    const nRows = this.layout.cols.length;
    return 'cols ' + this.layout.cols.map((item, index) => 'row-' + index + ' ').join('');
  }

}
