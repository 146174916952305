import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from '../../core/campaigns/services/campaigns.service';
import Categories from '../../core/campaigns/constants/categories.constant';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less'],
})
export class HomePageComponent implements OnInit {
  public campaingsCover$: Observable<ICampaign[]>;
  public campaigns$: Observable<ICampaign[]>;

  public categoryA: string;
  public categoryB: string;

  constructor(private $campaigns: CampaignsService) {
    this.campaingsCover$ = this.$campaigns.syncCoverCampaigns();
    this.campaigns$ = this.$campaigns.syncCampaigns();
  }

  async ngOnInit(): Promise<void> {
    const categoryA = Categories[Math.floor(Math.random() * Categories.length)];
    const categoryB = Categories.filter((catg) => catg !== categoryA)[Math.floor(Math.random() * Categories.filter((catg) => catg !== categoryA).length)];

    this.categoryA = categoryA;
    this.categoryB = categoryB;

    // const coverCampaigns = await this.$campaigns.fetchCoverCampaigns();
    const homeCampaigns = await this.$campaigns.fetchHomeCampaigns(categoryA, categoryB);
  }
}
