<div class="page alternative-page">
  <ui-card-register
    [img]="'../../../../assets/icons/hearts.png'"
    [subtitle]="'Donador'"
    [colorsub]="'#9D9D9C'"
    [text1]="'Tan solo con una donación, te daré un espacio en una plataforma donde la solidaridad y honestidad lo son todo'"
    [text2]="
      'Puedes iniciar con una sola donación o bien, sacar el máximo provecho de la plataforma  que la FUNDACIÓN SOLIUN ha construido e iniciar a liderar campañas para ayudar al mundo en diferentes categorías.'
    "
    [btn]="'Quiero ser donador'"
    [btnop]="1"
    [colorbtn]="'white'"
    id="signup-donator-card"
  >
  </ui-card-register>
  <ui-card-register
    [img]="'../../../../assets/icons/puzzle.png'"
    [subtitle]="'Organizador'"
    [colorsub]="'#58B999'"
    [text1]="'Si vas a brindar ayuda constante a otros liderando tus propias causas, el registro como organizador es para tí.'"
    [text2]="
      'Si, la constancia en tus causas disminuye y empiezas a donar, tu estado cambiará a Donador. Si tu constancia generando causas se mantiene y al mismo tiempo donas, cambiará a Donador y Organizador.'
    "
    [btn]="'Quiero ser organizador'"
    [btnop]="1"
    [colorbtn]="'green'"
    id="signup-organizer-card"
  >
  </ui-card-register>
  <ui-card-register
    [img]="'../../../../assets/icons/r_fundation.png'"
    [subtitle]="'Fundación'"
    [colorsub]="'#E84249'"
    [text1]="'Puedes contar con nosotros como medio para cumplir con todos tus proyectos si eres una fundación.'"
    [text2]="
      'Al registrarte como fundación asegúrate de contar con NIT y documentación en regla, para nosotros es muy importante relacionarnos con grupos que tengan el mismo objetivo de ayuda, así que antes revisaremos tú documentación.'
    "
    [btn]="'Soy una fundación'"
    [btnop]="2"
    [colorbtn]="'red'"
    id="signup-foundation-card"
  >
  </ui-card-register>
</div>
