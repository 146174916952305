



<p-sidebar
  [visible]="(sidebarOpen$ | async)"
  (visibleChange)="doChangeSidebar($event)"
  position="right" styleClass="w-30rem"
>


  <div class="user_sidebar__header">
    <h3>Editar Usuario</h3>
    <button pButton pRipple label="Guardar" class="p-button-success" (click)="doUserSave()"></button>
  </div>

  <div class="user_sidebar__content grid selected_user" *ngIf="selectedUser && selectedUser.id">
    <div class="row row-2cols">
      <div class="col" style="position: relative">
        <img class="profile_picture" [src]="selectedUser.photo_url || '/assets/placeholders/placeholder__user.png'" />
        <label class="profile_delete" (click)="doRemovePicture()">
          <span><i class="fa fa-trash-o" aria-hidden="true"></i></span>
        </label>
        <label class="profile_edit" >
          <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
          <span ><i class="fa fa-pencil" aria-hidden="true"></i> </span>
        </label>
      </div>
      <div class="col">
        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Nombre'"
          [placeholder]="selectedUser.name"
          [type]="'text'"
          style="width: 100%"
          (nChange)="onChangeName($event)"
        ></ui-text-input-inline-c>

        <ui-text-input-inline-c
        [error]="authError"
        [errorText]="'N/A'"
        [border]="true"
        [label]="'Company'"
        [placeholder]="selectedUser.company"
        [type]="'text'"
        style="width: 100%"
        (nChange)="onChangeCompany($event)"
      ></ui-text-input-inline-c>

        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Correo'"
          [type]="'text'"
          [placeholder]="selectedUser.email"
          style="width: 100%"
          (nChange)="onChangeEmail($event)"
        ></ui-text-input-inline-c>

        <ui-text-input-inline-c
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Telefono'"
          [placeholder]="selectedUser.phone"
          [type]="'text'"
          style="width: 100%"
          (nChange)="onChangePhone($event)"
        ></ui-text-input-inline-c>
      </div>
    </div>

  </div>


</p-sidebar>
