import { Pipe, PipeTransform } from '@angular/core';

function toTranslate(category: string): string {
  const categorias = {
    'OLDER ADULTS': 'Adultos Mayores',
    ANIMALS: 'Animales',
    EDUCATION: 'Educación',
    'YOUNG PEOPLE': 'Jóvenes',
    FAMILIES: 'Familias',
    ENVIRONMENTAL: 'Medio Ambiente',
    'MORE POPULAR': 'Populares',
    'LESS DONATED': 'Menos Donados',
    KIDS: 'Niños',
  };
  return categorias[category] || category;
}

@Pipe({
  name: 'filterByCategoryPipe',
})
export class FilterByCategoryPipe implements PipeTransform {
  transform(values: any[], categories: string[]): any[] {
    if (categories && categories.length > 0) {
      return JSON.parse('' + JSON.stringify(values)).filter(
        (value) => categories.includes(value.category) || categories.map(item => item.toUpperCase()).includes(toTranslate(value.category).toUpperCase())
      );
    } else {
      return JSON.parse('' + JSON.stringify(values));
    }
  }
}
