import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.less']
})
export class CategoryFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
