import { Pipe, PipeTransform } from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
@Pipe({
  name: 'filterByIsActive',
})
export class FilterByIsActivePipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    if (campaigns && campaigns.length > 0) {
      return campaigns.filter((campaign) => campaign.status === 'S3_PUBLISHED' || campaign.status === 'S4_IN_COLLECTING');
    }
    return [];
  }
}
