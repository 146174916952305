import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-create-campaign-thanks-page',
  templateUrl: './create-campaign-thanks-page.component.html',
  styleUrls: ['./create-campaign-thanks-page.component.less'],
})
export class CreateCampaignThanksPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
