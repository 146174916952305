import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSignupUserComponent } from './components/forms/form-signup-user/form-signup-user.component';
import { FormSignupCompanyComponent } from './components/forms/form-signup-company/form-signup-company.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FormSignupUserComponent, FormSignupCompanyComponent],
  exports: [FormSignupUserComponent, FormSignupCompanyComponent],
  imports: [CommonModule, SharedModule, RouterModule],
})
export class UsersModule {}
