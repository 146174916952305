import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ICampaign, IServerCampaign } from '../models/campaign.model';
import { IPlace, IServerPlace } from 'src/app/shared/models/place.model';
import { IUser, IServerUser } from '../../users/models/user.model';
import { IServerComment } from '../../comments/models/comment.model';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  constructor(private $http: HttpClient) {}

  private _coverCampaigns = new BehaviorSubject<ICampaign[]>(null);
  public coverCampaigns$ = this._coverCampaigns.asObservable();

  private _profileCampaigns = new BehaviorSubject<ICampaign[]>(null);
  public profileCampaigns$ = this._profileCampaigns.asObservable();

  private _campaigns = new BehaviorSubject<ICampaign[]>(null);
  public campaigns$ = this._campaigns.asObservable();

  public syncCampaign(id: number): Observable<ICampaign>{
    return this.campaigns$.pipe(
      map(items => {
        if(items) return items.find(item => item.id === id)
        else return null
      })
    );
  }
  public syncCoverCampaigns() {
    return this.coverCampaigns$;
  }
  public syncProfileCampaigns() {
    return this.profileCampaigns$;
  }
  public syncCampaigns() {
    return this.campaigns$;
  }

  public async refresh() {
    const ids = [...this._campaigns.getValue()].filter((campaign) => campaign.id).map((campaign) => campaign.id);
    const response = await this.fetchCampaignsByIds(ids);
  }

  public async postCampaing(campaign) {
    const obj = await this.$http.post<IServerCampaign[]>(`${environment.server}/campaigns`, campaign).toPromise();
    return obj;
  }
  public async fetchAllCampaigns(state?: string) {
    console.log(' FETCH ALL CAMPAIGNS ')
    const campaigns = await this.fetchCampaigns(state);
    this._campaigns.next(campaigns);
    return campaigns;
  }
  public async fetchCampaigns(filter?: string) {
    try {
      if (!filter) filter = 'all';
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Accept', 'application/json');
      headers.append('Access-Control-Allow-Origin', '*');
      const serverCampaignsEndpoint = `${environment.server}/campaigns${filter ? '/' + filter : ''}?token=${Math.random()}`;
      const serverCampaings = await this.$http.get<IServerCampaign[]>(serverCampaignsEndpoint, { headers }).toPromise();
      const serverCampaignsIds = serverCampaings.map((campaing) => campaing.organizer_id).join(',');
      const serverOrganizersEndpoint = `${environment.server}/users/by/ids${serverCampaignsIds ? '/' + serverCampaignsIds : '/0'}`;
      const serverOrganizers = await this.$http.get<IServerUser[]>(serverOrganizersEndpoint, { headers: headers }).toPromise();
      console.log({serverOrganizers})
      const serverPlaces = [];
      const campaings = serverCampaings.map((serverCampaing: IServerCampaign): ICampaign => {
        return {
          id: serverCampaing.id,
          organizer: serverOrganizers.find((serverOrganizer) => serverOrganizer.id === serverCampaing.organizer_id),
          company: serverOrganizers.find((serverOrganizer) => serverOrganizer.id === serverCampaing.organizer_id).company,
          place: serverPlaces.find((serverPlace) => serverPlace.id === serverCampaing.place_id),
          created_at: serverCampaing.created_at,
          updated_at: serverCampaing.updated_at,
          title: serverCampaing.title,
          description: serverCampaing.description,
          type: serverCampaing.type,
          category: serverCampaing.category,
          status: serverCampaing.status,
          content: serverCampaing.content,
          photos: this.getPhotos(serverCampaing),

          place_city: serverCampaing.place_city,

          donated: serverCampaing.donated,
          donors: serverCampaing.donors,
          followers: serverCampaing.followers,
          stats: serverCampaing.stats,

          share_link: serverCampaing.share_link,
          share_picture_url: serverCampaing.share_picture_url,
          goal: serverCampaing.goal,
          date_start: serverCampaing.date_start,
          date_end: serverCampaing.date_end,
          summary_html: serverCampaing.summary_html,
          summary_photo_url: serverCampaing.summary_photo_url,
          isInCover: serverCampaing.isInCover,
          isInOutstanding: serverCampaing.isInOutstanding,
        };
      });
      return campaings;
    } catch (err) {
      console.error(' ERROR @ fetchCampaigns ');
      console.error(err);
      console.error(err);
    }
  }

  public getPhotos(campaign) {
    const end = '?random=' + Math.floor(100 * Math.random());
    return [
      campaign.photo_1 + (campaign.photo_1 && campaign.photo_1.includes('picsum') ? end : ''),
      campaign.photo_2 + (campaign.photo_2 && campaign.photo_2.includes('picsum') ? end : ''),
      campaign.photo_3 + (campaign.photo_3 && campaign.photo_3.includes('picsum') ? end : ''),
      campaign.photo_4 + (campaign.photo_4 && campaign.photo_4.includes('picsum') ? end : ''),
    ];
  }
  public async fetchCoverCampaigns() {
    const campaigns = await this.fetchCampaigns('by/is/cover');
    this._coverCampaigns.next(campaigns);
  }
  public async fetchMyProfileCampaigns(userId) {
    const obj: any = await this.$http.get<IServerCampaign[]>(`${environment.server}/campaigns/by/is/profile/userId/${userId}`).toPromise();
    const campaignsLiked = obj.liked;
    const campaignsDonated = obj.donated;
    const campaignsOrganized = obj.organized;

    const serverCampaings = [
      ...campaignsLiked.map((campaign) => {
        campaign.isLiked = true;
        return campaign;
      }),
      ...campaignsDonated.map((campaign) => {
        campaign.isDonated = true;
        return campaign;
      }),
      ...campaignsOrganized.map((campaign) => {
        campaign.isOrganized = true;
        return campaign;
      }),
    ];

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    const serverOrganizers = await this.$http
      .get<IServerUser[]>(`${environment.server}/users/by/ids/${serverCampaings.map((campaing) => campaing.organizer_id).join(',') || '0'}`, {
        headers: headers,
      })
      .toPromise();
    const serverPlaces = [];
    const campaings = serverCampaings.map((serverCampaing: any): ICampaign => {
      return {
        isLiked: serverCampaing.isLiked,
        isDonated: serverCampaing.isDonated,
        isOrganized: serverCampaing.isOrganized,

        id: serverCampaing.id,
        organizer: serverOrganizers.find((serverOrganizer) => serverOrganizer.id === serverCampaing.organizer_id),
        place: serverPlaces.find((serverPlace) => serverPlace.id === serverCampaing.place_id),
        created_at: serverCampaing.created_at,
        updated_at: serverCampaing.updated_at,
        title: serverCampaing.title,
        description: serverCampaing.description,
        type: serverCampaing.type,
        category: serverCampaing.category,
        status: serverCampaing.status,
        content: serverCampaing.content,
        photos: [
          serverCampaing.photo_1 + '?random=' + Math.floor(100 * Math.random()),
          serverCampaing.photo_2 + '?random=' + Math.floor(100 * Math.random()),
          serverCampaing.photo_3 + '?random=' + Math.floor(100 * Math.random()),
          serverCampaing.photo_4 + '?random=' + Math.floor(100 * Math.random()),
        ],

        donated: serverCampaing.donated,
        donors: serverCampaing.donors,
        followers: serverCampaing.followers,
        stats: serverCampaing.stats,

        share_link: serverCampaing.share_link,
        share_picture_url: serverCampaing.share_picture_url,
        goal: serverCampaing.goal,
        date_start: serverCampaing.date_start,
        date_end: serverCampaing.date_end,
        summary_html: serverCampaing.summary_html,
        summary_photo_url: serverCampaing.summary_photo_url,
        isInCover: serverCampaing.isInCover,
        isInOutstanding: serverCampaing.isInOutstanding,
        update_remains: serverCampaing.update_remains,
      };
    });

    console.warn(' campaings ');
    console.warn(campaings);

    this._profileCampaigns.next(campaings);
  }

  public async fetchHomeCampaigns(categoryA: string, categoryB: string) {
    const campaings = await this.fetchCampaigns(`for/home/categoryA/${categoryA}/categoryB/${categoryB}`);
    this._campaigns.next(campaings);
    return campaings;
  }

  public async fetchCampaignsById(id: number) {
    const campaign = (await this.fetchCampaigns(`by/ids/${id}`))[0];
    const campaignsStore = [...(this._campaigns.getValue() || [])];
    const campaignsCategoryA = await this.fetchCampaigns(`by/is/category/${campaign.category}`);
    const campaigns = [...[campaign], ...campaignsStore, ...campaignsCategoryA];
    this._campaigns.next(campaigns);
  }

  public async fetchCampaignsByIds(ids: number[]) {
    const campaign = await this.fetchCampaigns(`by/ids/${ids.join(',')}`);
    const campaigns = [...campaign];
    this._campaigns.next(campaigns);
  }

  public async getDonatedByCampaingId(id: number) {
    const donated: number = ((await this.$http.get<any>(`${environment.server}/donations/by/campaing/id/${id}`).toPromise()) as any[]).reduce(
      (prev, curr) => parseFloat(curr.amount + '') + prev
    );
    return donated;
  }

  public async getDonorsByCampaingId(id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    const donors = ((await this.$http.get<any>(`${environment.server}/donations/by/campaing/id/${id}`).toPromise()) as any[]).slice(0, 50);
    const users = await this.$http
      .get<any>(`${environment.server}/users/by/ids3/${donors.map((donor) => donor.user_id).join(',')}`, { headers: headers })
      .toPromise();
    return donors.map((donor) => {
      const _donor = donor;
      _donor.user = users.find((user) => user.id === _donor.user_id);
      return _donor;
    });
  }

  public async getDonorsStatsByCampaingId(id: number) {
    const donated: any = await this.$http.get<any>(`${environment.server}/donations/stats/by/campaing/id/${id}`).toPromise();
    return donated;
  }

  public async getCommentsByCampaingId(id: number) {
    const serverComments = await this.$http.get<IServerComment[]>(`${environment.server}/comments/by/campaign/id/${id}`).toPromise();
    return serverComments;
  }

  public async patch(id, campaing) {
    const url = `${environment.server}/campaigns/${id}`;
    const obj = JSON.parse(JSON.stringify(campaing) + '');
    const response = await this.$http.patch(url, obj).toPromise();
    return response;
  }

  public async removeById(id) {
    const url = `${environment.server}/campaigns/${id}`;
    const response = await this.$http.delete(url).toPromise();
    return true;
  }
}
