import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-recovery-password-email-send-page',
  templateUrl: './recovery-password-email-send-page.component.html',
  styleUrls: ['./recovery-password-email-send-page.component.less'],
})
export class RecoveryPasswordEmailSendPageComponent implements OnInit {
  public email = '';
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
  }
}
