import { Component, Input} from '@angular/core';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';

@Component({
  selector: 'ui-admin-campaign-details-row',
  templateUrl: './admin-campaign-details-row.component.html',
  styleUrls: ['./admin-campaign-details-row.component.less']
})
export class AdminCampaignDetailsRowComponent {

  @Input()
  campaign !: ICampaign;

}
