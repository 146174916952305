import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { CountriesService } from 'src/app/shared/services/countries.service';
export type PlaceISO = {
  name: string;
  iso2: string;
}

@Component({
  selector: 'ui-place-input',
  templateUrl: './place-input.component.html',
  styleUrls: ['./place-input.component.less']
})
export class PlaceInputComponent implements OnInit,OnChanges {

  @Input()
  isRow: boolean = true;

  @Input()
  isReset: boolean = false;

  @Input()
  type: string;

  @Input()
  label: string;
  
  @Input()
  border: boolean;

  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Input()
  error: any;

  @Input()
  errorText: any;

  @Output()
  nChange = new EventEmitter();

  public countries = [];
  public isCountryLoading = true;
  public isDepartmentLoading = true;
  public isCityLoading = true;

  public fields = {
    'country' : {
      value: {
        name: '',
        iso2: ''
      },
      options: [] as PlaceISO[],
      label:'Pais',
      type:'options',
      border: true,
      error:{ value: false, text: 'N/A'}
    },
    'department' : {
      value: {
        name: '',
        iso2: ''
      },
      options: [] as PlaceISO[],
      label:'Departamento',
      type:'options',
      border: true,
      error:{ value: false, text: 'N/A'}
    },
    'city' : {
      value: {
        name: '',
        iso2: ''
      },
      options: [] as PlaceISO[],
      label:'Ciudad',
      type:'options',
      border: true,
      error:{ value: false, text: 'N/A'}
    },
  }

  constructor(private $countries: CountriesService) { }

  ngOnInit(): void {
    this.initPlaces();
  }

  ngOnChanges(){
    if(this.isReset){
      this.initPlaces();
    }
  }

  async initPlaces(){
    this.isCountryLoading = true;
    // Countries, States & Cities
    const countries: any[] = (await this.getCountries()) as any[];
    this.countries = countries;
    this.isCountryLoading = false;

    // Set Country Default
    this.setCountry('Colombia');

    return '';
  }

  async setCountry(countryName: string) {
    this.isDepartmentLoading = true;
    this.isCityLoading = true;
    if (countryName === 'Colombia') {
      const countries = this.countries;
      const country = countries.find((country: any) => country.name === countryName);
      const departments = await this.getDepartmentsByCountryName(country.iso2);
      const department = departments.find((department: any) => department.iso2 === 'DC');
      const cities = await this.getCitiesByCountryAndDeparment(country.iso2, 'CUN');
      const city = cities.find((city: any) => city.name == 'Bogotá D.C.');
      this.fields.country.options = countries;
      this.fields.country.value = country;
      this.fields.department.options = departments;
      this.fields.department.value = department;
      this.fields.city.options = cities;
      this.fields.city.value = city;
    } else if (countryName === 'United States') {
      const countries = this.countries;
      const country = countries.find((country: any) => country.name === countryName);
      const departments = await this.getDepartmentsByCountryName(country.iso2);
      const department = departments.find((department: any) => department.iso2 === 'FL');
      const cities = await this.getCitiesByCountryAndDeparment(country.iso2, 'FL');
      const city = cities.find((city: any) => city.name == 'Miami');
      this.fields.country.options = countries;
      this.fields.country.value = country;
      this.fields.department.options = departments;
      this.fields.department.value = department;
      this.fields.city.options = cities;
      this.fields.city.value = city;
    } else {
      const countries = this.countries;
      const country = countries.find((country: any) => country.name === countryName);
      const departments = await this.getDepartmentsByCountryName(country.iso2);
      const department = departments[0];
      const cities = await this.getCitiesByCountryAndDeparment(country.iso2, department.iso2);
      const city = cities[0];
      this.fields.country.options = countries;
      this.fields.country.value = country;
      this.fields.department.options = departments;
      this.fields.department.value = department;
      this.fields.city.options = cities;
      this.fields.city.value = city;
    }
    this.isDepartmentLoading = false;
    this.isCityLoading = false;

    this.nChange.emit({
      country: this.fields.country.value.name,
      deparment: this.fields.department.value.name,
      city: this.fields.city.value.name
    });
  }
  async setDepartment($departmentName) {
    this.isCityLoading = true; 
    const countries = this.fields.country.options;
    const country = this.fields.country.value;
    const departments = await this.getDepartmentsByCountryName(country.iso2);
    const department = departments.find((department: any) => department.name == $departmentName);
    const cities = await this.getCitiesByCountryAndDeparment(country.iso2, department.iso2);
    const city = cities[0];
    this.fields.country.options = countries;
    this.fields.country.value = country;
    this.fields.department.options = departments;
    this.fields.department.value = department;
    this.fields.city.options = cities;
    this.fields.city.value = city;
    this.isCityLoading = false;
    
    this.nChange.emit({
      country: this.fields.country.value.name,
      deparment: this.fields.department.value.name,
      city: this.fields.city.value.name
    });
  }
  async setCity($cityName) {
    const countries = this.fields.country.options;
    const country = this.fields.country.value;
    const departments = this.fields.department.options;
    const department = this.fields.department.value;
    const cities = this.fields.city.options;
    const city =  cities.find((city: any) => city.name == $cityName);
    this.fields.country.options = countries;
    this.fields.country.value = country;
    this.fields.department.options = departments;
    this.fields.department.value = department;
    this.fields.city.options = cities;
    this.fields.city.value = city;
    
    this.nChange.emit({
      country: this.fields.country.value.name,
      deparment: this.fields.department.value.name,
      city: this.fields.city.value.name
    });
  }
  async getCountries() {
    return await this.$countries.getCountriesList();
  }
  async getDepartmentsByCountryName(countryName: string) {
    return await this.$countries.getDepartmentsByCountryName(countryName);
  }
  async getCitiesByCountryAndDeparment(countryName: string, departmentName: string) {
    return await this.$countries.getCitiesByCountryAndDepartment(countryName, departmentName);
  }
  setField(fieldName: string, fieldValue: any){
    if(this.fields[fieldName].type === 'text'){
      this.fields[fieldName].value = fieldValue.target.value
    }
    if(this.fields[fieldName].type === 'options'){
      if(fieldName === 'country'){
        this.setCountry((fieldValue as PlaceISO).name);
      }
      if(fieldName === 'department'){
        this.setDepartment((fieldValue as PlaceISO).name);
      }
      if(fieldName === 'city'){
        this.setCity((fieldValue as PlaceISO).name);
      }
      this.fields[fieldName].value = (fieldValue as PlaceISO);
    }
  }
  getFieldOptions(fieldName){
    return this.fields[fieldName].options;
  }
  getErrorField(fieldName){
    return this.fields[fieldName].error.value;
  }
  getErrorFieldText(fieldName){
    return this.fields[fieldName].error.text;
  }
  getFieldBorder(fieldName){
    return this.fields[fieldName].border;
  }
  getFieldDefault(fieldName){
    return this.fields[fieldName].value;
  }
  getFieldLabel(fieldName){
    return this.fields[fieldName].label;
  }
  getFieldType(fieldName){
    return this.fields[fieldName].type;
  }

}
