import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-radio-btn-input',
  templateUrl: './radio-btn-input.component.html',
  styleUrls: ['./radio-btn-input.component.less'],
})
export class RadioBtnInputComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  color: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}
}
