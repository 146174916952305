import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ui-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.less'],
})
export class CreatePageComponent implements OnInit {
  public page = 'menu';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params && params.type === 'foundation') {
        this.page = 'foundation';
      }
      if (params && params.type === 'cause') {
        this.page = 'cause';
      }
      // this.page = params['yourParam'];
    });
  }
}
