import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { signUpForm } from 'src/app/auth/forms/signup.user.form';
import { SignupService } from 'src/app/auth/services/signup.service';
import { UserService } from 'src/app/core/users/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ui-signup-welcome-page',
  templateUrl: './signup-welcome-page.component.html',
  styleUrls: ['./signup-welcome-page.component.less']
})
export class SignupWelcomePageComponent {

  constructor(
    public router: Router, 
    public route: ActivatedRoute,
    public userService: UserService) {}

  ngOnInit(): void {

  }
  goToProfile(){
    const user = this.userService.getUser();
    if(user) {
      const userId = user.id;
      this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'No existe usuario',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  doClose(){
    
  }
}