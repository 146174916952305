import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-outline-btn',
  templateUrl: './outline-btn.component.html',
  styleUrls: ['./outline-btn.component.less'],
})
export class OutlineBtnComponent implements OnInit {

  @Input()
  active: boolean;

  @Input()
  text: string;

  @Input()
  minWidth: string;

  @Input()
  color: string;

  @Input()
  margins: string;

  @Input()
  paddings: string;

  @Input()
  pipe: string;

  @Input()
  currency: string;

  @Output()
  nClick = new EventEmitter<boolean>();

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  onClick() {
    this.nClick.emit(true);
  }
}
