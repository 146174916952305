import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-campaign-tabs2',
  templateUrl: './campaign-tabs2.component.html',
  styleUrls: ['./campaign-tabs2.component.less'],
})
export class CampaignTabs2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
