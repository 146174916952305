<div class="card" *ngIf="card_type === 'donate'">
  <div class="card--main card--main--help-world" (click)="goToCampaigns()">
    <img src="../../../../../assets/icons/plus_icon.png" />
    <h1>QUIERO AYUDAR AL MUNDO</h1>
  </div>
  <div class="card--bottom">
    <img src="../../../../../assets/icons/empty_card_heart.png" />
    <p>Contribuye con tu donación para un nuevo proyecto de una fundación o con una causa, gestada por un organizador.</p>
  </div>
</div>

<div class="card" *ngIf="card_type === 'like'">
  <div class="card--main">
    <img src="../../../../../assets/icons/plus_icon.png" />
    <h1>
      ¡Sigue todas las <br />
      Campañas que quieras!
    </h1>
  </div>
  <div class="card--bottom">
    <img src="../../../../../assets/icons/empty_card_heart.png" />
  </div>
</div>

<div class="card" *ngIf="card_type === 'like'">
  <div class="card--main">
    <img src="../../../../../assets/icons/plus_icon.png" />
    <h1>
      ¡Sigue todas las <br />
      Campañas que quieras!
    </h1>
  </div>
  <div class="card--bottom">
    <img src="../../../../../assets/icons/empty_card_heart.png" />
  </div>
</div>

<div class="card card-like-tutorial" *ngIf="card_type === 'like_tutorial_step_1'">
  <img src="../../../../../assets/icons/card_like_tutorial_step1.png" />
</div>
<div class="card card-like-tutorial" *ngIf="card_type === 'like_tutorial_step_2'">
  <img src="../../../../../assets/icons/card_like_tutorial_step2.png" />
</div>
<div class="card card-like-tutorial" *ngIf="card_type === 'like_tutorial_step_3'">
  <img src="../../../../../assets/icons/card_like_tutorial_step3.png" />
</div>

<div class="card card-organized-finished-tutorial" *ngIf="card_type === 'finished_tutorial_step_1'">
  <img src="../../../../../assets/icons/card_organized_finished_tutorial_step1.png" />
</div>
<div class="card card-organized-finished-tutorial" *ngIf="card_type === 'finished_tutorial_step_2'">
  <img src="../../../../../assets/icons/card_organized_finished_tutorial_step2.png" />
</div>
<div class="card card-organized-finished-tutorial" *ngIf="card_type === 'finished_tutorial_step_3'">
  <img src="../../../../../assets/icons/card_organized_finished_tutorial_step3.png" />
</div>
