import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { IUser } from 'src/app/core/users/models/user.model';
import { EpaycoService, IEpaycoPayload } from 'src/app/shared/services/epayco.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ui-mobile-checkout-view',
  templateUrl: './mobile-checkout-view.component.html',
  styleUrls: ['./mobile-checkout-view.component.less'],
})
export class MobileCheckoutViewComponent {
  @Input()
  campaign!: ICampaign;

  @Input()
  user!: IUser;

  //public campaign: ICampaign;
  //public campaign$: Observable<ICampaign>;

  public title: string;
  public subtitle: string;

  public index_currency: number = 0;
  public index_amount: number = 0;

  public amount = 10000;
  public currency = 'COP';

  public STEP = 0;
  public TYPE: 'person' | 'company' = 'person';

  public form: FormGroup;

  public campaignId: number = -1;

  public isAnonymous: boolean = false;
  public isColombia: boolean = true;

  public config_currency: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    spaceBetween: 20,
  };
  public config_amount: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2.5,
    spaceBetween: 5,
  };

  public OPTIONS_CURRENCY = ['COP', 'USD', 'EUR'].map((item) => ({ value: item, label: item }));
  public OPTIONS_NID_TYPES = [
    { value: 'CC', label: 'Cédula de Ciudadanía' },
    { value: 'CE', label: 'Cédula de Extranjería' },
    { value: 'TI', label: 'Tarjeta de Identidad' },
  ];
  public OPTIONS_ORIGINS = [
    { value: 'web', label: 'Sitio Web' },
    { value: 'social', label: 'Redes Sociales' },
    { value: 'friend', label: 'Referido por un amigo' },
  ];

  public amounts = [
    { value: 10000, currency: 'COP', label: '10,000 COP' },
    { value: 20000, currency: 'COP', label: '20,000 COP' },
    { value: 50000, currency: 'COP', label: '50,000 COP' },
    { value: 5, currency: 'USD', label: '$5 USD' },
    { value: 10, currency: 'USD', label: '$10 USD' },
    { value: 15, currency: 'USD', label: '$15 USD' },
    { value: 5, currency: 'EUR', label: '$5 EUR' },
    { value: 10, currency: 'EUR', label: '$10 EUR' },
    { value: 15, currency: 'EUR', label: '$15 EUR' },
  ];

  constructor(private fb: FormBuilder, private epayco: EpaycoService, private route: ActivatedRoute) {}

  ngOnInit() {
    const campaignId = this.route.snapshot.paramMap.get('id');
    this.campaignId = Number(campaignId);
    
    this.doSetCheckoutFormGroup();
    this.doSelectAmount(10000);
  }
  ngOnDestroy() {}

  public doSetCheckoutFormGroup() {
    const isCompanyTab = this.TYPE == 'company';
    const isUserTab = this.TYPE == 'person';

    const formUser = {
      user_firstname: [this.user?.firstname || null, [Validators.required]],
      user_lastname: [this.user?.lastname || null, [Validators.required]],
      user_nid_type: [this.user?.nid_type || null, Validators.required],
      user_nid_number: [this.user?.nid_number || null, Validators.required],
      user_email: [this.user?.email || null, [Validators.required, Validators.email]],
      user_phone: [this.user?.phone || null, Validators.required],
    };
    const formCompany = {
      company_name: [this.user?.firstname || null, [Validators.required]],
      company_nid_type: ['NIT', Validators.required],
      company_nid_number: [null, Validators.required],
      company_email: [this.user?.email || null, [Validators.required, Validators.email]],
      company_phone: [this.user?.phone || null, Validators.required],
    };
    const formDonation = {
      donation_campaign_id: [this.campaignId, [Validators.required]],
      donation_currency: ['COP', [Validators.required]],
      donation_is_anonymous: [false, []],
      donation_amount: [null, [Validators.required, Validators.max(1000000), Validators.min(10000)]],
      donation_place: [
        {
          country: 'Colombia',
          department: 'Bogotá D.C.',
          city: 'Bogotá D.C.',
        },
        [Validators.required],
      ],
      donation_origin: [null],
      donation_postcode: [null],
    };

    let formGroup;
    if (isUserTab) formGroup = { ...formUser, ...formDonation };
    else formGroup = { ...formCompany, ...formDonation };

    this.form = this.fb.group({ ...formGroup }, { validator: [] } as AbstractControlOptions);
  }
  onAnonymousChange($event) {
    this.isAnonymous = $event.target.checked;
    if (this.isAnonymous) {
      if (this.TYPE === 'person') {
        this.form.controls.user_firstname.disable();
        this.form.controls.user_lastname.disable();
        this.form.controls.user_nid_type.disable();
        this.form.controls.user_nid_number.disable();
        this.form.controls.user_email.disable();
        this.form.controls.user_phone.disable();

        this.form.controls.user_firstname.clearValidators();
        this.form.controls.user_lastname.clearValidators();
        this.form.controls.user_nid_type.clearValidators();
        this.form.controls.user_nid_number.clearValidators();
        this.form.controls.user_email.clearValidators();
        this.form.controls.user_phone.clearValidators();
      }
    } else {
      if (this.TYPE === 'company') {
        this.form.controls.user_firstname.enable();
        this.form.controls.user_lastname.enable();
        this.form.controls.user_nid_type.enable();
        this.form.controls.user_nid_number.enable();
        this.form.controls.user_email.enable();
        this.form.controls.user_phone.enable();

        this.form.controls.user_firstname.setValidators([Validators.required]);
        this.form.controls.user_lastname.setValidators([Validators.required]);
        this.form.controls.user_nid_type.setValidators([Validators.required]);
        this.form.controls.user_nid_number.setValidators([Validators.required]);
        this.form.controls.user_email.setValidators([Validators.required, Validators.email]);
        this.form.controls.user_phone.setValidators([Validators.required]);
      }
    }
  }

  doSelectCurrency(currency) {
    this.currency = currency;
  }
  doSelectAmount(amount) {
    const currency = this.currency;
    const control_currency = this.form.get('donation_currency');
    const control_amount= this.form.get('donation_amount');

    control_currency.setValue(currency);
    control_amount.setValue(amount);

    if(control_currency.value === 'USD'){
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'EUR'){
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'COP'){
      control_amount.setValidators([Validators.min(10000), Validators.max(1000000)])
    }
    this.amount = amount;
  }
  setStep(step) {
    this.STEP = step;
  }

  onAmountChange($event){
    const value = Number($event.target.value);
    this.doSelectAmount(value)
  }

  setPlace(event) {
    this.isColombia = event.country === 'Colombia';
    this.form.get('donation_place').setValue({
      city: event.city,
      department: event.deparment,
      country: event.country,
    });
  }

  public async doEpayCo() {
    const _campaign = {
      campaign_id: this.campaignId,
      campaign_title: this.campaign.title,
    };
    const _donation = {
      donation_amount: this.form.get('donation_amount').value,
      donation_currency: this.form.get('donation_currency').value,
      donation_is_anonymous: !(this.user && this.user.id),
    };
    const _place = {
      billing_country: this.form.get('donation_place').value.country,
      billing_deparment: this.form.get('donation_place').value.department,
      billing_city: this.form.get('donation_place').value.city,
    };

    let _billing;
    if (this.TYPE === 'person') {
      _billing = {
        billing_name: this.form.get('user_firstname').value + ' ' + this.form.get('user_lastname').value,
        billing_email: this.form.get('user_email').value,
        billing_phone: this.form.get('user_phone').value,
        billing_address: ' NA ',
        billing_nid_type: this.form.get('user_nid_type').value,
        billing_nid_number: this.form.get('user_nid_number').value,
      };
    } else {
      _billing = {
        billing_name: this.form.get('company_name').value,
        billing_email: this.form.get('company_email').value,
        billing_phone: this.form.get('company_phone').value,
        billing_address: this.form.get('company_address').value,
        billing_nid_type: this.form.get('company_nid_type').value,
        billing_nid_number: this.form.get('company_nid_number').value,
      };
    }

    const payload: IEpaycoPayload = {
      ..._campaign,
      ..._donation,
      ..._billing,
      ..._place,
    };

    console.log(' ')
    console.log(' const payload: IEpaycoPayload =  ')
    console.log(payload)
    console.log(' ')

    try {
      const donation = await this.epayco.doEpayCo(payload);
      return donation;
    } catch (err) {
      Swal.fire('Error', err, 'error');
    }
  }

  onTypeChange(type: 'person' | 'company') {
    this.TYPE = type;
    this.doSetCheckoutFormGroup();
  }

  onChangeCurrency($event){
    const control_currency = this.form.get('donation_currency');
    const control_amount= this.form.get('donation_amount');
    if(control_currency.value === 'USD'){
      control_amount.setValue(5);
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'EUR'){
      control_amount.setValue(5);
      control_amount.setValidators([Validators.min(5), Validators.max(260)])
    }
    if(control_currency.value === 'COP'){
      control_amount.setValue(10000);
      control_amount.setValidators([Validators.min(10000), Validators.max(1000000)])
    }
  }
}
