import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'src/app/core/users/models/user.model';

@Component({
  selector: 'ui-campaign-last-donors',
  templateUrl: './campaign-last-donors.component.html',
  styleUrls: ['./campaign-last-donors.component.less'],
})
export class CampaignLastDonorsComponent implements OnInit {
  @Input()
  donors: any[];

  // ANON_PHOTO_URL = 'https://ui-avatars.com/api/?background=random&name=' + encodeURI('Anon' + this.user.email);

  constructor() {

  }

  ngOnInit(): void {}

  public getUserPhoto(user: IUser, isAnonymous : boolean) {
    if(isAnonymous){
      return user.photo_url ||'https://ui-avatars.com/api/?background=random&name=' + encodeURI('Anon' +  user.email);;
    } 
    return user.photo_url || 'https://ui-avatars.com/api/?background=random&name=' + encodeURI(user.name);
  }

  public getUserName(user: IUser, isAnonymous: boolean){
    if(isAnonymous){
      return 'Anonimo';
    }
    return user.name;
  }
}
