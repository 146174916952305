import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

import { HeaderComponent } from './components/header/header.component';
import { OutlineBtnComponent } from './components/buttons/outline-btn/outline-btn.component';
import { FooterComponent } from './components/footer/footer.component';
import { RadioBtnInputComponent } from './components/inputs/radio-btn-input/radio-btn-input.component';
import { SearchInputComponent } from './components/inputs/search-input/search-input.component';

import { TruncateTextPipe } from './pipes/TruncateText.pipe';
import { TruncateArrayPipe } from './pipes/TruncateArray.pipe';
import { CategoryToTextPipe } from './pipes/CategoryToText.pipe';
import { FilterByPlacePipe } from './pipes/FilterByPlace.pipe';
import { SortByPipe } from './pipes/SortBy.pipe';
import { FilterByCategoryPipe } from './pipes/FilterByCategory.pipe';
import { FilterBySearchKeywordPipe } from './pipes/FilterBySearchKeyword.pipe';
import { CampaignStatusLabelPipe } from './pipes/CampaignStatusLabel.pipe';

import { CardCampaignComponent } from './components/cards/card-campaign/card-campaign.component';
import { CardEmptyComponent } from './components/cards/card-empty/card-empty.component';

import { WidgetPercentageLevelComponent } from './components/widgets/widget-percentage-level/widget-percentage-level.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PhotoGalleryComponent } from './components/galleries/photo-gallery/photo-gallery.component';
import { WidgetLikeButtonComponent } from './components/widgets/widget-like-button/widget-like-button.component';
import { SolidBtnComponent } from './components/buttons/solid-btn/solid-btn.component';
import { ShareModalComponent } from './components/modals/share-modal/share-modal.component';
import { GlossaryModalComponent } from './components/modals/glossary-modal/glossary-modal.component';
import { DropdownInputComponent } from './components/inputs/dropdown-input/dropdown-input.component';
import { TextInputComponent } from './components/inputs/text-input/text-input.component';
import { PayuBtnComponent } from './components/buttons/payu-btn/payu-btn.component';
import { PaypalBtnComponent } from './components/buttons/paypal-btn/paypal-btn.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSortableModule } from 'ngx-sortable';
import { DragulaModule } from 'ng2-dragula';
import { NgProgressModule } from 'ngx-progressbar';
import { FilterAutocompleteComponent } from './components/filters/filter-autocomplete/filter-autocomplete.component';
import { FilterCityComponent } from './components/filters/filter-city/filter-city.component';
import { FilterAmountComponent } from './components/filters/filter-amount/filter-amount.component';
import { FilterDateComponent } from './components/filters/filter-date/filter-date.component';
import { FilterTypeComponent } from './components/filters/filter-type/filter-type.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NouisliderModule } from 'ng2-nouislider';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDateRangeModule } from 'ngx-daterange';
import { TextInputInlineComponent } from './components/ui-kit/inputs/text-input-inline/text-input-inline.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { TitleH1Component } from './components/ui-kit/titles/title-h1/title-h1.component';
import { TitleH2Component } from './components/ui-kit/titles/title-h2/title-h2.component';
import { TitleH3Component } from './components/ui-kit/titles/title-h3/title-h3.component';
import { TitleH4Component } from './components/ui-kit/titles/title-h4/title-h4.component';
import { TitleH5Component } from './components/ui-kit/titles/title-h5/title-h5.component';

import { TextPComponent } from './components/ui-kit/texts/text-p/text-p.component';
import { TextLabelComponent } from './components/ui-kit/texts/text-label/text-label.component';
import { SuccessModalComponent } from './components/ui-kit/modals/success-modal/success-modal.component';
import { TextInputInlineCComponent } from './components/ui-kit/inputs/text-input-inline-c/text-input-inline-c.component';
import { DropdownInputCComponent } from './components/ui-kit/inputs/dropdown-input-c/dropdown-input-c.component';

// UI KIT

import { TitleAuthH1Component } from './components/ui-kit/titles/title-auth/title-auth-h1/title-auth-h1.component';

import { DefaultButtonComponent } from './components/ui-kit/buttons/default-button/default-button.component';
import { ButtonFacebookLoginComponent } from './components/ui-kit/buttons/button-facebook-login/button-facebook-login.component';
import { ButtonGmailLoginComponent } from './components/ui-kit/buttons/button-gmail-login/button-gmail-login.component';
import { CardRegisterComponent } from './components/cards/card-register/card-register.component';
import { TextAreaInlineComponent } from './components/ui-kit/texts/text-area-inline/text-area-inline.component';
import { UikitFormDefaultComponent } from './components/ui-kit/forms/uikit-form-default/uikit-form-default.component';
import { WompiBtnComponent } from './components/buttons/wompi-btn/wompi-btn.component';
import { EpaycoBtnComponent } from './components/buttons/epayco-btn/epayco-btn.component';
import { PlaceCountryInputComponent } from './components/ui-kit/inputs/place-country-input/place-country-input.component';
import { PlaceDepartmentInputComponent } from './components/ui-kit/inputs/place-department-input/place-department-input.component';
import { PlaceCityInputComponent } from './components/ui-kit/inputs/place-city-input/place-city-input.component';
import { PlaceInputComponent } from './components/ui-kit/inputs/place-input/place-input.component';
import { FormSingleColumnComponent } from './components/ui-kit/forms/form-single-column/form-single-column.component';
import { InputSwitchComponent } from './components/ui-kit/inputs/input-switch/input-switch.component';
import { TextReadOnlyEditorJsComponent } from './components/texts/text-read-only-editor-js/text-read-only-editor-js.component';
import { SafePipe } from './pipes/SagePipe.pipe';
import { NgFormInputTextComponent } from './components/forms/ng-form-input-text/ng-form-input-text.component';
import { NgFormInputNumberComponent } from './components/forms/ng-form-input-number/ng-form-input-number.component';
import { NgFormInputDeadlineComponent } from './components/forms/ng-form-input-deadline/ng-form-input-deadline.component';
import { NgFormInputTextareaComponent } from './components/forms/ng-form-input-textarea/ng-form-input-textarea.component';
import { NgFormInputOptionsComponent } from './components/forms/ng-form-input-options/ng-form-input-options.component';
import { NgFormInputSingleCheckComponent } from './components/forms/ng-form-input-single-check/ng-form-input-single-check.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,

  OutlineBtnComponent,
  SolidBtnComponent,

  TextInputInlineComponent,
  RadioBtnInputComponent,
  SearchInputComponent,

  CardCampaignComponent,
  CardEmptyComponent,

  WidgetPercentageLevelComponent,
  PhotoGalleryComponent,
  WidgetLikeButtonComponent,

  ShareModalComponent,
  GlossaryModalComponent,

  DropdownInputComponent,
  TextInputComponent,
  PayuBtnComponent,
  PaypalBtnComponent,
  EpaycoBtnComponent,

  FilterAutocompleteComponent,
  FilterCityComponent,
  FilterAmountComponent,
  FilterDateComponent,
  FilterTypeComponent,
  TitleH1Component,
  TitleH2Component,
  TitleH3Component,
  TitleH4Component,
  TitleH5Component,
  TextPComponent,
  TextLabelComponent,
  SuccessModalComponent,
  TextInputInlineCComponent,
  DropdownInputCComponent,
  CardRegisterComponent,

  TitleAuthH1Component,
  SuccessModalComponent,

  // UI KIT
  DefaultButtonComponent,
  ButtonGmailLoginComponent,
  ButtonFacebookLoginComponent,
  TextAreaInlineComponent,
  UikitFormDefaultComponent,
  WompiBtnComponent,
  EpaycoBtnComponent,
  PlaceCountryInputComponent,
  PlaceDepartmentInputComponent,
  PlaceCityInputComponent,
  PlaceInputComponent,
  TextAreaInlineComponent,
  FormSingleColumnComponent,
  InputSwitchComponent,

  TextReadOnlyEditorJsComponent,

  NgFormInputTextComponent,
  NgFormInputNumberComponent,
  NgFormInputDeadlineComponent,
  NgFormInputTextareaComponent,
  NgFormInputOptionsComponent,
  NgFormInputNumberComponent,
  NgFormInputSingleCheckComponent

];

const PIPE = [
  TruncateTextPipe,
  CategoryToTextPipe,
  TruncateArrayPipe,
  FilterBySearchKeywordPipe,
  FilterByPlacePipe,
  FilterByCategoryPipe,
  SortByPipe,
  SafePipe,
  CampaignStatusLabelPipe
];

const MODULES = [
  HeaderModule,
  FooterModule,
  CommonModule,
  SwiperModule,
  ReactiveFormsModule,
  NgxDateRangeModule,
  NgxDropzoneModule,
  NgxSortableModule,
  NgProgressModule,
  NgxMaskModule.forRoot(),
  DragulaModule.forRoot(),

  TranslateModule,
  ClickOutsideModule,
  BrowserAnimationsModule,
  LazyLoadImageModule,

  FormsModule,
  AutocompleteLibModule,
  NgxSliderModule,
  NouisliderModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS, ...PIPE ],
  exports: [...MODULES, ...COMPONENTS, ...PIPE] as any[],
})
export class SharedModule {}
