import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
/*
{
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    type: 'bullets',
    dynamicBullets: true,
    dynamicMainBullets: 5,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    }
  },
*/
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
  SwiperAutoplayInterface,
} from 'ngx-swiper-wrapper';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { NavigationEnd, Router } from '@angular/router';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 4.5,
  spaceBetween: 0,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  },
};

@Component({
  selector: 'ui-home-category-campaigns',
  templateUrl: './home-category-campaigns.component.html',
  styleUrls: ['./home-category-campaigns.component.less'],
})
export class HomeCategoryCampaignsComponent implements OnInit, AfterViewInit {
  @Input()
  campaigns: ICampaign[];

  public config = DEFAULT_SWIPER_CONFIG;
  public index = 0;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  ngOnInit(): void {}

  ngAfterViewInit() {}

  constructor(private router: Router, private elementRef: ElementRef) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.componentRef.directiveRef.setIndex(0);
      }
    });
  }
}
