import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';


export type EditorJS_Json = {
  time: number;
  blocks: any[],
  version: string;
}
@Component({
  selector: 'ui-campaign-description',
  templateUrl: './campaign-description.component.html',
  styleUrls: ['./campaign-description.component.less'],
})
export class CampaignDescriptionComponent implements OnInit,OnChanges {

  @Input()
  description: string;

  @Input()
  content: string;

  @Input()
  editable = false;

  @Output()
  nChange = new EventEmitter();




  @Input()
  json: string ;

  editor: any;
  readonly: any;

  constructor() { }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnChanges(changes: any){
   

    if(!this.editable){
      this.editor.save().then( response => {
        this.readonly.render(response)
      })
    }

  }

  onInit(){
    let data = null;
    let readOnly = !this.editable;
    try{
       data = JSON.parse(this.json);
    } catch(e){
        console.error(e)
    }

    this.editor = new EditorJS({

      holderId: 'campaign-description-editor-js',

      autofocus: true,

      data: data,
      
      readOnly: readOnly,


      tools: {
        link: Link,
        header: Header,
        list: List
      },

    });

    this.readonly = new EditorJS({

      holderId: 'campaign-description-readonly-js',

      data: data,
      
      readOnly: true,

      tools: {
        link: Link,
        header: Header,
        list: List
      },

    });
  }


  updateDescription($event) {
    this.nChange.emit(this.description);
  }

  onSave(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.editor.save().then((outputData) => {
        // console.log('Article data: ', outputData)
        resolve(outputData)
      }).catch((error) => {
        // console.log('Saving failed: ', error)
        reject(error)
      });
    })

  }
}
